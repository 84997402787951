import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  DisbursementInitiateModel,
  DisbursementRejectionModel,
} from '@fleet/model';
import { handleApiError } from '@fleet/utilities';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminApiService {
  host;
  orgHost;
  constructor(private httpClient: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.admin;
    this.orgHost = env.host + paths.organisation;
  }

  requestDriverDiagnostics(driverId: string) {
    return this.httpClient
      .post(`${this.host}/driver/${driverId}/diagnostics`, {})
      .pipe(catchError(handleApiError));
  }

  requestTravellerDiagnostics(travellerId: string) {
    return this.httpClient
      .post(`${this.host}/traveller/${travellerId}/diagnostics`, {})
      .pipe(catchError(handleApiError));
  }

  getTransactionDetails(transactionId: string) {
    return this.httpClient
      .get(`${this.host}/transaction/${transactionId}`)
      .pipe(catchError(handleApiError));
  }

  searchDisbursements() {
    return this.httpClient.get(`${this.host}/recentDisbursementBatches`);
  }

  rejectDisbursement(
    rejectionModel: DisbursementRejectionModel,
    disbursementId: string
  ) {
    return this.httpClient
      .put(`${this.host}/disbursement/${disbursementId}/reject`, rejectionModel)
      .pipe(catchError(handleApiError));
  }

  initiateDisbursements(initiateModel: DisbursementInitiateModel) {
    return this.httpClient
      .put(`${this.host}/disbursement`, initiateModel)
      .pipe(catchError(handleApiError));
  }

  getRejectionReasons() {
    return this.httpClient
      .get(`${this.host}/disbursement/rejectionReasons`)
      .pipe(catchError(handleApiError));
  }

  getSupportedAdhocAdjustmentTypes() {
    return this.httpClient
      .get(`${this.host}/driver/adjustmentTypes`)
      .pipe(catchError(handleApiError));
  }
  searchBookingTransactions(bookingId: string) {
    return this.httpClient
      .get(
        `${this.host}/transaction?bookingId=${bookingId}
    `
      )
      .pipe(catchError(handleApiError));
  }

  getUser(credentialId: string) {
    return this.httpClient
      .get(`${this.host}/${credentialId}/users`)
      .pipe(catchError(handleApiError));
  }

  getSupportedManualOrganisationAdjustmentTypes() {
    return this.httpClient
      .get(`${this.host}/organisation/adjustmentTypes`)
      .pipe(catchError(handleApiError));
  }
}
