import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ResponsiveElementModule } from '@fleet/layout';
import {
  JobColorsPipeModule,
  JobSearchResultToAddressDisplayPipeModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { JobActionModule } from '../../../job-action/job-action.module';
import { JobDatetimePipeModule } from './../../../pipes/job-datetime-pipe/job-datetime-pipe.module';
import { JobSearchResultsComponent } from './job-search-results.component';

import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  JobTimeDisplayPipeModule,
  JobWaypointDisplayPipeModule,
} from '@fleet/job-shared';
import { DoubleClickDirectiveModule } from '@fleet/shared';
import { BadgeModule, SourceModule, StatusChipModule } from '@fleet/ui';
import { JobSearchResultsColumnConfigurationModule } from '../../../job-search-results-column-configuration/job-search-results-column-configuration.module';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);

@NgModule({
  declarations: [JobSearchResultsComponent],
  imports: [
    CommonModule,
    ResponsiveElementModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,

    JobDatetimePipeModule,
    TitleCaseAndCleanPipeModule,
    StatusChipModule,
    JobActionModule,
    SourceModule,
    MatSortModule,
    JobSearchResultsColumnConfigurationModule,
    JobSearchResultToAddressDisplayPipeModule,
    DoubleClickDirectiveModule,
    JobWaypointDisplayPipeModule,
    JobTimeDisplayPipeModule,
    MatTooltipModule,
    JobColorsPipeModule,
    BadgeModule,
    InternationalizationDatePipeModule,
    TranslocoDirective,
  ],
  exports: [JobSearchResultsComponent],
  providers: [
    provideTranslocoScope({
      scope: 'jobSearchResults',
      loader: lazyTranslationloader,
    }),
  ],
})
export class JobSearchResultsModule {}
