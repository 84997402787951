import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotAuthorisedComponent } from './not-authorised/not-authorised.component';
import { NotAuthorisedModule } from './not-authorised/not-authorised.module';

@NgModule({
  imports: [
    CommonModule,
    NotAuthorisedModule,
    RouterModule.forChild([{ path: '', component: NotAuthorisedComponent }]),
  ],
})
export class AuthorisationModule {}
