<ng-container *transloco="let t">
  <div
    class="bg-card rounded-md shadow-sm border border-gray-300"
    [ngClass]="{
      'border-gray-500': hostFocused && serviceControl.enabled,
      'opacity-60': serviceControl.disabled,
      'border-red-500': serviceControl.errors && serviceControl.touched
    }"
  >
    <div
      class="w-full flex flex-row justify-between items-center py-3 px-4"
      [ngClass]="{ 'cursor-pointer': serviceControl?.enabled }"
      (click)="serviceControl?.enabled ? toggleList() : null"
    >
      <div class="flex shrink flex-row">
        <mat-icon class="mr-3 icon-size-5 text-hint">local_taxi</mat-icon>
        <div
          [ngClass]="{
            'text-red-500': serviceControl.errors && serviceControl.touched
          }"
        >
          {{
            showList
              ? t('servicePricingSelect.selectService')
              : t('servicePricingSelect.service')
          }}
        </div>
      </div>
      <mat-icon
        *ngIf="!loading && serviceControl.enabled"
        [svgIcon]="showList ? 'expand_less' : 'expand_more'"
        class="icon-size-5"
      >
      </mat-icon>
      <ng-container *ngIf="loading">
        <mat-progress-spinner
          diameter="18"
          mode="indeterminate"
        ></mat-progress-spinner>
      </ng-container>
    </div>
    <div *ngIf="showList || serviceControl.value" class="border-t-2"></div>

    <fuse-alert
      *ngFor="let alert of issues | alertsFromIssues"
      class="m-1"
      [appearance]="'outline'"
      [showIcon]="true"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>

    <ng-container *ngIf="showList; else showSelected">
      <ng-container *ngTemplateOutlet="list"></ng-container>
    </ng-container>

    <ng-template #list>
      <fuse-alert *ngIf="!startLocation && !loading" type="warn">
        {{
          t(
            'servicePricingSelect.pleaseSelectAStartLocationToSeeAvailableServices'
          )
        }}
      </fuse-alert>
      <fuse-alert
        *ngIf="
          services.length === 0 && jobOrganisation && previousParams && !loading
        "
        type="warn"
      >
        {{ t('servicePricingSelect.thereAreNoServicesAvailableForThisClass') }}
      </fuse-alert>

      <ul
        #serviceList
        cdkListbox
        cdkListboxOrientation="vertical"
        class="w-full"
        [cdkListboxValue]="[serviceControl.value]"
        (cdkListboxValueChange)="serviceSelected($event.value[0])"
      >
        <li
          class="focus:bg-gray-100 hover:bg-gray-50 p-4 cursor-pointer"
          [cdkOption]="service"
          (click)="
            service === serviceControl.value ? serviceSelected(service) : null
          "
          *ngFor="let service of services; let last = last"
          [ngClass]="{
            'border-b': !last,
            'bg-gray-200': service === serviceControl.value
          }"
        >
          <ng-container
            *ngTemplateOutlet="
              servicePriceTemplate;
              context: { $implicit: service }
            "
          ></ng-container>
        </li>
        <li class="hidden" [cdkOptionDisabled]="true" [cdkOption]="null"></li>
      </ul>
    </ng-template>

    <!-- SHOWING THE ITEM WHEN THE LIST IS OPEN -->
    <ng-template #servicePriceTemplate let-servicePrice>
      <div class="flex flex-col w-full">
        <!-- <img
          class="h-14 w-14 rounded-md object-cover"
          [src]="servicePrice.imageUrl"
        /> -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-2 items-center">
            <div class="text-base">{{ servicePrice.displayName }}</div>

            <mat-icon
              (click)="$event.stopPropagation(); openExternalPricingLink()"
              class="icon-size-5"
              >info</mat-icon
            >
          </div>

          <div class="min-w-0 flex-1 text-sm text-secondary">
            {{ servicePrice.description }}
          </div>
        </div>

        <div
          class="text-xs my-2 text-secondary"
          *ngIf="!endLocation; else estimate"
        >
          {{ t('servicePricingSelect.destinationRequiredForFareEstimate') }}
        </div>
        <ng-template #estimate>
          <div
            class="flex flex-col w-full mt-2"
            *ngIf="servicePrice?.prices?.length > 0"
          >
            <ng-container *ngIf="loading; else hasPrices">
              <mat-progress-spinner
                diameter="14"
                mode="indeterminate"
              ></mat-progress-spinner>
            </ng-container>
            <ng-template #hasPrices>
              <div
                class="grid grid-cols-3 justify-items-stretch items-center gap-4"
              >
                <ng-container
                  *ngFor="let price of servicePrice.prices; let i = index"
                >
                  <div
                    (click)="
                      $event.stopPropagation();
                      serviceControl?.enabled
                        ? setServiceAndPrice(servicePrice, price)
                        : null
                    "
                    class="flex flex-col items-center justify-center p-2 gap-1 rounded-md bg-default hover:ring-1 hover:ring-gray-500"
                  >
                    <div class="text-sm font-secondary">
                      {{ price.pricingMethodType | titleCaseAndClean }}
                    </div>

                    <div
                      class="whitespace-normal text-sm"
                      *ngIf="
                        price.pricingMethodType !== 'FLAT';
                        else flatTariff
                      "
                    >
                      {{ price.displayPrice ? price.displayPrice : '?' }}
                    </div>
                    <ng-template #flatTariff>
                      <div class="whitespace-normal text-sm">
                        {{
                          totalTariffControl.value
                            ? (totalTariffControl.value | currency)
                            : t('servicePricingSelect.specify')
                        }}
                      </div></ng-template
                    >
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </ng-template>

    <ng-template #showSelected>
      <div
        class="px-3"
        *ngIf="serviceControl.value"
        [ngClass]="{ 'cursor-pointer': serviceControl?.enabled }"
      >
        <div (click)="serviceControl?.enabled ? toggleList() : null">
          <ng-container
            *ngTemplateOutlet="
              selectedServicePriceTemplate;
              context: {
                $implicit: serviceControl.value
              }
            "
          ></ng-container>
        </div>
        <div *ngIf="endLocation && endLocation.latitude">
          <div *ngIf="loading; else pricesLoaded" class="text-sm my-2">
            {{ t('servicePricingSelect.fetchingPrices') }}
          </div>

          <ng-template #pricesLoaded>
            <div
              class="grid grid-cols-3 justify-items-stretch items-center gap-4 pb-4"
            >
              <ng-container *ngFor="let price of serviceControl?.value?.prices">
                <div
                  class="flex flex-col items-center justify-center p-2 gap-1 rounded-md bg-default hover:ring-1 hover:ring-gray-500"
                  [ngClass]="{
                    'ring-1 ring-inset ring-gray-500':
                      pricingControl.value?.pricingMethodId ===
                      price.pricingMethodId,

                    'cursor-pointer': serviceControl?.enabled
                  }"
                  (click)="serviceControl?.enabled ? selectPrice(price) : null"
                >
                  <div class="font-sm font-secondary">
                    {{ price.pricingMethodType | titleCaseAndClean }}
                  </div>
                  <div
                    class="whitespace-normal text-sm"
                    *ngIf="price.pricingMethodType !== 'FLAT'; else flatTariff"
                  >
                    {{ price.displayPrice ? price.displayPrice : '?' }}
                  </div>
                  <ng-template #flatTariff>
                    <div class="whitespace-normal text-sm">
                      {{
                        totalTariffControl.value
                          ? (totalTariffControl.value | currency)
                          : t('servicePricingSelect.specify')
                      }}
                    </div></ng-template
                  >
                </div>
              </ng-container>
            </div>
          </ng-template>
        </div>
        <div
          class="border-b-0 flex flex-col"
          *ngIf="
            pricingControl?.value?.pricingMethodType === 'FLAT' && !loading
          "
        >
          <mat-form-field
            class="w-full"
            appearance="fill"
            matTooltip="flatFareSplit?'Primary Fare Amount':'Flat Fare Amount"
          >
            <mat-label class="text-sm font-light">{{
              t('servicePricingSelect.totalTariff')
            }}</mat-label>
            <mat-icon class="icon-size-5" matPrefix
              ><span class="material-symbols-outlined">
                monetization_on
              </span></mat-icon
            >
            <input
              autocomplete="off"
              data-lpignore="true"
              data-form-type="other"
              matInput
              currencyMask
              [options]="{ align: 'left', allowNegative: false }"
              [placeholder]="t('servicePricingSelect.enterFlatFareAmount')"
              [formControl]="totalTariffControl"
            />

            <mat-error>
              {{
                t(
                  'servicePricingSelect.anTotalTariffAmountIsRequiredForFlatFare'
                )
              }}
            </mat-error>
            <span
              matSuffix
              *ngIf="!flatFareSplit"
              class="cursor-pointer underline text-sm text-primary"
              [ngClass]="{ 'cursor-pointer': serviceControl?.enabled }"
              (click)="serviceControl?.enabled ? (flatFareSplit = true) : null"
              >{{ t('servicePricingSelect.split') }}</span
            >
            <span
              matSuffix
              *ngIf="flatFareSplit"
              class="cursor-pointer underline text-sm text-primary"
              (click)="
                flatFareSplit = false; primaryTariffControl.setValue(null)
              "
              >{{ t('servicePricingSelect.noSplit') }}</span
            >
          </mat-form-field>

          <mat-form-field
            *ngIf="flatFareSplit"
            class="w-full mb-4"
            matTooltip="Primary Tariff Payment Amount"
          >
            <mat-label class="text-sm font-light">
              {{ t('servicePricingSelect.primaryTariffPaymentAmount') }}
            </mat-label>
            <mat-icon class="icon-size-5" matPrefix>payments</mat-icon>

            <input
              autocomplete="off"
              data-lpignore="true"
              data-form-type="other"
              matInput
              currencyMask
              [options]="{ align: 'left', allowNegative: false }"
              [placeholder]="
                t('servicePricingSelect.enterPrimaryPaymentAmount')
              "
              [formControl]="primaryTariffControl"
            />
            <mat-error *ngIf="primaryTariffControl.getError('required')">
              {{
                t(
                  'servicePricingSelect.aPrimaryPaymentAmountIsRequiredForSplitFares'
                )
              }}
            </mat-error>
            <mat-error *ngIf="primaryTariffControl.getError('max')">
              {{
                t(
                  'servicePricingSelect.aPrimaryPaymentAmountMustBeLessThanTheTotalForASplitFare'
                )
              }}
            </mat-error>
            <mat-hint *ngIf="secondaryTariffPaymentAmount">
              {{ t('servicePricingSelect.secondaryTariffPayment') }}
              {{ secondaryTariffPaymentAmount | number : '1.2-2' | currency }}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <ng-template #selectedServicePriceTemplate let-servicePrice>
      <div class="flex w-full py-4" *ngIf="servicePrice?.displayName">
        <!-- <img
        class="h-14 w-14 rounded-md object-cover"
        [src]="servicePrice.imageUrl"
      /> -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-2 items-center">
            <div>{{ servicePrice.displayName }}</div>

            <mat-icon
              class="icon-size-5"
              (click)="$event.stopPropagation(); openExternalPricingLink()"
              >info</mat-icon
            >
          </div>

          <div class="min-w-0 flex-1 text-sm">
            {{ servicePrice.description }}
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #servicePriceItem let-price>
      <div class="flex flex-row items-center justify-between p-4">
        <div>
          {{ price.pricingMethodType | titleCaseAndClean }}:
          {{ price.displayPrice }}
        </div>
      </div>
    </ng-template>
    <ng-template #selectedServicePriceItem let-price>
      <div class="flex flex-row items-center justify-between w-40">
        <div class="flex">
          {{ price.pricingMethodType | titleCaseAndClean }}
        </div>
        <div class="flex">{{ price.displayPrice }}</div>
      </div>
    </ng-template>
  </div>
</ng-container>
