<ng-container *transloco="let t">
  <fleet-dialog-layout
    [title]="t('jobPaymentTransactionReceipt.emailReceiptTitle')"
    [instructions]="t('jobPaymentTransactionReceipt.emailReceiptInstructions')"
    [iconColour]="'primary'"
    [icon]="'info'"
    [showCancel]="true"
  >
    <div slot="main">
      <form [formGroup]="form" class="flex flex-col gap-3 w-full">
        <div class="flex flex-col gap-2" *ngIf="traveller">
          <mat-label>{{
            t('jobPaymentTransactionReceipt.sendReceiptTo')
          }}</mat-label>

          <mat-radio-group
            required
            class="flex flex-col gap-3"
            formControlName="receipt"
          >
            <mat-radio-button
              [disabled]="
                traveller &&
                !traveller?.receiptEmail &&
                traveller?.status === 'UNREGISTERED'
              "
              [value]="'TRAVELLER'"
              >{{
                t('jobPaymentTransactionReceipt.travellerEmail')
              }}</mat-radio-button
            >
            <mat-radio-button [value]="'EMAIL'">{{
              t('jobPaymentTransactionReceipt.otherEmail')
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field
          *ngIf="form?.get('receipt')?.value === 'EMAIL' || !traveller"
          class="w-full"
        >
          <mat-label>{{
            t('jobPaymentTransactionReceipt.emailPlaceholder')
          }}</mat-label>
          <input
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
            matInput
            placeholder="{{
              t('jobPaymentTransactionReceipt.emailPlaceholder')
            }}"
            formControlName="email"
          />
          <mat-error>{{
            t('jobPaymentTransactionReceipt.validEmailError')
          }}</mat-error>
        </mat-form-field>
      </form>
    </div>
    <fuse-alert
      slot="error"
      *ngFor="let alert of issues | alertsFromIssues"
      class=""
      [appearance]="'outline'"
      [showIcon]="true"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>

    <ng-container slot="action">
      <fleet-progress-button
        [state]="{
          buttonLabel: t('jobPaymentTransactionReceipt.sendButtonLabel'),
          loading: loading
        }"
        [disabled]="
          loading || (!form.valid && form?.get('receipt')?.value === 'EMAIL')
        "
        [loading]="loading"
        (onClick)="send()"
      >
      </fleet-progress-button>
    </ng-container>
  </fleet-dialog-layout>
</ng-container>
