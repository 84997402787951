<ng-container *transloco="let t">
  <fuse-alert
    *ngFor="let alert of issues | alertsFromIssues"
    [appearance]="'outline'"
    [showIcon]="true"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <mat-form-field class="w-full">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
      #input
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
      [placeholder]="placeHolder"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      (click)="initialiseSearching()"
    />
    <mat-error>{{
      t('organisationAutocomplete.organisationRequired')
    }}</mat-error>
    <mat-icon matPrefix *ngIf="prefixIcon">{{ prefixIcon }} </mat-icon>

    <mat-spinner matSuffix diameter="18" *ngIf="searching"></mat-spinner>
  </mat-form-field>
  <mat-autocomplete
    #groupAutoComplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="selectOrganisation($event)"
  >
    <ng-container *ngIf="organisationSearch$ | async as organisationSearch">
      <mat-option
        *ngFor="let organisation of organisationSearch"
        [value]="organisation"
      >
        <span>{{ organisation.name }}</span>
      </mat-option>
      <mat-option
        disabled
        *ngIf="organisationSearch.length === 0 && searchComplete"
        >{{ t('organisationAutocomplete.noResultsFound') }}</mat-option
      >
    </ng-container>
  </mat-autocomplete>
</ng-container>
