import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCard',
})
export class CreditCardPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (!value) {
      return;
    }
    switch (args) {
      case 'credit-card-icon-in': {
        return 'in_icon-brands_cc' + this.toTitleCase(value);
        break;
      }
      case 'credit-card-icon-fa': {
        return 'fa-cc' + this.toTitleCase(value);
        break;
      }
      case 'svg': {
        let cardType = value.toLowerCase();
        if (cardType === 'american express') {
          cardType = 'amex';
        }
        if (cardType === 'master-card') {
          cardType = 'mastercard';
        }
        if (cardType === 'eftpos') {
          cardType = 'none';
        }
        if (cardType === 'diners') {
          cardType = 'diners';
        }
        if (cardType === 'discover') {
          cardType = 'discover';
        }
        if (cardType === 'jcb') {
          cardType = 'jcb';
        }
        if (cardType === 'unionpay') {
          cardType = 'unionpay';
        }
        return 'creditcards:' + cardType;
      }
    }
  }

  toTitleCase(str: any) {
    return str.replace(/\w\S*/g, function (txt: any) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
