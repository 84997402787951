import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityCallDetailComponent } from './entity-call-detail.component';
import {
  ActionButtonsModule,
  CardDescriptionListModule,
  SidebarHeaderModule,
} from '@fleet/ui';
import {
  TitleCaseAndCleanPipeModule,
  MobileFormatModule,
  DurationPipeModule,
} from '@fleet/pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { CallerTypePipeModule } from '../caller-type-pipe/caller-type-pipe.module';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [EntityCallDetailComponent],
  imports: [
    CommonModule,
    CardDescriptionListModule,
    SidebarHeaderModule,
    ActionButtonsModule,
    TitleCaseAndCleanPipeModule,

    MobileFormatModule,
    DurationPipeModule,
    MatIconModule,
    MatTooltipModule,
    HasFunctionPipeModule,
    InternationalizationDatePipeModule,
    CallerTypePipeModule,
    TranslocoDirective,
  ],
  exports: [EntityCallDetailComponent],
  providers: [
    provideTranslocoScope({
      scope: 'callDetail',
      loader: lazyTranslationloader,
    }),
  ],
})
export class EntityCallDetailModule {}
