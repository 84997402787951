/* eslint-disable no-sparse-arrays */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {
  ApiResponse,
  DriverModel,
  InvoiceModel,
  IssueModel,
  PaymentTransactionModel,
  SaleModel,
} from '@fleet/model';
import { SaleApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import { PaymentTransactionGroup } from '../job-sale-transaction-results/';
import { titleCaseClean } from '@fleet/utilities';
export interface EntityPTDRefundState {
  loading: boolean;
  issues: any;
  buttonLabel: string;
}

@Component({
  selector: 'fleet-entity-ptd-refund',
  templateUrl: './entity-ptd-refund.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class EntityPtdRefundComponent implements OnInit, OnDestroy {
  title = 'refundPayment';
  issues: IssueModel[] = [];
  loading: boolean;
  buttonLabel = 'refund';

  _label = 'refund';
  @Input() set label(value: string) {
    if (value) {
      this._label = value.toLocaleLowerCase();

      if (value === 'REFUND') {
        this.title = 'refundPayment';
        this.buttonLabel = 'refund';
      } else if (value === 'REVERSE') {
        this.title = 'reverseCreditAllocation';
        this.buttonLabel = 'reverse';
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  get label() {
    return this._label;
  }

  @Input() color = 'primary';

  @Output() cancelled = new EventEmitter();
  @Output() refunded = new EventEmitter();

  form: UntypedFormGroup;

  //SALE MODEL OR TRANSACTION PAYMENT MODEL
  _sale: any;
  @Input() set sale(value: any) {
    this._sale = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.issues = [];
      if (
        value.paymentTransaction &&
        value.paymentTransaction.refundableAmount
      ) {
        this.form
          .get('refundAmount')
          .patchValue(value.paymentTransaction.refundableAmount);
      } else {
        this.form.get('refundAmount').patchValue(value.amount);
      }

      if (value.paymentType === 'CREDIT_INVOICE') {
        //MUST BE PAID IN FULL - DISABLED FOR THE USER
        this.form.get('refundAmount').disable();
        this.form.get('refundAmount').updateValueAndValidity();
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  get sale() {
    return this._sale;
  }

  _transaction: PaymentTransactionModel;
  @Input() set transaction(value: PaymentTransactionModel) {
    this._transaction = value;
    if (value) {
      this.buildForm();
      this.issues = [];

      this.form.get('refundAmount').patchValue(value.refundableAmount);

      if (value.paymentType === 'CREDIT_INVOICE') {
        //MUST BE PAID IN FULL - DISABLED FOR THE USER
        this.form.get('refundAmount').disable();
        this.form.get('refundAmount').updateValueAndValidity();
      }
      this.changeDetectorRef.markForCheck();
    }
  }

  get transaction() {
    return this._transaction;
  }

  _paymentTransactionGroup: PaymentTransactionGroup;
  @Input() set paymentTransactionGroup(value: PaymentTransactionGroup) {
    this._paymentTransactionGroup = value;
    if (value) {
      this.buildForm();
      this.issues = [];

      this.form.get('refundAmount').patchValue(value.totalRefundableAmount);
      this.form
        .get('refundAmount')
        .setValidators([Validators.max(Number(value.totalRefundableAmount))]);
      this.form.get('refundAmount').updateValueAndValidity();

      this.changeDetectorRef.markForCheck();
    }
  }

  get paymentTransactionGroup() {
    return this._paymentTransactionGroup;
  }

  @Input() driver: DriverModel;

  @Input() invoice: InvoiceModel;

  constructor(
    private saleApiService: SaleApiService,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.form) {
      this.buildForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      reason: [null, [Validators.required]],
      refundAmount: [{ value: null }, [Validators.required]],
    });
  }

  refundPTD() {
    this.issues = [];
    this.loading = true;

    this.changeDetectorRef.markForCheck();

    const payload = {
      reason: this.form.value.reason,
      refundAmount: this.form.value.refundableAmount,
    } as any;

    //WHere am i getting the sale id from?
    let saleId;
    if (this.sale) {
      saleId = this.sale.saleId;
    } else if (this.transaction) {
      saleId = this.transaction.saleId;
    } else if (
      this.paymentTransactionGroup &&
      this.paymentTransactionGroup.purchaseTransactions &&
      this.paymentTransactionGroup.purchaseTransactions.length > 0
    ) {
      // Use the first APPROVED purchaseTransaction as there can be more than one and this is the only thing that can be refunded
      const approvedTransaction =
        this.paymentTransactionGroup.purchaseTransactions.find(
          (transaction) => transaction.paymentStatus === 'APPROVED'
        );
      if (approvedTransaction) {
        saleId = approvedTransaction.saleId;
        payload['paymentTransactionId'] =
          approvedTransaction.paymentTransactionId;
      }
    }

    this.saleApiService.refundSale(saleId, this.form.getRawValue()).subscribe({
      next: (resp: ApiResponse<any>) => {
        setTimeout(() => {
          //ACCOUNT IS SLIGHTLY SLOW THIS IS IMPORTANT TO WAIT
          this.loading = false;
          this.refunded.emit(resp.data);
          this.changeDetectorRef.markForCheck();
        }, 250);
      },
      error: (issues: IssueModel[]) => {
        this.issues = issues;
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  ngOnDestroy(): void {}
}
