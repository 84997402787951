import { FLEET_VERSION } from '@fleet/version';
export const environment = {
  production: true,
  hmr: false,
  xApiKey: 'pk_test_979473aac2e1c7b4dc7f85430ba0eade',
  host: 'https://preprod-tapi-demo1.fleet.com.au',
  googleApiKey: 'AIzaSyDOOk5iG_N8crmcgz_fvooLtY3C9hSIVwA',
  braintreeWebClientToken: 'sandbox_bnnjgsp2_37d8t9fxb3ydvmxs',
  minTokenExpiry: 60,
  fleetProduct: 'TRAVELLER',
  envName: 'demo1',
  socketHost: 'wss://socket-awspreprod.ingogo.mobi/socket',
  socketTopicPrefix: '/topic/demo1/core/v3/',

  socketAcknowledge:
    'https://socket-awspreprod.ingogo.mobi/socket/client/{{clientId}}/acknowledge/',
  tasHost: 'https://preprod-svc-tas.fleet.com.au/tas/api/demo1/v1',
  firebaseConfig: {
    apiKey: 'AIzaSyCh7s0kGmCmJrt4QudOz2TPlt3FcsydoYk',
    authDomain: 'fleet-demo-au.firebaseapp.com',
    databaseURL: 'https://fleet-demo-au.firebaseio.com',
    projectId: 'fleet-demo-au',
    storageBucket: 'fleet-demo-au.appspot.com',
    messagingSenderId: '724478988396',
    appId: '1:724478988396:web:e5f24b530b21bb449b8849',
    measurementId: 'G-7LMMM8CN40',
  },
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  version: FLEET_VERSION,
};
