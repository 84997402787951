import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsTabLabelComponent } from './settings-tab-label.component';

import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [SettingsTabLabelComponent],
  imports: [CommonModule, RouterModule, MatIconModule],
  exports: [SettingsTabLabelComponent],
  providers: [],
})
export class SettingsTabLabelModule {}
