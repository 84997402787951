import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EntityDetailLayoutService {
  drawOpened: BehaviorSubject<any> = new BehaviorSubject(false);
  showMenuButton: BehaviorSubject<any> = new BehaviorSubject(false);
  withPadding: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentRoute = this.router.routerState.root.snapshot;
        let route = currentRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        const withEntityLayoutPadding = route.data['withEntityLayoutPadding'];
        this.setWithPadding(withEntityLayoutPadding !== false);
      });
  }

  get drawOpened$() {
    return this.drawOpened.asObservable();
  }

  get showMenuButton$() {
    return this.showMenuButton.asObservable();
  }

  setShowMenuButton(show: boolean) {
    this.showMenuButton.next(show);
  }

  setDrawOpened(opened: boolean) {
    this.drawOpened.next(opened);
  }

  toggleDraw() {
    this.drawOpened.next(!this.drawOpened.value);
  }

  setWithPadding(withPadding: boolean) {
    this.withPadding.next(withPadding);
  }

  get withPadding$() {
    return this.withPadding.asObservable();
  }
}
