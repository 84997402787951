import {
  Component,
  ViewEncapsulation,
  Input,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { L } from '@angular/cdk/keycodes';
import { BadgeModule } from '../../../badge';

@Component({
  selector: 'fleet-radio-group-list-with-description',
  standalone: true,
  imports: [CommonModule, BadgeModule],
  template: `<fieldset>
    <legend *ngIf="label" class="mb-5">{{ label }}</legend>
    <div class="space-y-5">
      <div *ngFor="let option of options" class="relative flex items-start">
        <div class="flex h-6 items-center">
          <input
            [id]="option.value"
            [attr.aria-describedby]="option.value + '-description'"
            name="option"
            type="radio"
            [checked]="option.value === value"
            (change)="change(option.value)"
            class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
          />
        </div>

        <div class="ml-3 text-sm leading-6">
          <div class="flex flex-row items-center">
            <label [for]="option.value" class="font-medium text-gray-900">{{
              option.name
            }}</label>
            <fleet-badge
              class="ml-4"
              *ngIf="option.status"
              [variationConfig]="{
              variation: 'border',
              color: option.status.color,
              size: 'small',

            }"
              [label]="option.status.label"
            ></fleet-badge>
          </div>
          <p [id]="option.value + '-description'" class="text-gray-500">
            {{ option.description }}
          </p>
        </div>
      </div>
    </div>
  </fieldset>`,
  styles: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupListWithDescriptionComponent),
      multi: true,
    },
  ],
})
export class RadioGroupListWithDescriptionComponent
  implements ControlValueAccessor
{
  @Input() label: string;
  @Input() options: {
    value: string;
    name: string;
    description: string;
    status: any;
  }[];

  value: string;
  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(value: string): void {
    if (this.value !== value) {
      this.value = value;
      this.cdr.markForCheck();
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = (value: string) => {
      fn(value);
      this.cdr.detectChanges();
    };
  }

  change(value: string) {
    this.value = value;
    this.onTouched();
    this.onChange(value);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if needed
  }
}
