import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationAutocompleteComponent } from './organisation-autocomplete.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [OrganisationAutocompleteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    AlertsFromIssuesModule,
    FuseAlertModule,
    TranslocoDirective,
  ],
  providers: [
    provideTranslocoScope({
      scope: 'organisationAutocomplete',
      loader: lazyTranslationloader,
    }),
  ],
  exports: [OrganisationAutocompleteComponent],
})
export class OrganisationAutocompleteModule {}
