import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AuthApiService,
  ValidationApiService,
  VerificationApiService,
} from '@fleet/api';
import { paths } from '@fleet/environment';
import { AuthService } from './auth.service';
import { handleApiError } from '@fleet/utilities';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NetworkApiService } from '@fleet/api';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoginModel } from '@fleet/model';

@Injectable({
  providedIn: 'root',
})
export class NetworkAuthService extends AuthService {
  host: string;
  fleetProduct: string;
  nonAuthorisedHttp: HttpClient;

  constructor(
    http: HttpClient,
    private backend: HttpBackend,
    authApiService: AuthApiService,
    verificationApiService: VerificationApiService,
    private networkApiService: NetworkApiService,
    validationApiService: ValidationApiService,
    store: Store<any>,
    @Inject('env') env: any,
    router: Router
  ) {
    super(
      authApiService,
      verificationApiService,
      router,
      validationApiService,
      store,
      http,
      env
    );
    this.nonAuthorisedHttp = new HttpClient(backend);
    this.host = env.host + paths.network;
    this.fleetProduct = env.fleetProduct;
  }

  signIn(login: LoginModel) {
    const authdata = btoa(login.username + ':' + login.password);
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Basic ' + authdata,
      'fleet-product': this.fleetProduct,
      'fleet-channel': 'WEB',
    });
    return this.nonAuthorisedHttp
      .post(`${this.host}/signin`, login.verificationToken ? login : null, {
        headers: headers,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  getUser(payload: any): Observable<any> {
    return this.networkApiService
      .getUser(payload.networkId, payload.networkUserId)
      .pipe(catchError(handleApiError));
  }

  signup(payload: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'fleet-product': this.fleetProduct,
      'fleet-channel': 'WEB',
    });
    return this.nonAuthorisedHttp
      .post(`${this.host}/signup`, payload, {
        headers: headers,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  refreshToken(jwt: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + jwt,

      'fleet-product': this.fleetProduct,
      'fleet-channel': 'WEB',
    });
    return this.nonAuthorisedHttp
      .get<string>(`${this.authHost}/refreshToken`, {
        headers: headers,
        observe: 'response',
      })
      .pipe(
        map((resp: any) => {
          const newjwt = resp.headers.get('Authorization');
          return newjwt;
        }),
        catchError((error) => null)
      );
  }

  findUser(payload: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  usernameInUse(username: string): Observable<any> {
    return of(false);
  }

  resetMFA(payload: any): Observable<any> {
    return this.http
      .post(
        `${this.host}/${payload.networkId}/user/${payload.networkUserId}/resetMFA`,
        {}
      )
      .pipe(catchError(handleApiError));
  }
}
