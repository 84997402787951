import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobConditionSelectorComponent } from './job-condition-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { NetworkConditionsModule } from '@fleet/network-shared';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [JobConditionSelectorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    NetworkConditionsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslocoDirective,
  ],
  exports: [JobConditionSelectorComponent],
  providers: [
    provideTranslocoScope({
      scope: 'jobConditionSelector',
      loader: lazyTranslationloader,
    }),
  ],
})
export class JobConditionSelectorModule {}
