import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fleet-section',
  templateUrl: './section.component.html',
  styles: `[slot=description]:empty,
[slot=error]:empty,
[slot=main]:empty {
  display: none;
}`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent implements OnInit {
  @Input() contentPadding = 'mt-6';
  @Input() title: string;
  @Input() subtitle: string;
  @Input() hasDivider = true;
  @Input() fullWidth = false;

  constructor() {}

  ngOnInit(): void {}
}
