<form
  *ngIf="form"
  class="pt-6 pb-4 px-4"
  [formGroup]="form"
  (ngSubmit)="searchTickets()"
>
  <div class="grid grid-cols-1 sm:grid-cols-3 gap-2 items-center">
    <mat-form-field>
      <mat-label class="text-sm font-light"> From</mat-label>
      <input
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        formControlName="fromDate"
        [value]="form.controls['fromDate'].value"
        matInput
        [matDatepicker]="fromDatePicker"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="fromDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label class="text-sm font-light"> To</mat-label>
      <input
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        formControlName="toDate"
        [value]="form.controls['toDate'].value"
        matInput
        [matDatepicker]="toDatePicker"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="toDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #toDatePicker></mat-datepicker>
    </mat-form-field>

    <fleet-organisation-user-autocomplete
      *ngIf="organisation"
      formControlName="organisationUserId"
      [mode]="'id'"
      [organisationId]="organisation.organisationId"
      [placeHolder]="'Organisation User'"
      [label]="'Organisation User'"
      [labelClass]="'text-sm font-light'"
    ></fleet-organisation-user-autocomplete>

    <fleet-operator-user-autocomplete
      *ngIf="operator"
      formControlName="operatorUserId"
      [mode]="'id'"
      [operatorId]="operator.operatorId"
      [placeHolder]="'Operator User'"
      [label]="'Operator User'"
      [labelClass]="'text-sm font-light'"
    ></fleet-operator-user-autocomplete>
  </div>

  <div class="flex shrink items-center justify-end border-t pt-5">
    <button
      mat-button
      matTooltip="Clear Search"
      type="button"
      (click)="clearAndSearch()"
    >
      Clear
    </button>
    <button
      class="bg-primary-600 text-white px-6 ml-3 disabled:bg-gray-200"
      type="submit"
      matTooltip="Search Tickets"
      mat-flat-button
    >
      Search
    </button>
  </div>
</form>
