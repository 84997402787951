import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

const form = {
  fromDate: new UntypedFormControl(),
  toDate: new UntypedFormControl(),
  ticketType: new UntypedFormControl(),
  driverId: new UntypedFormControl(),
  travellerId: new UntypedFormControl(),
  vehicleId: new UntypedFormControl(),
  jobId: new UntypedFormControl(),
  organisationId: new UntypedFormControl(),
  organisationUserId: new UntypedFormControl(),
  operatorId: new UntypedFormControl(),
  operatorUserId: new UntypedFormControl(),
};
@Injectable({
  providedIn: 'root',
})
export class TicketSearchForm extends UntypedFormGroup {
  constructor() {
    super(form);
  }
}
