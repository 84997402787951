import { Pipe, PipeTransform } from '@angular/core';
import { FareElementModel } from '@fleet/model';
import { groupBy } from 'lodash-es';

@Pipe({
  name: 'fareGroup',
})
export class FareGroupPipe implements PipeTransform {
  transform(elements: FareElementModel[], ...args: unknown[]): any {
    return groupBy(elements, (element) => element.group);
  }
}
