<ng-container *transloco="let t">
  <fleet-selection-list-in-drawer
    [title]="t('organisationStaffListAutocomplete.findOrganisation')"
    (cancel)="cancel.emit()"
  >
    <ng-container slot="filters">
      <mat-form-field class="w-full">
        <input
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          #input
          [placeholder]="t('organisationStaffListAutocomplete.searchName')"
          (keydown)="inputKeydown($event)"
          cdkFocusInitial
          matInput
          [formControl]="searchControl"
        />
        <mat-spinner matSuffix diameter="18" *ngIf="searching"></mat-spinner>
        <mat-icon matPrefix>search </mat-icon>
      </mat-form-field>
    </ng-container>

    <fuse-alert
      slot="error"
      *ngFor="let alert of issues | alertsFromIssues"
      class=""
      [appearance]="'outline'"
      [showIcon]="true"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>

    <ng-container slot="list">
      <mat-selection-list
        class="organisation-list selection-hide-checkboxes"
        #organisationList
      >
        <mat-list-option
          *ngFor="let organisation of organisationSearch"
          class="h-auto"
          (click)="selectOrganisation(organisation)"
          (keydown.enter)="selectOrganisation(organisation)"
        >
          <ng-container
            *ngTemplateOutlet="
              organisationTemplate;
              context: { $implicit: organisation }
            "
          ></ng-container>
        </mat-list-option>
        <mat-list-option
          *ngIf="organisationSearch?.length === 0 && searchCompleted"
          >{{
            t('organisationStaffListAutocomplete.noResults')
          }}</mat-list-option
        >
      </mat-selection-list>
    </ng-container>
  </fleet-selection-list-in-drawer>

  <ng-template #organisationTemplate let-organisation>
    <div class="flex flex-col sm:flex-row sm:items-center py-3">
      <div class="flex items-center">
        <div
          class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden"
        >
          <img
            *ngIf="organisation?.imageUrl; else noImage"
            class="w-10 h-10 rounded-full object-cover"
            [src]="organisation.imageUrl"
            alt="Organisation photo"
          />
          <ng-template #noImage>
            <span
              class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500"
            >
              <span class="text-xl font-medium leading-none text-white">
                {{ organisation.name ? organisation.name.substring(0, 2) : '' }}
              </span>
            </span>
          </ng-template>
        </div>
        <div class="ml-4">
          <div class="font-medium">{{ organisation.name }}</div>
          <div class="text-sm text-secondary">
            {{ organisation.category }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
