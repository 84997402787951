import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NetworkApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import {
  ApiResponse,
  NetworkServiceModel,
  NetworkServiceSearchResultModel,
} from '@fleet/model';

@Component({
  selector: 'fleet-network-service-selector',
  templateUrl: './network-service-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NetworkServiceSelectorComponent),
      multi: true,
    },
  ],
})
export class NetworkServiceSelectorComponent implements OnInit {
  @Input() multiSelector = false;

  @Input() placeholder: string;
  @Input() matLabelClasses = false;
  @Input() classes: string;
  @Input() label: string;
  @Input() prefixIcon = 'search';
  @Input() viewMode: 'SELECT' | 'LIST' = 'SELECT';
  control = new UntypedFormControl();
  @Input() mode = 'id';
  networkServices: NetworkServiceSearchResultModel[] = [];

  constructor(
    private networkApiService: NetworkApiService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.networkApiService
      .searchNetworkServices(this.authService.networkId, {})
      .subscribe({
        next: (
          resp: HttpResponse<ApiResponse<NetworkServiceSearchResultModel[]>>
        ) => {
          this.networkServices = resp.body.data;
          this.changeDetectorRef.markForCheck();
        },
      });

    this.control.valueChanges.subscribe((value: any) => {
      if (value) {
        if (this.mode === 'id') {
          this.onChange(value);
        } else {
          //object
          const networkServices = value.map((networkServiceId: string) =>
            this.networkServices.find(
              (s) => s.networkServiceId === networkServiceId
            )
          );

          this.onChange(networkServices);
        }
      } else {
        this.onChange(null);
      }
      this.onTouched();
    });
  }

  onChange: any = (group: any) => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      if (typeof value === 'string') {
        const networkServices = this.networkServices.find(
          (s) => s.networkServiceId === value
        );

        this.control.setValue(
          this.multiSelector ? [networkServices] : networkServices,
          {
            emitEvent: false,
          }
        );
      } else if (Array.isArray(value)) {
        if (value.every((item) => typeof item === 'string')) {
          this.control.setValue(value, { emitEvent: false });
        } else {
          this.control.setValue(
            value.map(
              (networkService: NetworkServiceSearchResultModel) =>
                networkService.networkServiceId
            ),
            { emitEvent: false }
          );
        }
      } else {
        this.control.setValue(value.networkServiceId, { emitEvent: false });
      }
    } else {
      this.control.setValue(null, { emitEvent: false });
    }
  }
}
