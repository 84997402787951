import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  EntityDetailLayoutModule,
  EntityHeaderWithBreadcrumbsModule,
} from '@fleet/layout';
import { JobDetailContainerComponent } from './components/job-detail-container/job-detail-container.component';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { TicketActionButtonModule } from '@fleet/ticket';
import { JobActionModule } from '../job-action/job-action.module';
import { JobCallModule } from '../job-call/job-call.module';
import { JobExtendExpiryModule } from '../job-extend-expiry/job-extend-expiry.module';
import { JobUnassignDialogModule } from '../job-unassign-dialog/job-unassign-dialog.module';
import { jobDetailRoutes } from './job-detail.routing';
import { JobListenerService } from '../services/job-listener.service';
import { provideTranslocoScope } from '@jsverse/transloco';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);

@NgModule({
  declarations: [JobDetailContainerComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(jobDetailRoutes),
    EntityDetailLayoutModule,
    TicketActionButtonModule,
    JobActionModule,
    JobUnassignDialogModule,
    MatTooltipModule,
    MatIconModule,
    EntityHeaderWithBreadcrumbsModule,
    HasFunctionPipeModule,
    JobCallModule,

    JobExtendExpiryModule,
  ],
  providers: [
    JobListenerService,

    provideTranslocoScope({
      scope: 'jobDetail',
      loader: lazyTranslationloader,
    }),
  ],
})
export class JobDetailModule {}
