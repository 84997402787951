import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnInit, OnDestroy } from '@angular/core';
import { DriverApiService, SettingsApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import { SettingService } from '@fleet/setting';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, filter, Subject, Subscription, takeUntil } from 'rxjs';
import {
  AccountModel,
  ApiResponse,
  DriverModel,
  IssueModel,
  NetworkGroupModel,
  PaymentMethodModel,
  PaymentMethodSearchResultModel,
} from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import {
  FormControl,
  ReactiveFormsModule,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  AbstractControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import {
  ButtonComponent,
  RadioGroupListWithDescriptionComponent,
} from '@fleet/ui';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { AccountModelFormModule } from '@fleet/payment-shared';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule, fuseAnimations } from '@fleet/fuse';
import { evereeLogo } from '@fleet/shared';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    return acc;
  },
  {}
);
@Component({
  selector: 'fleet-disbursement-method',
  standalone: true,
  animations: fuseAnimations,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatRadioModule,
    RadioGroupListWithDescriptionComponent,
    AccountModelFormModule,
    TranslocoDirective,
    ButtonComponent,
    TitleCaseAndCleanPipeModule,
    AlertsFromIssuesModule,
    FuseAlertModule,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideTranslocoScope({
      scope: 'disbursementMethod',
      loader: lazyTranslationloader,
    }),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DisbursementMethodComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DisbursementMethodComponent),
      multi: true,
    },
  ],
  template: `
    <div
      class="flex flex-col space-y-5"
      *ngIf="
        disbursementProviderControl &&
        accountForm &&
        mappedPaymentProviders?.length > 0
      "
    >
      <ng-container *transloco="let t">
        <div
          class="flex flex-col"
          *ngIf="
            mappedPaymentProviders.length > 1 && !disbursementMethod;
            else showLabel
          "
        >
          <fleet-radio-group-list-with-description
            [options]="mappedPaymentProviders"
            [formControl]="disbursementProviderControl"
            [label]="t('disbursementMethod.disbursementProviderLabel')"
          ></fleet-radio-group-list-with-description>

          <mat-error *ngIf="disbursementProviderControl.hasError('required')">
            {{ t('disbursementMethod.disbursementProviderErrorsRequired') }}
          </mat-error>
        </div>

        <ng-template #showLabel>
          <ng-container [ngSwitch]="disbursementProviderControl.value">
            <ng-container *ngSwitchCase="'EVEREE'">
              <div class="flex items-center justify-start text-lg font-medium">
                <img
                  [src]="evereeLogo"
                  alt="Everee Logo"
                  style="width: 30px; height: 30px; margin-right: 5px;"
                />
                Create an
                {{ disbursementProviderControl.value | titleCaseAndClean }}
                disbursement account
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-container>

      <fleet-account-model-form
        *ngIf="disbursementProviderControl.value"
        [paymentType]="selectedPaymentType"
        [disbursementFrequencies]="mappedDisbursementFrequencies"
        [bankAccountTypes]="bankAccountTypes"
        [formControl]="accountForm"
      >
      </fleet-account-model-form>

      <fuse-alert
        *ngFor="let alert of issues | alertsFromIssues"
        class="py-3"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type"
        [@shake]="alert.type === 'error'"
      >
        {{ alert.message }}
      </fuse-alert>

      <div class="flex justify-end mt-4" *ngIf="mode === 'FORM'">
        <fleet-button
          *transloco="let t"
          [variant]="'rounded'"
          [color]="'primary'"
          [disabled]="!accountForm.valid || loading"
          (click)="submit()"
          [label]="
            disbursementMethod
              ? t('disbursementMethod.updateButtonLabel')
              : t('disbursementMethod.saveButtonLabel')
          "
          [loading]="loading"
        ></fleet-button>
      </div>
    </div>
  `,
  styles: [
    `
      /* Your CSS styles here */
    `,
  ],
})
export class DisbursementMethodComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator, AfterViewInit
{
  evereeLogo = evereeLogo;
  singleOptionLabel = '';
  @Input() disabled: boolean;
  @Input() mode: 'FORM' | 'SIDEBAR' = 'FORM';
  accountForm: FormControl = new FormControl(null, [Validators.required]);
  disbursementProviderControl = new FormControl();
  bankAccountTypeControl = new FormControl();
  frequencyControl = new FormControl();

  _disbursementMethod:
    | PaymentMethodModel
    | PaymentMethodSearchResultModel
    | any;

  @Input() set disbursementMethod(
    value: PaymentMethodModel | PaymentMethodSearchResultModel | any
  ) {
    this._disbursementMethod = value;

    if (value) {
      setTimeout(() => {
        this.disbursementProviderControl.setValue(
          this.disbursementMethod.type
            ? this.disbursementMethod.type
            : this.disbursementMethod.paymentType
        );

        this.disbursementProviderControl.updateValueAndValidity();

        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
        this.accountForm.patchValue(
          this.disbursementMethod.account
            ? this.disbursementMethod.account
            : this.disbursementMethod
        );
        // this.bankAccountTypeControl.patchValue(patch.accountType);
        this.frequencyControl.patchValue(
          this.disbursementMethod.account
            ? this.disbursementMethod.account.disbursementFrequency
            : this.disbursementMethod.disbursementFrequency
        );
      }, 150);
    }
  }

  get disbursementMethod():
    | PaymentMethodModel
    | PaymentMethodSearchResultModel
    | any {
    return this._disbursementMethod;
  }

  sharedData: any;

  currentSettings: any;

  @Output() successful = new EventEmitter();
  @Output() failure = new EventEmitter();

  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;
    // if (value && value.disbursementPaymentMethod) {
    //   this.disbursementMethod = value.disbursementPaymentMethod;
    // }
  }

  get driver() {
    return this._driver;
  }
  @Output() driverUpdated = new EventEmitter();
  private settingSubscription: Subscription;

  selectedPaymentType: string;
  loading = false;
  issues: IssueModel[] = [];
  setting: any;
  networkGroup: NetworkGroupModel;

  paymentProviders: any = [];
  bankAccountTypes: any = [];
  disbursementFrequencies: any = [];
  mappedPaymentProviders: any = [];
  mappedDisbursementFrequencies: any = [];

  private _unsubscribeAll: Subject<any> = new Subject();

  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor(
    private settingService: SettingService,
    private settingApiService: SettingsApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private networkGroupService: NetworkGroupService,
    private settingsService: SettingService,
    private driverApiService: DriverApiService
  ) {
    this.settingService
      .getSettingDefinitionByPath$('network.disbursement')
      .pipe(
        takeUntilDestroyed(),
        filter((settingsResp) => !!settingsResp)
      )
      .subscribe({
        next: (settingsResp) => {
          if (settingsResp.setting) {
            this.currentSettings = settingsResp.setting.data;
          }

          const sharedSettings = settingsResp.sharedData.data;
          this.buildSettings(this.currentSettings, sharedSettings);

          this.accountForm.valueChanges.subscribe(() => {
            this.propagateChange();
          });

          this.disbursementProviderControl.valueChanges.subscribe(
            (provider) => {
              if (provider) {
                this.updateBankAccountTypes(provider);
                this.updateDisbursementFrequencies(provider);
                this.propagateChange();
              }
            }
          );
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        },
        error: (err: any) => {},
      });
  }

  ngOnInit(): void {
    // Initialize form controls or any other necessary setup
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  refresh() {
    // Implement refresh logic if needed
  }

  submit() {
    if (this.disbursementMethod) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    this.issues = [];
    const accountPayload = this.accountForm.value as AccountModel;

    const paymentMethod = <PaymentMethodModel>{
      account: accountPayload ? accountPayload : null,
      displayName: accountPayload
        ? accountPayload.accountName.toUpperCase()
        : null,
      type: this.selectedPaymentType ? this.selectedPaymentType : null,
    };

    let apiCall;

    if (this.driver) {
      apiCall = this.driverApiService.createPaymentMethod(
        this.driver.driverId,
        paymentMethod
      );
    }

    this.changeDetectorRef.markForCheck();
    apiCall.subscribe({
      next: (response: ApiResponse<PaymentMethodModel> | any) => {
        // Handle successful response
        console.log('Payment method created successfully', response);
        this.loading = false;

        const driver = Object.assign(this.driver, {
          disbursementPaymentMethod: response.data,
        });
        this.driverUpdated.emit(driver);
        this.changeDetectorRef.markForCheck();
      },
      error: (issues: IssueModel[]) => {
        // Handle error response
        this.loading = false;
        this.issues = issues;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  update() {
    this.loading = true;
    this.issues = [];
    const accountPayload = this.accountForm.value as AccountModel;

    const paymentMethod = <PaymentMethodModel>{
      account: {
        ...accountPayload,
        accountId: this.disbursementMethod.account.accountId,
      },
      displayName: accountPayload.accountName.toUpperCase(),
      type: this.disbursementProviderControl.value,
      paymentMethodId: this.disbursementMethod.paymentMethodId,
    };

    let apiCall;

    if (this.driver) {
      apiCall = this.driverApiService.updatePaymentMethod(
        this.driver.driverId,
        paymentMethod
      );
    }

    this.changeDetectorRef.markForCheck();
    apiCall.subscribe({
      next: (response: ApiResponse<PaymentMethodModel> | any) => {
        // Handle successful response
        this.loading = false;

        const driver = Object.assign(this.driver, {
          disbursementPaymentMethod: response.data,
        });
        this.driverUpdated.emit(driver);
        this.changeDetectorRef.markForCheck();
      },
      error: (issues: IssueModel[]) => {
        // Handle error response
        this.loading = false;
        this.issues = issues;
        this.changeDetectorRef.markForCheck();
      },
    });
  }
  buildSettings(disbursementSettings: any, sharedSettings: any) {
    const settings = disbursementSettings.disbursementMethods.map(
      (method: any) => {
        const provider = sharedSettings.disbursementMethods.find(
          (provider: any) => provider.paymentProvider === method.paymentProvider
        );

        return {
          ...method,
          bankAccountTypes: provider?.bankAccountTypes || [],
          disbursementFrequencies: method.disbursementFrequencies.map(
            (freq: any) => {
              const sharedFreq = sharedSettings.disbursementFrequencies.find(
                (sharedFreq: any) => sharedFreq.frequency === freq.frequency
              );
              return {
                ...freq,
                value: sharedFreq?.value || '',
                description: sharedFreq?.description || '',
              };
            }
          ),
        };
      }
    );

    this.currentSettings = { disbursementMethods: settings };

    // Set the form controls with the available options
    this.paymentProviders = settings.map((method: any) => {
      const provider = sharedSettings.disbursementMethods.find(
        (provider: any) => provider.paymentProvider === method.paymentProvider
      );
      return {
        paymentProvider: method.paymentProvider,
        value: provider?.value || method.paymentProvider,
        description: provider?.description || '',
        paymentType: provider?.paymentType
          ? provider?.paymentType
          : method?.paymentType,
      };
    });

    this.mappedPaymentProviders = this.paymentProviders.map(
      (provider: any) => ({
        value: provider.paymentProvider,
        name: provider.value,
        description: provider.description || '',
        paymentType: provider.paymentType || '',
      })
    );

    if (this.mappedPaymentProviders.length === 1) {
      this.disbursementProviderControl.patchValue(
        this.mappedPaymentProviders[0].value
      );

      this.selectedPaymentType = this.mappedPaymentProviders[0]?.paymentType;
      this.changeDetectorRef.markForCheck();
      // this.singleOptionLabel =
      //   'Create ' + this.mappedPaymentProviders[0].description;
    }

    this.bankAccountTypes = settings.flatMap(
      (method: any) => method.bankAccountTypes
    );
    this.disbursementFrequencies = settings.flatMap((method: any) =>
      method.disbursementFrequencies.map((freq: any) => {
        const sharedFreq = sharedSettings.disbursementFrequencies.find(
          (sharedFreq: any) => sharedFreq.frequency === freq.frequency
        );
        return {
          ...freq,
          value: sharedFreq?.value || freq.frequency,
          description: sharedFreq?.description || '',
        };
      })
    );
    this.mappedDisbursementFrequencies = this.disbursementFrequencies.map(
      (freq: any) => ({
        value: freq.frequency,
        name: freq.value,
        description: freq.description,
      })
    );

    this.changeDetectorRef.markForCheck();
  }

  updateBankAccountTypes(provider: string) {
    const selectedMethod = this.currentSettings.disbursementMethods.find(
      (method: any) => method.paymentProvider === provider
    );
    this.bankAccountTypes = selectedMethod?.bankAccountTypes || [];
  }

  updateDisbursementFrequencies(provider: string) {
    const selectedMethod = this.currentSettings.disbursementMethods.find(
      (method: any) => method.paymentProvider === provider
    );
    this.disbursementFrequencies =
      selectedMethod?.disbursementFrequencies || [];
    this.mappedDisbursementFrequencies = this.disbursementFrequencies.map(
      (freq: any) => ({
        value: freq.frequency,
        name: freq.value,
        description: freq.description,
      })
    );
  }

  writeValue(value: any): void {
    if (value) {
      const flattenedValue = {
        bankCode: value.bankCode,
        bankName: value.bankName,
        accountType: value.accountType,
        accountNumber: value.accountNumber,
        accountName: value.accountName,
        disbursementFrequency: value.disbursementFrequency,
      };

      this.disbursementProviderControl.setValue(value.paymentProvider, {
        emitEvent: false,
      });
      this.accountForm.setValue(flattenedValue, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.disbursementProviderControl.disable();
      this.accountForm.disable();
    } else {
      this.disbursementProviderControl.enable();
      this.accountForm.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.accountForm.valid
      ? null
      : { invalidForm: { valid: false, message: 'Account form is invalid' } };
  }

  private propagateChange() {
    if (
      this.onChange &&
      this.disbursementProviderControl.value &&
      this.accountForm.value
    ) {
      const flattenedValues = {
        ...this.accountForm.value,
      };

      this.onChange(flattenedValues);
    }
  }
}
