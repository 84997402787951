import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DisbursementApiService {
  host;
  disbursementHost;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.admin;
    this.disbursementHost = env.host + paths.disbursement;
  }

  // searchRecentDisbursements() {
  //   return this.http
  //     .get(`${this.disbursementHost}/batch/search`, {
  //       params: {
  //         limit: '100',
  //         offset: '0',
  //       },
  //     })
  //     .pipe(catchError(handleApiError));
  // }

  searchDisbursementBatches(params: any) {
    return this.http
      .get(`${this.disbursementHost}/batch/search`, {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  searchDisbursements(params: any) {
    return this.http
      .get(`${this.disbursementHost}/search`, {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  rejectDisbursement(rejectionModel: any, disbursementId: string) {
    return this.http
      .put(`${this.disbursementHost}/${disbursementId}/reject`, rejectionModel)
      .pipe(catchError(handleApiError));
  }

  initiateDisbursements(initiateModel: any) {
    return this.http
      .post(`${this.disbursementHost}`, initiateModel)
      .pipe(catchError(handleApiError));
  }

  getRejectionReasons() {
    return this.http
      .get(`${this.disbursementHost}/rejectionReasons`)
      .pipe(catchError(handleApiError));
  }

  downloadDisbursement(disbursementBatchId: string): Observable<any> {
    return this.http
      .get(`${this.disbursementHost}/batch/${disbursementBatchId}`, {
        observe: 'response',
        responseType: 'blob' as 'json',
        params: { disbursementType: 'WESTPAC' },
      })
      .pipe(catchError(handleApiError));
  }
}
