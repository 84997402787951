<ng-container *transloco="let t">
  <fleet-responsive-element class="flex flex-grow w-full">
    <ng-container slot="xs">
      <ng-container *ngTemplateOutlet="mobile"></ng-container>
    </ng-container>
    <ng-container slot="sm">
      <ng-container *ngTemplateOutlet="default"></ng-container>
    </ng-container>
    <ng-container slot="md">
      <ng-container *ngTemplateOutlet="default"></ng-container>
    </ng-container>
    <ng-container slot="lg">
      <ng-container *ngTemplateOutlet="default"></ng-container>
    </ng-container>
    <ng-container slot="xl">
      <ng-container *ngTemplateOutlet="default"></ng-container>
    </ng-container>
  </fleet-responsive-element>

  <ng-template #default>
    <mat-table #table [dataSource]="search.data" class="w-full">
      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef class="max-w-36">
          {{ t('ticketSearchResults.created') }}
        </mat-header-cell>
        <mat-cell
          (click)="selectTicket.emit(ticket)"
          *matCellDef="let ticket"
          class="flex-grow max-w-36"
        >
          <span class="line-clamp-2">{{
            ticket.created | internationalizationDate : 'CONDENSED_DATE'
          }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="updated">
        <mat-header-cell *matHeaderCellDef class="max-w-36">
          {{ t('ticketSearchResults.updated') }}
        </mat-header-cell>
        <mat-cell
          (click)="selectTicket.emit(ticket)"
          *matCellDef="let ticket"
          class="flex-grow max-w-36"
        >
          <span class="line-clamp-2">{{
            ticket.updated | internationalizationDate : 'CONDENSED_DATE'
          }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="subject">
        <mat-header-cell *matHeaderCellDef class="flex-grow">
          {{ t('ticketSearchResults.subject') }}
        </mat-header-cell>
        <mat-cell
          (click)="selectTicket.emit(ticket)"
          *matCellDef="let ticket"
          class="flex-grow"
        >
          <span matTooltip="{{ ticket.subject }}" class="line-clamp-2">
            {{ ticket.subject }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef class="flex-grow max-w-20">
          {{ t('ticketSearchResults.status') }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let ticket"
          (click)="selectTicket.emit(ticket)"
          class="flex-grow max-w-20"
        >
          <fleet-status-chip [status]="ticket.status"></fleet-status-chip>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef class="flex-grow max-w-60">
          {{ t('ticketSearchResults.category') }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let ticket"
          (click)="selectTicket.emit(ticket)"
          class="flex-grow max-w-60"
        >
          <span class="line-clamp-2">{{ ticket.ticketCategoryId }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="associations">
        <mat-header-cell *matHeaderCellDef class="flex-grow max-w-40">
          {{ t('ticketSearchResults.associations') }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let ticket"
          (click)="selectTicket.emit(ticket)"
          class="flex-grow max-w-40"
        >
          <div class="grid grid-cols-3 gap-2">
            <ng-container *ngFor="let association of ticket.associations">
              <ng-container *ngIf="association.type !== excludeType">
                <div
                  (click)="
                    viewAssociation(association); $event.stopPropagation()
                  "
                  matTooltip="{{
                    t('ticketSearchResults.navigateTo', {
                      entity: association.type | lowercase
                    })
                  }}"
                  class="flex justify-center items-center"
                >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'DRIVER'"
                    >person</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'TRAVELLER'"
                    >hail</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'ORGANISATION'"
                    >business</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'JOB'"
                    >place</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'OPERATOR'"
                    >supervised_user_circle</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'VEHICLE'"
                    >local_taxi</mat-icon
                  >
                </div>
              </ng-container>
            </ng-container>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="content">
        <mat-header-cell *matHeaderCellDef class="flex-grow">
          {{ t('ticketSearchResults.content') }}
        </mat-header-cell>
        <mat-cell
          (click)="selectTicket.emit(ticket)"
          *matCellDef="let ticket"
          class="flex-grow line-clamp-3"
        >
          {{ ticket.content }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell
          class="justify-end flex-none w-18"
          *matHeaderCellDef
        ></mat-header-cell>
        <mat-cell class="justify-end flex-none w-18" *matCellDef="let ticket">
          <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
            <!-- <button
              mat-menu-item
              *ngIf="allowLinking"
              (click)="linkTicket.emit(ticket)"
            >
              {{ t('ticketSearchResults.link') }}
            </button> -->

            <button
              *ngIf="allowEditing"
              mat-menu-item
              (click)="editTicket.emit(ticket)"
            >
              Edit
            </button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="
          allowLinking ? DEFAULT_COLUMNS_JOB : DEFAULT_COLUMNS_ENTITY
        "
      >
      </mat-header-row>
      <mat-row
        class="cursor-pointer py-2"
        *matRowDef="
          let row;
          columns: allowLinking ? DEFAULT_COLUMNS_JOB : DEFAULT_COLUMNS_ENTITY
        "
      >
      </mat-row>
    </mat-table>
  </ng-template>

  <ng-template #mobile>
    <div class="grid grid-cols-1 w-full bg-white">
      <div
        *ngFor="let ticket of search.data"
        class="relative border p-4 grid grid-cols-1 cursor-pointer"
        (click)="selectTicket.emit(ticket)"
      >
        <div class="flex flex-col gap-1 relative">
          <div class="font-bold">
            {{ ticket.created | internationalizationDate : 'CONDENSED_DATE' }}
          </div>
          <div>{{ ticket.ticketCategoryId }}</div>
          <div>{{ ticket.subject }}</div>
          <div>
            {{ ticket.updated | internationalizationDate : 'CONDENSED_DATE' }}
          </div>
          <fleet-status-chip [status]="ticket.status"></fleet-status-chip>

          <div
            class="absolute right-0 top-1/2 transform -translate-y-1/2"
            *ngIf="allowLinking"
          >
            <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
              <button mat-menu-item (click)="linkTicket.emit(ticket)">
                {{ t('ticketSearchResults.link') }}
              </button>
              <!-- Additional actions can be added here -->
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <div
            class="flex flex-row gap-2 mt-1"
            *ngIf="ticket.associations && ticket.associations.length > 0"
          >
            <ng-container *ngFor="let association of ticket.associations">
              <ng-container *ngIf="association.type !== excludeType">
                <div
                  (click)="
                    viewAssociation(association); $event.stopPropagation()
                  "
                  matTooltip="{{
                    t('ticketSearchResults.navigateTo', {
                      entity: association.type | lowercase
                    })
                  }}"
                  class="flex justify-center items-center"
                >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'DRIVER'"
                    >person</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'TRAVELLER'"
                    >hail</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'ORGANISATION'"
                    >business</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'JOB'"
                    >place</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'OPERATOR'"
                    >supervised_user_circle</mat-icon
                  >
                  <mat-icon
                    class="cursor-pointer"
                    *ngIf="association.type === 'VEHICLE'"
                    >local_taxi</mat-icon
                  >
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
