import { FuseNavigationItem } from '@fleet/model';
export const organisationMenu = (): FuseNavigationItem[] => [
  {
    id: 'overview',
    title: 'overview',
    icon: 'widgets',
    link: '/detail',
    type: 'basic',
    exactMatch: false,
    securityFunctions: ['ORGANISATION:read'],
  },
  {
    id: 'organisation-jobs',
    title: 'jobs',
    type: 'collapsable',
    link: '/job',
    icon: 'place',
    securityFunctions: [
      'JOB_DETAIL:read',
      'ORGANISATION_JOB_SEARCH:read',
      'ORGANISATION_JOB_IMPORT:read',
      'JOB_ACTIVITY:read',
      'JOB_DETAIL:create',
    ],
    children: [
      {
        id: 'organisation-job-search',
        type: 'basic',
        link: '/job/search',
        icon: 'search',
        title: 'search',
        securityFunctions: ['ORGANISATION_JOB_SEARCH:read'],
      },
      {
        id: 'organisation-job-import',
        type: 'basic',
        link: '/job/import',
        icon: 'cloud_upload',
        title: 'importJobs',
        securityFunctions: ['ORGANISATION_JOB_IMPORT:read'],
      },
      {
        id: 'active-jobs',
        type: 'basic',
        link: '/job/active',
        icon: 'bolt',
        title: 'activeJobs',
        securityFunctions: ['JOB_ACTIVITY:read'],
      },
      {
        id: 'organisation-job-new',
        type: 'basic',
        link: '/job/new',
        icon: 'add',
        title: 'newJob',
        securityFunctions: ['JOB_DETAIL:create'],
      },
      {
        id: 'organisation-kiosk',
        type: 'basic',
        link: '/kiosk',
        icon: 'web_asset',
        title: 'bookingKiosk',
        securityFunctions: ['JOB_DETAIL:create'],
      },
    ],
  },
  {
    id: 'staff',
    title: 'staff',
    type: 'basic',
    icon: 'person',
    link: '/staff',
    securityFunctions: ['ORGANISATION_STAFF_SEARCH:read'],
  },
  {
    id: 'group',
    title: 'groups',
    type: 'basic',
    icon: 'group',
    link: '/group',
    securityFunctions: ['ORGANISATION_GROUP_SEARCH:read'],
  },
  {
    id: 'policy',
    title: 'policies',
    type: 'collapsable',
    icon: 'policy',
    link: '/policy/search',
    securityFunctions: ['ORGANISATION_TRAVEL_POLICY_SEARCH:read'],
    children: [
      {
        id: 'policy.search',
        title: 'search',
        type: 'basic',
        icon: 'search',
        link: '/policy/search',
        exactMatch: true,
        securityFunctions: ['ORGANISATION_TRAVEL_POLICY_SEARCH:read'],
      },
      // {
      //   id: 'policy.radius',
      //   icon: 'place',
      //   title: translocoService.translate('radiuses'),
      //   type: 'basic',
      //   link: '/policy/radius',
      //   exactMatch: true,
      // },
      {
        id: 'policy.boundary',
        icon: 'room',
        title: 'boundaries',
        type: 'basic',
        link: '/policy/boundary',
        exactMatch: true,
        securityFunctions: ['ORGANISATION_BOUNDARY_SEARCH:read'],
      },
    ],
  },
];
