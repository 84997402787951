import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationFormModule, PlacesAutocompleteModule } from '@fleet/location';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  DriverModel,
  LocationModel,
  OperatorModel,
  OrganisationModel,
  TravellerModel,
} from '@fleet/model';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { SectionModule } from '@fleet/ui';
import { MobileFormatModule } from '@fleet/pipes';
import { NetworkGroupService } from '@fleet/network-group';
import { Subject, takeUntil } from 'rxjs';
import { NgxMatIntlTelInputModule } from '@fleet/custom-controls';
interface StripeBillingDetails {
  name: string; // Full name
  email?: string; // Optional
  phone?: string; // Optional
  address: {
    line1: string; // Street address
    city: string;
    state?: string; // Optional depending on the country
    postal_code: string;
    country: string;
  };
}

interface BraintreeBillingDetails {
  firstName?: string; // The first name associated with the billing address.
  lastName?: string; // The last name associated with the billing address.
  phoneNumber?: string; // The phone number associated with the billing address.
  streetAddress: string; // Line 1 of the billing address (e.g., number, street, etc.).
  extendedAddress?: string; // Line 2 of the billing address (e.g., suite, apt #, etc.).
  line3?: string; // Optional Line 3 of the billing address if needed.
  locality: string; // The locality (city) name associated with the billing address.
  region?: string; // The state or region, using an ISO 3166-2 subdivision code.
  postalCode: string; // The postal code or equivalent.
  countryCodeAlpha2?: string; // The 2-character country code (ISO 3166-1 Alpha-2).
}
@Component({
  selector: 'fleet-billing-address',
  standalone: true,
  imports: [
    CommonModule,
    LocationFormModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    SectionModule,
    MobileFormatModule,
    PlacesAutocompleteModule,
    NgxMatIntlTelInputModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BillingAddressComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BillingAddressComponent),
      multi: true,
    },
  ],
  template: `
    <ng-container *ngIf="editing; else viewMode">
      <form
        [formGroup]="detailForm"
        *ngIf="detailForm"
        class="flex flex-col gap-1"
      >
        <mat-form-field class="w-full" *ngIf="type === 'STRIPE'">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" formControlName="name" />
          <mat-error *ngIf="detailForm.get('name')?.hasError('required')">
            Name is required
          </mat-error>
          <mat-error *ngIf="detailForm.get('name')?.hasError('pattern')">
            Please enter a valid name
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="type === 'BRAINTREE'">
          <div class="flex flex-row gap-3">
            <mat-form-field class="w-full">
              <mat-label>First name</mat-label>
              <input
                matInput
                placeholder="First name"
                formControlName="firstName"
              />
              <mat-error
                *ngIf="detailForm.get('firstName')?.hasError('required')"
              >
                First name is required
              </mat-error>
              <mat-error
                *ngIf="detailForm.get('firstName')?.hasError('pattern')"
              >
                Please enter a valid first name
              </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Last name</mat-label>
              <input
                matInput
                placeholder="Last name"
                formControlName="lastName"
              />
              <mat-error
                *ngIf="detailForm.get('lastName')?.hasError('required')"
              >
                Last name is required
              </mat-error>
              <mat-error
                *ngIf="detailForm.get('lastName')?.hasError('pattern')"
              >
                Please enter a valid last name
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <!-- <mat-form-field class="w-full">
          <mat-label>Phone Number</mat-label>
          <input matInput placeholder="Phone Number" formControlName="phone" />
          <mat-error *ngIf="detailForm.get('phone')?.hasError('required')">
            Phone number is required
          </mat-error>
          <mat-error *ngIf="detailForm.get('phone')?.hasError('pattern')">
            Please enter a valid phone number
          </mat-error>
        </mat-form-field> -->

        <mat-form-field class="w-full">
          <mat-label>Phone number</mat-label>

          <ngx-mat-intl-tel-input
            [required]="true"
            inputPlaceholder="Phone Number"
            [enablePlaceholder]="true"
            [onlyCountries]="['au', 'us']"
            formControlName="phone"
          ></ngx-mat-intl-tel-input>

          <mat-error *ngIf="detailForm.get('phone').errors?.validatePhoneNumber"
            >Invalid Phone Number</mat-error
          >
          <mat-error *ngIf="detailForm.get('phone').errors?.required">
            Phone number is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email" />
          <mat-error *ngIf="detailForm.get('email')?.hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="detailForm.get('email')?.hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <fleet-places-autocomplete
          [locationSearchLatLng]="locationSearchLatLng"
          *ngIf="placesAutocomplete"
          [formControl]="placesAutocomplete"
          [locationSearchCountryCode]="locationSearchCountryCode"
          label="Billing address"
          [placeHolder]="'Search Address'"
        >
        </fleet-places-autocomplete>

        <ng-container *ngIf="placesAutocomplete?.value">
          <div class="flex flex-row gap-2 w-full">
            <mat-form-field class="flex-auto">
              <mat-label>Addres Line 1</mat-label>
              <input
                matInput
                placeholder="Address Line 1"
                formControlName="line1"
              />
              <mat-error *ngIf="detailForm.get('line1')?.hasError('required')">
                Addres Line 1 is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="flex flex-row gap-2 w-full">
            <mat-form-field class="flex-auto">
              <mat-label>Address Line 2</mat-label>
              <input
                matInput
                placeholder="Address Line 2"
                formControlName="line2"
              />
              <mat-error *ngIf="detailForm.get('line2')?.hasError('required')">
                Address Line 2 is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex flex-row gap-2 w-full">
            <mat-form-field class="flex-auto">
              <mat-label>City</mat-label>
              <input matInput placeholder="City" formControlName="city" />
              <mat-error *ngIf="detailForm.get('city')?.hasError('required')">
                City is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="flex-auto">
              <mat-label>State</mat-label>
              <input matInput placeholder="State" formControlName="state" />
              <mat-error *ngIf="detailForm.get('state')?.hasError('required')">
                State is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="flex flex-row gap-2 w-full">
            <mat-form-field class="flex-auto">
              <mat-label>Postal Code</mat-label>
              <input
                matInput
                placeholder="Postal Code"
                formControlName="postal_code"
              />
              <mat-error
                *ngIf="detailForm.get('postal_code')?.hasError('required')"
              >
                Postal Code is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="flex-auto">
              <mat-label>Country</mat-label>
              <mat-select formControlName="country">
                <mat-option value="AUS">Australia</mat-option>

                <mat-option value="USA">United States</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
      </form>
    </ng-container>
    <ng-template #viewMode>
      <div class="flex flex-col gap-1" slot="main">
        <div *ngIf="type === 'STRIPE'">
          <div *ngIf="detailForm.get('name').value" class="font-bold">
            {{ detailForm.get('name').value }}
          </div>
        </div>

        <div *ngIf="type === 'BRAINTREE'" class="flex flex-row font-bold gap-1">
          <div *ngIf="detailForm.get('firstName').value">
            {{ detailForm.get('firstName').value }}
          </div>
          <div *ngIf="detailForm.get('lastName').value">
            {{ detailForm.get('lastName').value }}
          </div>
        </div>

        <div *ngIf="driver" class="flex flex-col gap-1">
          <div>{{ driver?.address?.displayLine1 | uppercase }}</div>
          <div>{{ driver?.address?.displayLine2 | uppercase }}</div>
        </div>
        <div *ngIf="traveller"></div>

        <div *ngIf="organisation"></div>

        <div *ngIf="detailForm.get('email').value">
          Email: {{ detailForm.get('email').value }}
        </div>
        <div *ngIf="detailForm.get('phone').value">
          Phone number: {{ detailForm.get('phone').value | mobileFormat }}
        </div>
      </div>

      <div
        class="underline cursor-pointer mt-2 text-primary "
        (click)="editing = true"
      >
        Edit
      </div>
    </ng-template>
  `,
  styles: [
    `
      .billing-address {
        /* Add your CSS styles here */
      }
    `,
  ],
})
export class BillingAddressComponent
  implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy, Validators
{
  private _unsubscribeAll: Subject<any> = new Subject();

  placesAutocomplete: FormControl = new FormControl();
  editing = false;
  detailForm: FormGroup;
  @Input() type: 'STRIPE' | 'BRAINTREE';
  locationSearchLatLng: any;

  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      this.buildForm();

      const patchValues = {
        email: value.receiptEmail,
        phone: value.phoneNumber,
      } as any;

      if (this.type === 'STRIPE' && value.firstName) {
        patchValues['name'] =
          value.firstName + (value.lastName ? ' ' + value.lastName : '');
      } else {
        patchValues['firstName'] = value.firstName;
        patchValues['lastName'] = value.lastName;
      }

      this.editing = true;
      this.detailForm.patchValue(patchValues);
      this.detailForm.updateValueAndValidity();
    }
  }

  get traveller() {
    return this._traveller;
  }

  _organisation: OrganisationModel;

  @Input() set organisation(value: OrganisationModel) {
    this._organisation = value;
    if (value) {
      this.buildForm();

      this.editing = true;
    }
  }

  get organisation() {
    return this._organisation;
  }

  _operator: OperatorModel;

  @Input() set operator(value: OperatorModel) {
    this._operator = value;
    if (value) {
      this.buildForm();

      this.editing = true;
    }
  }

  get operator() {
    return this._operator;
  }

  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;
    if (value) {
      this.buildForm();
      const patchValues = {
        email: value.username,
        phone: value.phoneNumber,
      } as any;

      if (this.type === 'STRIPE' && value.firstName) {
        patchValues['name'] =
          value.firstName + (value.lastName ? ' ' + value.lastName : '');
      } else {
        patchValues['firstName'] = value.firstName;
        patchValues['lastName'] = value.lastName;
      }
      this.detailForm.patchValue(patchValues);

      if (!value.address || !value.address.structuredAddress) {
        this.editing = true;
      } else if (value.address) {
        this.placesAutocomplete.patchValue(value.address);
      }
      this.detailForm.updateValueAndValidity();

      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

  get driver() {
    return this._driver;
  }
  locationSearchCountryCode: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private networkGroupService: NetworkGroupService
  ) {}

  ngOnInit(): void {
    if (!this.detailForm) {
      this.buildForm();
    }

    this.networkGroupService.networkGroupAndServiceArea$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (groupServiceArea: any) => {
          if (groupServiceArea.serviceArea) {
            this.locationSearchLatLng = {
              latitude: groupServiceArea.serviceArea.point.latitude,
              longitude: groupServiceArea.serviceArea.point.longitude,
            };
          }

          if (groupServiceArea.networkGroup.countryCode) {
            this.locationSearchCountryCode =
              groupServiceArea.networkGroup.countryCode;
          } else {
            this.locationSearchCountryCode = null;
          }
        },
      });
  }

  ngAfterViewInit(): void {
    this.detailForm.updateValueAndValidity();
  }

  editBillingDetails() {
    this.editing = !this.editing;
  }

  buildForm() {
    let formGroup = {
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required]],
      line1: [null, [Validators.required]],
      line2: [null],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      postal_code: [null, [Validators.required]],
      country: [null],
    } as any;
    if (this.type === 'STRIPE') {
      formGroup['name'] = [null, [Validators.required]];
    } else {
      formGroup['firstName'] = [null, [Validators.required]];
      formGroup['lastName'] = [null, [Validators.required]];
    }

    this.detailForm = this.fb.group(formGroup);

    this.detailForm.updateValueAndValidity();

    this.detailForm.valueChanges.subscribe((changes: any) => {
      let details;
      if (this.type === 'STRIPE') {
        details = {
          name: changes.name,
          email: changes.email,
          phone: changes.phone,
          address: {
            line1: changes.line1,
            line2: changes.line2,

            city: changes.city,
            state: changes.state,
            postal_code: changes.postal_code,
            country: changes.country
              ? changes.country.substring(0, 2)
              : changes.country,
          },
        } as StripeBillingDetails;
      } else if (this.type === 'BRAINTREE') {
        details = {
          firstName: changes.firstName,
          lastName: changes.lastName,
          email: changes.email,
          phone: changes.phone,
          streetAddress: changes.line1,
          extendedAddress: changes.line2,
          locality: changes.city,
          region: changes.state,
          postalCode: changes.postal_code,
          countryCodeAlpha2: changes.country,
        } as BraintreeBillingDetails;
      }

      this.onChange(details);
      this.onTouched();
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });

    this.placesAutocomplete.valueChanges.subscribe(
      (location: LocationModel) => {
        if (location) {
          let patchValues = {} as any;
          patchValues['line1'] =
            location.structuredAddress.streetNumber +
            ' ' +
            location.structuredAddress.streetName;
          patchValues['line2'] = location.structuredAddress.unitNumber
            ? location.structuredAddress.unitNumber + '/'
            : '';
          patchValues['city'] = location.structuredAddress.locality;
          patchValues['state'] = location.structuredAddress.regionCode;
          patchValues['postal_code'] = location.structuredAddress.postalCode;
          patchValues['country'] = location.structuredAddress.countryCode;

          this.detailForm.patchValue(patchValues);
        }
      }
    );
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  onValidatorChange: any = () => {};

  writeValue(address: any): void {
    // if (address && this.locationControl) {
    //   this.locationControl.setValue(address, { emitEvent: false });
    // }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.detailForm.disable();
    } else {
      this.detailForm.enable();
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.detailForm.valid ? null : { invalidForm: true };
  }
}
