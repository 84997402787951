import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { SettingsApiService, VerificationApiService } from '@fleet/api';
import {
  ApiResponse,
  IssueModel,
  LoginModel,
  MFAMethodMetaDataModel,
  MFAMethodModel,
  MFASettingModel,
  MultiFactorAuthenticationModel,
  SettingDefinitionModel,
  SettingSharedDataModel,
  SignInRequiredModel,
  VerificationModel,
} from '@fleet/model';

import {
  DialogLayoutModule,
  RadioGroupListWithDescriptionComponent,
  StackListItem,
  StackedListComponent,
} from '@fleet/ui';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ProgressButtonModule } from '@fleet/shared';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthService } from './../../services/auth.service';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule, fuseAnimations } from '@fleet/fuse';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { SettingService } from '@fleet/setting';
import { Subscription, filter, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { handleApiError, handleApiErrorSync } from '@fleet/utilities';
import { HttpErrorResponse } from '@angular/common/http';
import { Params } from '@angular/router';

@Component({
  selector: 'fleet-change-mfa-dialog',
  standalone: true,
  animations: fuseAnimations,
  imports: [
    CommonModule,
    DialogLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    ProgressButtonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    MatDialogModule,
    RadioGroupListWithDescriptionComponent,
    StackedListComponent,
  ],
  template: `
    <fleet-dialog-layout
      [title]="title"
      [iconColour]="'primary'"
      [icon]="'warning'"
      [showCancel]="!hasChanged"
    >
      <div slot="main">
        <div
          class="text-lg flex flex-col gap-4"
          *ngIf="hasChanged; else notChanged"
        >
          <div>
            {{ successMessage }}
          </div>
          <div *ngIf="signInRequired">
            You are now required to sign in again
          </div>
        </div>
        <ng-template #notChanged>
          <div class="flex flex-col gap-4">
            <ng-container *ngIf="!loading; else showSpinner">
              <div>{{ instructions }}</div>
              <ng-container
                *ngIf="
                  !mfa || (mfa?.methodType === 'NONE' && issues?.length > 0);
                  else verificationForm
                "
              >
                <fleet-radio-group-list-with-description
                  [formControl]="methodControl"
                  [options]="methodOptions"
                ></fleet-radio-group-list-with-description>

                <!-- <fleet-stacked-list
                  *ngIf="user"
                  [items]="methodOptions"
                  [carded]="true"
                  (buttonClicked)="methodControl.setValue($event.value.type)"
                >
                </fleet-stacked-list> -->
              </ng-container>
              <ng-template #verificationForm>
                <div
                  *ngIf="
                    verification?.methodType === 'SMS' ||
                    verification?.methodType === 'EMAIL'
                  "
                  class="text-left"
                >
                  <button
                    (click)="sendMFACode()"
                    class="text-primary-600 underline cursor-point text-sm hover:text-primary-700"
                  >
                    Resend Code
                  </button>
                </div>
                <div class="flex justify-center">
                  <img
                    class="w-40 h-40"
                    *ngIf="verification.qrCodeLinkUri"
                    [src]="verification.qrCodeLinkUri"
                    alt="QR Code"
                  />
                </div>
                <mat-form-field>
                  <input
                    matInput
                    #input
                    placeholder="Enter Code"
                    [formControl]="codeControl"
                  />
                </mat-form-field>
              </ng-template>
            </ng-container>
            <ng-template #showSpinner>
              <div class="w-full h-full flex items-center justify-center">
                <mat-progress-spinner
                  [mode]="'indeterminate'"
                  [diameter]="24"
                ></mat-progress-spinner>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
      <ng-container slot="action">
        <button
          mat-flat-button
          class="bg-primary-600 text-white px-6 ml-3 disabled:bg-gray-200 hover:bg-primary-500"
          (click)="next()"
          [disabled]="!methodControl.value"
          *ngIf="
            !mfa || (mfa?.methodType === 'NONE' && issues?.length > 0);
            else mfaSet
          "
        >
          Next
        </button>
        <ng-template #mfaSet>
          <fleet-progress-button
            (onClick)="verifyCode()"
            *ngIf="!hasChanged && mfa; else closeButton"
            [disabled]="!codeControl.valid"
            [state]="{ loading: verifying, buttonLabel: 'Verify Code' }"
            >Verify Code</fleet-progress-button
          >

          <ng-template #closeButton>
            <button
              mat-flat-button
              class="bg-primary-600 text-white px-6 ml-3 disabled:bg-gray-200 hover:bg-primary-500"
              [matDialogClose]="'cancelled'"
            >
              Close
            </button>
          </ng-template>
        </ng-template>
      </ng-container>

      <fuse-alert
        slot="error"
        *ngFor="let alert of issues | alertsFromIssues"
        class=""
        [appearance]="'outline'"
        [showIcon]="true"
        [type]="alert.type"
        [@shake]="alert.type === 'error'"
      >
        {{ alert.message }}
      </fuse-alert></fleet-dialog-layout
    >
  `,
  styles: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeMfaDialogComponent {
  _user: any;
  @Input() set user(value: any) {
    this._user = value;
    if (value) {
      this.verification = {
        credentialId: value.credentialId,
        availableMFAMethods: this.methodItems,
      } as VerificationModel;
    }
  }

  get user() {
    return this._user;
  }
  private _mfa: MultiFactorAuthenticationModel;
  _verification: VerificationModel;
  @Input() set verification(value: VerificationModel) {
    this._verification = value;
    if (!value?.verificationId) {
      this.title = 'Select MFA Method';
      this.instructions =
        'Please select a method for multi-factor authentication:';
      this.methodOptions = this.verification.availableMFAMethods.map(
        (method) => ({
          name: method.value,
          value: method.type,
          description: method.description,
        })
      );
    }
  }

  get verification(): VerificationModel {
    return this._verification;
  }

  @Input() login: LoginModel;
  @Input() samlLoginParams: any;
  @Input() noLogout: boolean;

  @Input()
  set mfa(value: MultiFactorAuthenticationModel) {
    this._mfa = value;
    if (this.mfa) {
      if (this.user && value.methodType === 'NONE') {
        //reset
        this.authService.resetMFA(this.user).subscribe({
          next: (resp: any) => {
            this.hasChanged = true;
            this.successMessage = 'Your MFA has now been disabled';
            const userWithMfa = Object.assign({}, this.user, {
              mfaEnabled: false,
              mfaType: null,
            });

            this.changeMFASuccessWithUser.emit(userWithMfa);
            this.loading = false;
            this.cdr.markForCheck();
          },
          error: (issues: IssueModel[]) => {
            this.issues = issues;
            this.loading = false;
            this.cdr.markForCheck();
          },
        });
      } else {
        this.verifyMfa();
        switch (this.mfa.methodType) {
          case 'TOTP':
            this.title = this.mfa.enabled
              ? 'Set up Authenticator App MFA'
              : 'Disable Authenticator App MFA';
            this.instructions = this.mfa.enabled
              ? 'Install an authenticator app on your phone and then add the profile by using the QR Code. Once you have added the profile then enter the code that appears in the field below'
              : 'Verify your authenticator app code to disable Authenticator App MFA';
            this.successMessage = this.mfa.enabled
              ? 'Authenticator App MFA has been successfully set up.'
              : 'Authenticator App MFA has been successfully disabled.';
            break;
          case 'EMAIL':
            this.title = this.mfa.enabled
              ? 'Set up Email MFA'
              : 'Disable Email MFA';
            this.instructions = this.mfa.enabled
              ? 'Check your email and enter the code below to enabled email based MFA'
              : 'Check your email and enter the code below to disable email based MFA';
            this.successMessage = this.mfa.enabled
              ? 'Email MFA has been successfully set up.'
              : 'Email MFA has been successfully disabled.';
            break;
          case 'SMS':
            this.title = this.mfa.enabled
              ? `Enable SMS MFA`
              : 'Disable SMS MFA';
            this.instructions = this.mfa.enabled
              ? 'Check your phone and enter the code below to enable SMS based MFA'
              : 'Check your phone and enter the code below to disable SMS based MFA';
            this.successMessage = this.mfa.enabled
              ? 'SMS MFA has been successfully set up.'
              : 'SMS MFA has been successfully disabled.';
            break;
        }
      }
    }
  }

  get mfa(): MultiFactorAuthenticationModel {
    return this._mfa;
  }

  @ViewChild('input') input: ElementRef;
  @Output() verificationSuccess = new EventEmitter();
  @Output() changeMFASuccess = new EventEmitter();
  @Output() changeMFASuccessWithUser = new EventEmitter();
  codeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d{6}$/),
  ]);
  methodControl = new FormControl('');
  methodItems: any[] = [];

  hasChanged: boolean;
  issues: any;
  loading: boolean;
  verifying: boolean;
  title: string;
  instructions: string;
  successMessage: string;
  methodOptions: any[];
  signInRequired = false;
  sharedData: any;
  setting: MFASettingModel;
  private settingSubscription: Subscription;
  constructor(
    private verificationApiService: VerificationApiService,
    private settingService: SettingService,
    private settingApiService: SettingsApiService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.settingSubscription = this.authService
      .checkAuth()
      .pipe(
        takeUntilDestroyed(),
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() =>
          this.settingService.settingByPath$('network.security.mfa')
        )
      )
      .subscribe((setting: any) => {
        if (setting) {
          this.loading = true;
          this.cdr.markForCheck();
          this.settingApiService
            .getSettingDefinition(setting.settingDefinitionId)
            .subscribe({
              next: (resp: ApiResponse<SettingDefinitionModel>) => {
                this.setting = resp.data.setting.data;
                this.loading = false;
                this.cdr.markForCheck();
                this.settingApiService
                  .getSettingDefinitionSharedData(setting.settingDefinitionId)
                  .subscribe({
                    next: (
                      sharedDataResp: ApiResponse<SettingSharedDataModel>
                    ) => {
                      this.sharedData = sharedDataResp.data.data;

                      this.refreshMethodItems();

                      this.cdr.markForCheck();
                    },
                    error: (sharedDataIssues: IssueModel[]) => {
                      this.issues = sharedDataIssues;
                      this.cdr.markForCheck();
                    },
                  });
              },
              error: (issues: IssueModel[]) => {
                this.issues = issues;
                this.loading = false;
                this.cdr.markForCheck();
              },
            });
        }
      });
  }

  ngOnInit() {}

  next() {
    let methodType;
    if (typeof this.methodControl.value === 'string') {
      methodType = this.methodControl.value;
    } else if (
      typeof this.methodControl.value === 'object' &&
      this.methodControl.value !== null
    ) {
      methodType = (this.methodControl.value as any).type;
    }

    this.mfa = {
      credentialId: this.verification?.credentialId,
      methodType: methodType,
      enabled: true,
    } as MultiFactorAuthenticationModel;
    //
  }

  verifyMfa() {
    this.loading = true;
    this.issues = [];
    this.verificationApiService.verifyMfa(this.mfa, this.login).subscribe({
      next: (resp: ApiResponse<VerificationModel>) => {
        this.verification = resp.data;
        this.loading = false;
        this.cdr.markForCheck();
        setTimeout(() => {
          this.input?.nativeElement.focus();
        }, 100);
      },

      error: (issues: IssueModel[]) => {
        this.issues = issues;
        this.loading = false;
        this.cdr.markForCheck();
      },
    });
  }

  setMfa(credentialId: string, methodType: string, enabled: boolean) {
    this.mfa = {
      ...this.mfa,
      credentialId,
      methodType,
      enabled,
    };
  }

  sendMFACode() {
    this.loading = true;
    this.issues = [];
    this.cdr.markForCheck();
    this.verificationApiService.sendMFACode(this.mfa, this.login).subscribe({
      next: (resp: ApiResponse<VerificationModel>) => {
        this.instructions = 'The code has been resent';
        this.verification = resp.data;
        this.loading = false;
        this.codeControl.reset();
        this.cdr.markForCheck();
        setTimeout(() => {
          this.input?.nativeElement.focus();
        }, 100);
      },
      error: (issues: IssueModel[]) => {
        this.issues = issues;
        this.loading = false;
        this.cdr.markForCheck();
      },
    });
  }

  verifyCode() {
    this.verifying = true;
    this.issues = [];
    let apiCall;
    if (this.user) {
      apiCall = this.verificationApiService.verifyWithJWT({
        ...this.verification,
        code: this.codeControl.value,
      });
    } else {
      apiCall = this.verificationApiService.verifyFullResponse({
        ...this.verification,
        code: this.codeControl.value,
      });
    }
    apiCall.subscribe({
      next: (resp: ApiResponse<VerificationModel>) => {
        this.verificationSuccess.emit(resp.data);
        this.mfa.verificationToken = resp.data.token;

        //change MFA
        this.authService.changeMFA(this.mfa, this.login).subscribe({
          next: (resp: ApiResponse<SignInRequiredModel>) => {
            this.signInRequired = resp.data.signInRequired;
            if (resp.data.signInRequired && !this.noLogout) {
              //logout

              this.authService.logout(this.samlLoginParams);
            }
            this.changeMFASuccess.emit(resp.data);

            const userWithMfa = Object.assign({}, this.user, {
              mfaEnabled: this.mfa.enabled,
              mfaType: this.mfa.methodType,
            });

            this.changeMFASuccessWithUser.emit(userWithMfa);
            this.verifying = false;
            this.cdr.markForCheck();
            this.hasChanged = true;
          },
          error: (issues: IssueModel[]) => {
            this.issues = issues;
            this.verifying = false;
            this.cdr.markForCheck();
          },
        });
        this.cdr.markForCheck();
      },
      error: (error: HttpErrorResponse) => {
        this.issues = handleApiErrorSync(error);
        console.log('error', error);

        this.verification = error.error.data;
        this.verifying = false;
        this.cdr.markForCheck();

        this.verifying = false;
        this.cdr.markForCheck();
      },
    });
  }

  refreshMethodItems() {
    this.methodOptions = this.setting.methods
      .filter((method: MFAMethodModel) => method.enabled)
      .map((method: MFAMethodModel, index: number) => {
        const sharedDataMethod = this.sharedData.methodList.find(
          (m: MFAMethodMetaDataModel) => m.type === method.type
        );

        return {
          name: sharedDataMethod?.value,
          description: sharedDataMethod?.description,
          title: sharedDataMethod.value,
          value: method,
          button:
            method.type === this.user.mfaType ? 'Re-configure' : 'Configure',
          status:
            method.type === this.user.mfaType
              ? { label: 'Enabled', color: 'green' }
              : null,
        };
      });
    if (this.user?.mfaEnabled) {
      this.methodOptions.push({
        name: 'None',
        description: 'Disable MFA',
        button: 'Disable',
        value: 'NONE',
      });
    }
    this.cdr.markForCheck();
  }
}
