import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisbursementJobCardComponent } from './disbursement-job-card.component';
import { CardModule } from '@fleet/ui';
import { MatButtonModule } from '@angular/material/button';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [DisbursementJobCardComponent],
  imports: [
    CommonModule,
    CardModule,
    MatButtonModule,

    InternationalizationDatePipeModule,
    TitleCaseAndCleanPipeModule,
    TranslocoDirective,
  ],
  exports: [DisbursementJobCardComponent],
  providers: [
    provideTranslocoScope({
      scope: 'disbursementJobCard',
      loader: lazyTranslationloader,
    }),
  ],
})
export class DisbursementJobCardModule {}
