import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesListCardComponent } from './notes-list-card.component';
import { BadgeModule, CardModule } from '@fleet/ui';
import { NoteSearchResultsModule } from '../note-search-results/note-search-results.module';
import { NoResultsLabelModule } from '@fleet/shared';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { FuseAlertModule } from '@fleet/fuse';
import { LineBreakPipeModule } from '@fleet/pipes';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [NotesListCardComponent],
  imports: [
    CommonModule,
    CardModule,
    NoteSearchResultsModule,
    NoResultsLabelModule,
    BadgeModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    LineBreakPipeModule,
    InternationalizationDatePipeModule,
    TranslocoDirective,
  ],
  exports: [NotesListCardComponent],
  providers: [
    provideTranslocoScope({
      scope: 'notesListCard',
      loader: lazyTranslationloader,
    }),
  ],
})
export class NotesListCardModule {}
