import { Component, Input, OnInit } from '@angular/core';
import { FareModel } from '@fleet/model';

@Component({
  selector: 'fleet-job-card-fare-detail',
  templateUrl: './job-card-fare-detail.component.html',
  styleUrls: ['./job-card-fare-detail.component.scss'],
})
export class JobCardFareDetailComponent implements OnInit {
  @Input() fare: FareModel;

  orderedFareGroupTypes = [
    'Tariff',
    'Extras',
    'Toll',
    'Levy',
    'Fee',
    'Subsidy',
  ];
  constructor() {}

  ngOnInit(): void {}
}
