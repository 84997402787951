import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
  FormGroup,
  FormGroupDirective,
  NgForm,
  FormBuilder,
  NgControl,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { JobState } from '@fleet/model';
import { HotkeysService } from '@fleet/shared';
import { hasRequiredValidator } from '@fleet/utilities';

import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control && control.invalid;
  }
}
@Component({
  selector: 'fleet-job-start-time',
  templateUrl: './job-start-time.component.html',
  styleUrls: ['./job-start-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JobStartTimeComponent),
      multi: true,
    },
  ],
})
export class JobStartTimeComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  private _unsubscribeAll: Subject<any> = new Subject();
  matcher = new MyErrorStateMatcher();
  _jobState: JobState;
  @Input() set jobState(value: JobState) {
    this._jobState = value;
  }

  get jobState() {
    return this._jobState;
  }
  ngControl: NgControl;
  minDate: DateTime;
  startTimeOptions = ['leaveNow', 'today', 'tomorrow', 'pickDate'];
  timeControl = new UntypedFormControl();
  scheduleControl = new UntypedFormControl('leaveNow', [Validators.required]);
  showControl = false;
  isLeaveNow = true;
  userInDifferentTimezone = false;
  firstRunHappened = false;
  calendarValue = 'pickDate';
  _timezone: string;
  @Input() set timezone(value: string) {
    this._timezone = value;
    this.userInDifferentTimezone = DateTime.now().zoneName !== value;
    this.changeDetectorRef.markForCheck();
    this.minDate = DateTime.now().setZone(value);
  }
  get timezone() {
    return this._timezone;
  }
  @Input() label: string;

  calendarControl = new UntypedFormControl(null, { updateOn: 'blur' });
  @ViewChild('timeInput') timeInput: ElementRef;
  @ViewChild('calendarInput') calendarInput: ElementRef;
  @ViewChild('scheduleSelect')
  scheduleSelect: MatSelect;

  @Input() defaultLeaveNow = true;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private hotkeysService: HotkeysService,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.ngControl = this.injector.get(NgControl);
      if (this.ngControl != null) {
        this.ngControl.control.statusChanges.subscribe((change: any) => {
          if (this.ngControl.control.touched) {
            this.timeControl.markAsTouched();
            this.calendarControl.markAsTouched();
            this.timeControl.updateValueAndValidity({
              emitEvent: false,
              onlySelf: true,
            });
            this.calendarControl.updateValueAndValidity({
              onlySelf: true,
              emitEvent: false,
            });
            this.changeDetectorRef.markForCheck();
          }
        });
      }
    });
  }

  ngOnInit(): void {
    // this.ngControl.control.statusChanges.subscribe((status: any) => {
    //   //This component handles whether it is in the past/future. Parent unaware of this.
    //   //Have to apply it here to invalid the ngControl when it is BEFORE TIME
    //   this.ngControl.control.setErrors(this.timeControl.errors, {
    //     emitEvent: false,
    //   });
    //   if (hasRequiredValidator(this.ngControl.control)) {
    //     //chat gpt says to add the required validator manually here if there specifcally...

    //     const currentValidators = this.ngControl.control.validator;
    //     const newValidators = Array.isArray(currentValidators)
    //       ? [...currentValidators, Validators.required]
    //       : [currentValidators, Validators.required];

    //     this.timeControl.setValidators(newValidators);
    //   }
    //   if (this.ngControl.touched) {
    //     this.timeControl.markAsTouched();
    //     this.calendarControl.markAllAsTouched();
    //   }
    //   this.changeDetectorRef.markForCheck();
    //   this.changeDetectorRef.detectChanges();
    // });

    this.registerHotKeys();

    this.timeControl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (value) => {
          const dateTimeIso = DateTime.fromISO(value, {
            setZone: true,
            zone: this.timezone,
          });
          if (dateTimeIso.isValid) {
            //this.timeInput.error = false;
            console.log('valid time');
            console.log('Setting local time from datetimepciker:' + value);

            if (this.scheduleControl.value === 'today') {
              this.calendarValue = 'pickDate';
              const datetime = DateTime.now()
                .setZone(this.timezone)
                .startOf('day')
                .plus({ hours: dateTimeIso.hour, minutes: dateTimeIso.minute });
              if (datetime < DateTime.now().setZone(this.timezone)) {
                //ALEX SET THE FORMCONTROL ERROR HERE AND DONT ONCHANGE THE TIME
                // this.timeInput.error = true;
                this.timeControl.setErrors({ BEFORE_TIME: 'true' });

                console.log('INVALID: Time is BEFORE should be RED');
                this.onChange(datetime);
                this.onTouched();
              } else {
                this.timeControl.clearValidators();
                this.timeControl.updateValueAndValidity({
                  emitEvent: false,
                });
                console.log('VALID: Sholuld be good');

                this.onChange(datetime);
                this.onTouched();
              }
            } else if (this.scheduleControl.value === 'tomorrow') {
              this.calendarValue = 'pickDate';
              const datetime = DateTime.now()
                .setZone(this.timezone)
                .plus({ day: 1 })
                .startOf('day')
                .plus({ hours: dateTimeIso.hour, minutes: dateTimeIso.minute });
              this.onChange(datetime);
              this.onTouched();
            } else {
              //It is possible that they have input the time in before even selecting the date, so the calendar control will be null
              //Use DateTime.now() in this instance to set the zone.

              if (this.calendarControl.value) {
                let datetime = this.calendarControl.value;

                datetime = datetime.setZone(this.timezone, {
                  keepLocalTime: true,
                });
                datetime = datetime.set({
                  hour: dateTimeIso.hour,
                  minute: dateTimeIso.minute,
                });
                this.onChange(datetime);
                this.onTouched();
              }
            }
          } else {
            console.log('invalid time');
          }
          // if (this.firstRunHappened) {
        },
      });

    this.scheduleControl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (value) => {
          //this.calendarValue = value;
          this.calendarControl.updateValueAndValidity();

          if (value !== 'leaveNow') {
            this.isLeaveNow = false;
            this.showControl = true;
            this.timeControl.setValidators([Validators.required]);
            this.timeControl.updateValueAndValidity({ emitEvent: false });

            if (this.timeControl.value) {
              let dateTimeIso = DateTime.fromISO(this.timeControl.value, {
                setZone: true,
                zone: this.timezone,
              });

              if (value === 'today') {
                //ALEX TEST DATETIME LIKE ABOVE ONLY ONCHANGE IF VALID ELSE set errors on startTImeCOntrl

                this.calendarValue = 'pickDate';

                this.onChange(dateTimeIso);
                this.onTouched();
              }
              if (value === 'tomorrow') {
                this.calendarValue = 'pickDate';
                dateTimeIso = dateTimeIso.plus({ day: 1 });
                this.onChange(dateTimeIso);
                this.onTouched();
              }
            }

            if (value === 'pickDate') {
              setTimeout(() => {
                this.calendarControl.setValidators([Validators.required]);
                this.calendarControl.updateValueAndValidity({
                  emitEvent: false,
                });
                this.calendarInput.nativeElement.focus();
              }, 1);
            } else {
              this.calendarControl.setValue(null, { emitEvent: false });

              this.calendarControl.clearValidators();
              this.calendarControl.updateValueAndValidity({
                emitEvent: false,
              });
            }
          } else {
            this.isLeaveNow = true;
            this.timeControl.clearValidators();
            this.timeControl.updateValueAndValidity({
              emitEvent: false,
            });
            this.calendarControl.setValue(null, { emitEvent: false });
            this.showControl = false;
            let dateTime = DateTime.now();
            if (this.timezone) {
              dateTime = DateTime.now().setZone(this.timezone);
            }

            this.onChange(dateTime);
            this.onTouched();
          }
        },
      });

    this.calendarControl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        if (value && value.isValid) {
          if (this.timeControl.value) {
            let dateTimeIso = DateTime.fromISO(this.timeControl.value, {
              setZone: true,
              zone: this.timezone,
            });

            //ALEX TEST AGAN IF BEFORE NOW
            dateTimeIso = dateTimeIso.set({
              year: value.year,
              month: value.month,
              day: value.day,
            });
            this.onChange(dateTimeIso);
            this.onTouched();
          }

          this.changeDetectorRef.markForCheck();
        } else {
          this.onChange(null);
          this.onTouched();
        }
      });
  }

  registerHotKeys() {
    this.hotkeysService
      .addShortcut({ keys: 'F6' })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: () => {
          console.log('F6 Clicked');
          this.scheduleSelect.focus();
          this.scheduleSelect.open();
        },
      });
  }

  writeValue(startTime: DateTime): void {
    if (startTime) {
      this.timezone = startTime.zoneName;

      if (
        startTime <=
        DateTime.now().plus({ minutes: 5 }).setZone(startTime.zone, {
          keepLocalTime: true,
        })
      ) {
        this.scheduleControl.setValue('leaveNow', { emitEvent: false });
        this.showControl = false;
        this.isLeaveNow = true;
      } else {
        const today = DateTime.now().setZone(startTime.zone, {
          keepLocalTime: true,
        });
        const formattedStartTime = startTime.toFormat('dd/MM/yyyy');
        if (today.toFormat('dd/MM/yyyy') === formattedStartTime) {
          this.scheduleControl.setValue('today', { emitEvent: false });
        } else if (
          today.plus({ days: 1 }).toFormat('dd/MM/yyyy') === formattedStartTime
        ) {
          this.scheduleControl.setValue('tomorrow', { emitEvent: false });
        } else {
          //its a date after tomorrow
          this.scheduleControl.setValue('pickDate', { emitEvent: false });
          this.calendarControl.setValue(startTime);
        }
        this.showControl = true;
        this.isLeaveNow = false;
        this.timeControl.setValue(startTime.toFormat('HH:mm'), {
          emitEvent: false,
        });
        this.changeDetectorRef.markForCheck();
      }
    } else {
      if (this.defaultLeaveNow) {
        this.scheduleControl.setValue('leaveNow', { emitEvent: false });
        this.isLeaveNow = true;
      } else {
        this.scheduleControl.setValue(null, { emitEvent: false });
      }
      this.timeControl.setValue(null, { emitEvent: false });
      this.timeControl.markAsPristine();
      this.showControl = false;
    }
  }
  onChange: any = () => {};
  onTouched: any = () => {
    console.log('start time touched');
    this.timeControl.markAsTouched();
  };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.timeControl.disable({ emitEvent: false });
      this.scheduleControl.disable({ emitEvent: false });
    } else {
      this.timeControl.enable({ emitEvent: false });
      this.scheduleControl.enable({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
