import { Pipe, PipeTransform } from '@angular/core';
import { FareElementModel, FareModel } from '@fleet/model';

@Pipe({
  name: 'totalFromFareGroups',
})
export class TotalFromFareGroupsPipe implements PipeTransform {
  transform(fares: FareModel[]): number {
    let total = 0;
    Object.keys(fares)
      .map((key: any) => fares[key])
      .forEach((fare: any) => {
        fare.forEach((fareElement: FareElementModel) => {
          total += Number(fareElement.amount);
        });
      });
    return total;
  }
}
