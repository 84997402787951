import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { DisbursementSearchFormComponent } from './disbursement-search-form/disbursement-search-form.component';

import { MatButtonModule } from '@angular/material/button';

import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatInputModule } from '@angular/material/input';

import { MatTableModule } from '@angular/material/table';

import { LuxonDateModule } from '@angular/material-luxon-adapter';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { DriverDisbursementDetailModule } from '@fleet/driver-shared';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import {
  EntitySearchLayoutModule,
  ResponsiveElementModule,
} from '@fleet/layout';
import { NetworkGroupSelectorModule } from '@fleet/network-shared';
import {
  RemovePropertiesPipeModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { SearchChipsListModule } from '@fleet/shared';
import { LayoutFormSectionModule, SearchSummaryModule } from '@fleet/ui';
import { DisbursementRejectModule } from '../disbursement-reject';
import { DisbursementSearchContainerComponent } from './disbursement-search-container/disbursement-search-container.component';
import { DisbursementSearchResultsModule } from './disbursement-search-results/disbursement-search-results.module';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    return acc;
  },
  {}
);

@NgModule({
  declarations: [
    DisbursementSearchFormComponent,

    DisbursementSearchContainerComponent,
  ],
  imports: [
    DisbursementSearchResultsModule,
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    ResponsiveElementModule,
    SearchChipsListModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    RemovePropertiesPipeModule,
    SearchSummaryModule,
    LayoutFormSectionModule,
    TitleCaseAndCleanPipeModule,
    EntitySearchLayoutModule,
    TranslocoDirective,
    DisbursementRejectModule,
    RouterModule.forChild([
      { path: '', component: DisbursementSearchContainerComponent },
    ]),
    LuxonDateModule,
    NetworkGroupSelectorModule,
    DriverDisbursementDetailModule,
    HasFunctionPipeModule,
  ],
  providers: [
    provideTranslocoScope({
      scope: 'disbursementSearch',
      loader: lazyTranslationloader,
    }),
  ],
  exports: [DisbursementSearchFormComponent],
})
export class DisbursementSearchModule {}
