import {
  AreaModel,
  HexagonMetadataModel,
  JobDetailModel,
  JobModel,
  JobProgressModel,
  JobStatus,
  JobStatusUpdateAndProgress,
  JobTransitionModel,
  LocationModel,
  PositionAggregateModel,
  PositionDetailModel,
  PositionModel,
  VehicleAreaRankingModel,
} from '@fleet/model';
import {
  alphabetLetterAtIndex,
  createActiveJobDisplay,
} from '@fleet/utilities';
import * as h3 from 'h3-js';

// import { Theme } from '../theme';
import {
  activeJobMarkerUrl,
  activeJobMarkerUrlV2,
  circleMarkerUrl,
  dynamicCircleMarkerUrl,
  vehicleMarkerUrl,
  vehicleSymbol,
} from './components/markers/marker-svg/marker-svg';
import { decode } from './heremaps-flexible-polyline';
import { BrandSettingModel } from '@fleet/model';
import {
  createCustomImageMarker,
  createCustomPinMarker,
} from './map-marker-utilities';

const HIGH_CONTRAST_HEX_FILL_COLOR = '#04BF9E';
const HIGH_CONTRAST_HEX_STROKE_COLOR = '#BBFA35';
const HIGH_CONTRAST_AREA_FILL_COLOR = '#35A7FA';
const HIGH_CONTRAST_AREA_STROKE_COLOR = '#AB1EFF';

export function drawMapFromAreas(
  areas: AreaModel[],
  selectedPolygons: google.maps.PolylineOptions[]
): google.maps.PolylineOptions[] {
  let areaPolygons = [] as any;

  if (areas) {
    areas.forEach((element) => {
      if (element && element.multiPolygon) {
        let path = element.multiPolygon.polygons[0].rings[0].points.map(
          (point: string[]) => new google.maps.LatLng(+point[0], +point[1])
        ) as [];
        areaPolygons.push({
          path: path,
          clickable: true,
          area: element,
        });
      }
    });
    return areaPolygons;
  }
  return areaPolygons;
}

export function getPolyOptionsFromArea(area: AreaModel, selected?: boolean) {
  return {
    path: area.multiPolygon.polygons[0].rings[0].points.map(
      (point: string[]) => new google.maps.LatLng(+point[0], +point[1])
    ),
    fillColor: 'blue',
    fillOpacity: '0.1',
    strokeWeight: 0,
    selected: selected ? selected : false,
    area: area,
  } as google.maps.PolylineOptions;
}

export function simpleLetterIconMarker(
  letter: string,
  location: LocationModel,
  isActive?: boolean,
  draggable?: boolean
) {
  const markerOptions = <google.maps.MarkerOptions>{
    position: {
      lat: +location.latitude,
      lng: +location.longitude,
    },
    draggable: draggable ? draggable : false,
    label: letter,
  };
  return markerOptions;
}

// export function generateMarkersFromJobDetail(
//   jobDetail: JobDetailModel,
//   brandConfig?: BrandSettingModel
// ) {
//   const markers: google.maps.MarkerOptions[] = [];
//   if (jobDetail.startLocation) {
//     markers.push(
//       simpleLetterIconMarker(alphabetLetterAtIndex(0), jobDetail.startLocation)
//     );
//   }

//   let waypointIndex = 1;
//   if (jobDetail.waypoints) {
//     jobDetail.waypoints.forEach((waypoint: LocationModel) => {
//       if (waypoint?.latitude) {
//         markers.push(
//           simpleLetterIconMarker(alphabetLetterAtIndex(waypointIndex), waypoint)
//         );
//         waypointIndex++;
//       }
//     });
//   }
//   if (jobDetail.endLocation && jobDetail.endLocation.latitude) {
//     markers.push(
//       simpleLetterIconMarker(
//         alphabetLetterAtIndex(waypointIndex),
//         jobDetail.endLocation
//       )
//     );
//   }
//   //return markers;
//   return generateCustomMarkersFromJobDetail(jobDetail, brandConfig);
// }

export function generateMarkersFromJobDetail(
  jobDetail: JobDetailModel,
  brandConfig: BrandSettingModel
) {
  const markers: google.maps.marker.AdvancedMarkerElementOptions[] = [];
  const standardMarkerColor = brandConfig.standardMarkerColor
    ? brandConfig.standardMarkerColor
    : brandConfig.theme.primary;
  if (jobDetail.startLocation) {
    markers.push(
      brandConfig.locationMarkerSet?.startLocation
        ? createCustomImageMarker(
            brandConfig.locationMarkerSet?.startLocation,
            jobDetail.startLocation
          )
        : createCustomPinMarker(
            standardMarkerColor,
            alphabetLetterAtIndex(0),
            jobDetail.startLocation
          )
    );
  }

  let waypointIndex = 1;
  if (jobDetail.waypoints) {
    for (const waypoint of jobDetail.waypoints) {
      if (waypoint?.latitude) {
        markers.push(
          brandConfig.locationMarkerSet?.waypoints
            ? createCustomImageMarker(
                brandConfig.locationMarkerSet.waypoints,
                waypoint
              )
            : createCustomPinMarker(
                standardMarkerColor,
                alphabetLetterAtIndex(waypointIndex),
                waypoint
              )
        );
        waypointIndex++;
      }
    }
  }
  if (jobDetail.endLocation && jobDetail.endLocation.latitude) {
    markers.push(
      brandConfig.locationMarkerSet?.endLocation
        ? createCustomImageMarker(
            brandConfig.locationMarkerSet?.endLocation,
            jobDetail.endLocation
          )
        : createCustomPinMarker(
            standardMarkerColor,
            alphabetLetterAtIndex(waypointIndex),
            jobDetail.endLocation
          )
    );
  }
  return markers;
}

export function generateMarkerFromActiveJobV2(
  activeJob: JobStatusUpdateAndProgress,
  disabled?: boolean
) {
  const sla = createActiveJobDisplay(activeJob);
  const marker = new google.maps.Marker({
    position: {
      lat: +activeJob.latestStatusUpdate.startLatitude,
      lng: +activeJob.latestStatusUpdate.startLongitude,
    },
    icon: {
      url: activeJobMarkerUrlV2(sla, disabled ? true : false),
      scaledSize: new google.maps.Size(30, 30),
      anchor: new google.maps.Point(15, 15),
    },
    // label: '5:00pm',
    // opacity: disable ? 1 : 0.2,

    optimized: true,
  } as google.maps.MarkerOptions);
  marker.set('activeJob', activeJob);
  marker.set('slaDisplay', sla);

  return marker;
}

export function generateVehicleMarkerFromPositionDetail(
  positionDetail: PositionDetailModel,
  isCurrentVehicle?: boolean,
  brandConfig?: BrandSettingModel
) {
  let fillColor = '#ff0000';
  let status = positionDetail.availability;
  switch (positionDetail.availability) {
    case 'AVAILABLE':
      fillColor = '#00FF00';
      break;
    case 'OFFLINE':
      fillColor = '#808080';
      break;
    default:
      fillColor = '#ff0000';
  }
  if (isCurrentVehicle) {
    status = 'ASSIGNED';
  }

  const marker = new google.maps.Marker({
    position: {
      lat: +positionDetail.position.latitude,
      lng: +positionDetail.position.longitude,
    },
    icon: {
      url: vehicleMarkerUrl(status, brandConfig),
      anchor: new google.maps.Point(15, 15),
      scaledSize: new google.maps.Size(30, 30), // Control the size of the marker
      //rotation: positionDetail.position.heading,
    },
    optimized: true,
  } as google.maps.MarkerOptions);
  marker.set('vehicleId', positionDetail.vehicleId);
  marker.set('driverId', positionDetail.driverId);
  marker.set('availability', positionDetail.availability);
  marker.set('positionDetail', positionDetail);
  return marker;
}

export function vehicleMarkerFromJobProgress(
  jobProgress: JobProgressModel,
  brandConfig: any
) {
  const marker = new google.maps.Marker({
    position: {
      lat: +jobProgress.position.latitude,
      lng: +jobProgress.position.longitude,
    },
    icon: {
      url: vehicleMarkerUrl('ASSIGNED', brandConfig),
      anchor: new google.maps.Point(15, 15),
      scaledSize: new google.maps.Size(30, 30),
    },
    optimized: true,
    zIndex: 9999,
  } as google.maps.MarkerOptions);
  return marker;
}

export function generateDriverMarkerFromPosition(
  position: PositionModel,
  color: string
) {
  return <google.maps.MarkerOptions>{
    position: {
      lat: +position.latitude,
      lng: +position.longitude,
    },
    icon: { ...vehicleSymbol, rotation: position.heading, fillColor: color },
  };
}

export function decodePathFromEncoded(encoded: string) {
  const decodedPath = google.maps.geometry.encoding.decodePath(encoded);
  return decodedPath;
}

export function decodeV2Route(encoded: string) {
  return decode(encoded);
}

export function pathFromHexGeo(hexGeo: any[]) {
  return hexGeo.map((latlng: any[]) => {
    return new google.maps.LatLng(+latlng[1], +latlng[0]);
  });
}

export function pathFromHexId(hexId: string) {
  return h3.h3ToGeoBoundary(hexId, true).map((latlng: any[]) => {
    return new google.maps.LatLng(+latlng[1], +latlng[0]);
  });
}

export const polygonFromArea = (
  area: AreaModel,
  highContrast?: boolean
): any => {
  const fillHex = !highContrast
    ? getCSSVariableValue('--fuse-accent-500')
    : HIGH_CONTRAST_AREA_FILL_COLOR;
  const areaPolygons: google.maps.PolygonOptions = {
    paths: [],
    fillColor: fillHex,
    fillOpacity: !highContrast ? 0.3 : 0.5,
    strokeWeight: 0,
  };

  const path =
    area && area?.multiPolygon?.polygons?.length > 0
      ? area.multiPolygon.polygons[0].rings[0].points.map(
          (point: string[]) => new google.maps.LatLng(+point[0], +point[1])
        )
      : [];
  areaPolygons.paths = path;
  return areaPolygons;
};

export const polygonAreaColor = (
  selected: boolean,
  area: AreaModel
): google.maps.PolygonOptions => {
  const fillHex = selected
    ? getCSSVariableValue('--fuse-accent-500')
    : getCSSVariableValue('--fuse-primary-500');
  const areaPolygons: google.maps.PolygonOptions = {
    paths: [],
    fillOpacity: selected ? 0.3 : 0.1,
    fillColor: fillHex,
    strokeWeight: selected ? 0 : 1,
    strokeOpacity: selected ? 1 : 0.5,
    strokeColor: fillHex,
  };

  const path =
    area?.multiPolygon?.polygons?.length > 0
      ? area.multiPolygon.polygons[0].rings[0].points.map(
          (point: string[]) => new google.maps.LatLng(+point[0], +point[1])
        )
      : [];
  areaPolygons.paths = path;
  return areaPolygons;
};

export const polygonsFromPositionListenerAggregations = (
  aggregations: PositionAggregateModel[]
) => {
  //get hex

  const strokeHex = getCSSVariableValue('--fuse-primary-100');
  const fillHex = getCSSVariableValue('--fuse-primary-500');
  // Calculate the max count to normalize the fillOpacity
  const maxCount = Math.max(...aggregations.map((agg) => agg.count));

  return aggregations.map((aggregate: PositionAggregateModel) => {
    // Normalize the count to a value between 0 and 1 to adjust fillOpacity
    const normalizedCount = aggregate.count / maxCount;
    // Adjust fillOpacity based on normalizedCount, e.g., between 0.1 and 0.9
    const fillOpacity = 0.3 + normalizedCount * 0.8;
    // fillColor: '#04BF9E',
    // strokeColor: '#BBFA35',
    return {
      fillOpacity: fillOpacity,
      paths: pathFromHexId(aggregate.geohash),
      clickable: false,
      strokeColor: strokeHex ? strokeHex : '#FFB84D', // Changed to a slightly more yellow orange
      strokeWeight: 0.2,
      fillColor: fillHex ? lightenHexColor(fillHex, 20) : '#FFB84D', // Changed to a slightly more yellow orange
    };
  });
};

export function areaPolyLinePath(area: AreaModel) {
  if (area?.multiPolygon?.polygons?.[0]?.rings?.[0]?.points) {
    return area.multiPolygon.polygons[0].rings[0].points.map(
      (point: string[]) => new google.maps.LatLng(+point[0], +point[1])
    );
  } else {
    return [];
  }
}

export function boundsFromArea(area: AreaModel) {
  const bounds = new google.maps.LatLngBounds();
  const paths = area.multiPolygon.polygons[0].rings[0].points.map(
    (point: string[]) => new google.maps.LatLng(+point[0], +point[1])
  );

  paths.forEach((path: any) => {
    bounds.extend(path);
  });
  return bounds;
}

export function boundsFromAreas(areas: AreaModel[]) {
  const bounds = new google.maps.LatLngBounds();
  areas.forEach((area: AreaModel) => {
    const paths = area.multiPolygon.polygons[0].rings[0].points.map(
      (point: string[]) => new google.maps.LatLng(+point[0], +point[1])
    );

    paths.forEach((path: any) => {
      bounds.extend(path);
    });
  });

  return bounds;
}

export function mapFromJob(
  job: JobModel,
  brandConfig: BrandSettingModel,
  setBounds: boolean,
  setBoundsTo?: string,
  withJobTransition?: JobTransitionModel,
  showRouteTimelineMarkersInsteadOfPolylines?: boolean
): any {
  const bounds = new google.maps.LatLngBounds();
  let boundsExtended = false;
  let forceBounds = false;
  const markers = generateMarkersFromJobDetail(job.jobDetail, brandConfig);
  const routePolyLines = showRouteTimelineMarkersInsteadOfPolylines
    ? []
    : polyLinesFromJobDetail(job.jobDetail, brandConfig);
  const timelineMarkers = showRouteTimelineMarkersInsteadOfPolylines
    ? routeTimestampMarkersFromJobDetail(job.jobDetail, brandConfig)
    : [];
  let vehicleMarker;
  const map: any = {
    markers: markers,
    routePolyLines: routePolyLines,
  };

  if (job.jobProgress) {
    switch (job.jobStatus) {
      case JobStatus.STARTED:
      case JobStatus.IN_PROGRESS:
      case JobStatus.ASSIGNED:
      case JobStatus.CANCELLED:
        vehicleMarker = vehicleMarkerFromJobProgress(
          job.jobProgress,
          brandConfig
        );
        break;
    }
  }
  if (withJobTransition) {
    if (withJobTransition.position) {
      vehicleMarker = vehicleMarkerFromJobProgress(
        {
          position: withJobTransition.position,
        } as any,
        brandConfig
      );
    } else {
      vehicleMarker = null;
    }
  }

  if (setBounds) {
    if (setBoundsTo === 'start-location') {
      //just bounds
      if (markers && markers.length > 0) {
        bounds.extend(markers[0].position);
        boundsExtended = true;
      }
    } else {
      markers.forEach((marker: any) => {
        boundsExtended = true;
        bounds.extend(marker.position);
      });
      routePolyLines.forEach((polylineOption: google.maps.PolylineOptions) => {
        polylineOption.path.forEach((path: any) => {
          boundsExtended = true;
          bounds.extend(path);
        });
      });
      timelineMarkers.forEach((marker: any) => {
        boundsExtended = true;
        bounds.extend(marker.position);
      });
      if (vehicleMarker) {
        boundsExtended = true;
        bounds.extend(vehicleMarker.getPosition());
      }
    }
  } else if (setBoundsTo === 'only-if-outside') {
    markers.forEach((marker: any) => {
      boundsExtended = true;
      bounds.extend(marker.position);
    });
    routePolyLines.forEach((polylineOption: google.maps.PolylineOptions) => {
      polylineOption.path.forEach((path: any) => {
        boundsExtended = true;
        bounds.extend(path);
      });
    });
    timelineMarkers.forEach((marker: any) => {
      boundsExtended = true;
      bounds.extend(marker.position);
    });
    if (vehicleMarker) {
      if (!bounds.contains(vehicleMarker.getPosition())) {
        boundsExtended = true;
        bounds.extend(vehicleMarker.getPosition());
        setBounds = true;
        forceBounds = true;
      }
    }
  }

  return {
    markers: markers,
    routePolyLines: routePolyLines,
    vehicleMarker: vehicleMarker,
    bounds: setBounds ? bounds : null,
    forceBounds: forceBounds,
    boundsExtended: boundsExtended,
    routeTimelineMarkers: timelineMarkers,
  };
}

export function activeLocationIndex(
  activeLocation: LocationModel,
  jobDetail: JobDetailModel
) {
  let locations: LocationModel[] = [];
  if (jobDetail.startLocation) {
    locations.push(jobDetail.startLocation);
  }
  if (jobDetail.waypoints && jobDetail.waypoints.length > 0) {
    locations = [...locations, ...jobDetail.waypoints];
  }
  if (jobDetail.endLocation) {
    locations.push(jobDetail.endLocation);
  }
  return locations.findIndex((s) => s.locationId === activeLocation.locationId);
}

export const mainRouteColor = '#0E53FF';
export const mainRouteBorderColor = '#1128f5';
export const startedRouteColor = '#ffa000';
export const proposedRouteColor = '#bccefb';
export const proposedRouteBorderColor = '#7188da';

export function polyLinesFromJobDetail(
  jobDetail: JobDetailModel,
  brandConfig: any
) {
  const polyLines = [];
  if (jobDetail.route) {
    polyLines.push({
      // The planned route
      path: decodePolyline(jobDetail.routeEncodingVersion, jobDetail.route),
      zIndex: 2,
      strokeColor: jobDetail.inProgressRoute
        ? brandConfig.routeColor.inProgressRoute
        : brandConfig.routeColor.route,
      // strokeColor: jobDetail.inProgressRoute ? '#5A9AD2' : '#2985D7', //lor to red
      strokeWeight: 4,
      strokeOpacity: 1,
    } as google.maps.PolygonOptions);

    polyLines.push({
      // The planned route
      path: decodePolyline(jobDetail.routeEncodingVersion, jobDetail.route),
      zIndex: 1,

      // strokeColor: '#184F7F', // UpUpdated color to red
      strokeColor: jobDetail.inProgressRoute
        ? brandConfig.routeColor.inProgressRouteBorder
        : brandConfig.routeColor.routeBorder,
      strokeWeight: 8,
      strokeOpacity: 1,
    } as google.maps.PolygonOptions);
  }

  if (jobDetail.startedRoute) {
    polyLines.push({
      path: decodePolyline(
        jobDetail.routeEncodingVersion,
        jobDetail.startedRoute
      ),
      zIndex: 3,
      // strokeColor: '#F5A72C',
      strokeColor: brandConfig.routeColor.startedRoute,
      strokeWeight: 4,
      strokeOpacity: 1,
    } as google.maps.PolygonOptions);
  }

  if (jobDetail.inProgressRoute) {
    polyLines.push({
      // The planned route
      path: decodePolyline(
        jobDetail.routeEncodingVersion,
        jobDetail.inProgressRoute
      ),
      zIndex: 2,
      // strokeColor: '#2985D7', //lor to red
      strokeColor: brandConfig.routeColor.route,
      strokeWeight: 3,
      strokeOpacity: 1,
    } as google.maps.PolygonOptions);
    polyLines.push({
      // The planned route
      path: decodePolyline(
        jobDetail.routeEncodingVersion,
        jobDetail.inProgressRoute
      ),
      zIndex: 1,
      strokeColor: brandConfig.routeColor.route,
      // strokeColor: '#184F7F', // UpUpdated color to red
      strokeWeight: 6,
      strokeOpacity: 1,
    } as google.maps.PolygonOptions);
    // polyLines.push({
    //   path: decodePolyline(
    //     jobDetail.routeEncodingVersion,
    //     jobDetail.inProgressRoute
    //   ),
    //   zIndex: 2,
    //   strokeColor: '#10902d',
    //   strokeWeight: 4,
    //   strokeOpacity: 0.7,

    //   strokeColor: '#4E85B5',
    //   strokeWeight: 4,
    //   strokeOpacity: 1,
    // } as google.maps.PolygonOptions);
  }
  return polyLines;
}

export const routeTimestampMarkersFromJobDetail = (
  jobDetail: JobDetailModel,
  brandConfig: BrandSettingModel
) => {
  const markers = [];
  if (jobDetail.startedRoute) {
    markers.push(
      ...timelineMarkersFromEncodedRoute(
        jobDetail.startedRoute,
        brandConfig.routeColor.startedRoute
      )
    );
  }
  if (jobDetail.inProgressRoute) {
    markers.push(
      ...timelineMarkersFromEncodedRoute(
        jobDetail.inProgressRoute,
        brandConfig.routeColor.route
      )
    );
  }
  return markers;
};

const timelineMarkersFromEncodedRoute = (
  encodedRoute: string,
  fill: string
) => {
  const decoded = decode(encodedRoute).polyline;
  return decoded.map((path: any) => {
    const position = {
      lat: +path[0],
      lng: +path[1],
    };

    const marker = new google.maps.Marker({
      position: position,
      clickable: true,
      draggable: false,
      optimized: true,
      icon: dynamicCircleMarkerUrl(fill, 4),
    } as google.maps.MarkerOptions);
    if (path.length > 2) {
      //has time stamp
      marker.set('timestamp', path[2]);
    }
    marker.set(
      'positionDisplay',
      `${parseFloat(path[0]).toFixed(4)}, ${parseFloat(path[1]).toFixed(4)}`
    );
    return marker;
  });
};

export function isBoundsContainedByAnother(
  boundsA: google.maps.LatLngBounds,
  boundsB: google.maps.LatLngBounds
) {
  // Check if bounds1 contains bounds2
  if (
    boundsA.contains(boundsB.getSouthWest()) &&
    boundsA.contains(boundsB.getNorthEast())
  ) {
    return true;
  } else {
    return false;
  }
}

export function decodePolyline(encodingVersion: any, encodedPolyline: string) {
  if (!encodingVersion || encodingVersion === 1) {
    return decodePathFromEncoded(encodedPolyline);
  } else {
    const v2Decoded = decodeV2Route(encodedPolyline);
    return v2Decoded.polyline.map((poly) => {
      return new google.maps.LatLng(poly[0], poly[1]);
    });
  }
}

// This is adapted from the implementation in Project-OSRM
// <https://github.com/DennisOSRM/Project-OSRM-Web/blob/master/WebContent/routing/OSRM.RoutingGeometry.js>
export const decodeOSRM = (str: string, precision?: number): number[][] => {
  let index = 0,
    lat = 0,
    lng = 0,
    coordinates: number[][] = [],
    shift = 0,
    result = 0,
    byte: number | null = null,
    latitude_change: number,
    longitude_change: number,
    factor = Math.pow(10, precision || 6);

  while (index < str.length) {
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitude_change;
    lng += longitude_change;

    coordinates.push([lat / factor, lng / factor]);
  }

  return coordinates;
};

export const v1MockEncoded = {
  routeEncodingVersion: 'v1',
  route:
    'r}bnEypuy[wGKA_Fh@uCIm@q@YcAoB^cIUoD_AgBgFmCvFyMbKS~IpHzDnJhDxRlJhDhChQeJnBcBmJ]g@_Ov@cEO',
};

export const v2MockEncoded = {
  routeEncodingVersion: 'v2',
  route:
    'BlDz-jvG6x26cm01ol38hD4IMw4JCgHw4JpB2Ew4JKuBw4JyBaw4JkCwDAfkKgxTWwFw4JgCoDw4JoHuEw4J3H6Ow4JjMUw4J_KxJw4J7FvLw4JpF5Tw4JtLpFw4JpEpSw4JmLvDw4JkDuLw4JeoBw4JgQ3Bw4JkGQw4J',
};

const lightenHexColor = (hex: string, percent: number): string => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Increase each channel by the given percentage
  r = Math.min(255, Math.floor(r + ((255 - r) * percent) / 100));
  g = Math.min(255, Math.floor(g + ((255 - g) * percent) / 100));
  b = Math.min(255, Math.floor(b + ((255 - b) * percent) / 100));

  // Convert back to hex and pad with zeroes if necessary
  const newHex = `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

  return newHex;
};

function getCSSVariableValue(
  variableName: string,
  element: HTMLElement = document.body
): string {
  // Get the computed style of the element
  const computedStyle = getComputedStyle(element);
  // Get the value of the CSS variable
  const value = computedStyle.getPropertyValue(variableName).trim();
  return value;
}

export function getRankingPolygonsFromVehicleArea(
  vehicleArea: VehicleAreaRankingModel,
  highContrast?: boolean
) {
  const strokeHex = !highContrast
    ? getCSSVariableValue('--fuse-primary-100')
    : HIGH_CONTRAST_HEX_STROKE_COLOR; // Attempt to fetch a CSS variable for stroke color
  const fillHex = !highContrast
    ? lightenHexColor(getCSSVariableValue('--fuse-primary-500'), 20)
    : HIGH_CONTRAST_HEX_FILL_COLOR; // Attempt to fetch a CSS variable for fill color

  const rankingPolygons =
    vehicleArea && vehicleArea.hexagonMetadata
      ? vehicleArea.hexagonMetadata.map((hex: HexagonMetadataModel) => {
          let fillOpacity = 0.5; // Default opacity
          if (hex.level === 1) {
            fillOpacity = 0.7;
          } else if (hex.level === 2) {
            fillOpacity = 0.4;
          } else if (hex.level === 3) {
            fillOpacity = 0.3;
          }
          return {
            // ...polygonsFromHexMetadataArray([hex])[0], // Assuming polygonsFromHexMetadataArray returns an array of polygons
            fillOpacity: fillOpacity,
            // The color represented by the hex code '#04BF9E' is roughly a shade of teal.
            fillColor: fillHex,
            strokeWeight: 1,
            clickable: false,
            // The color represented by the hex code '#BBFA35' is roughly a bright lime green.
            strokeColor: strokeHex,
            paths: pathFromHexId(hex.hexagonId),
          };
        })
      : [];

  return rankingPolygons;
}

export function getAreaPolylineOptionsOnVehicleArea(vehicleArea: AreaModel) {
  return {
    paths: areaPolyLinePath(vehicleArea),
    fillColor: '#35A7FA',
    fillOpacity: 0.5,
    strokeWeight: 0,
  } as google.maps.PolygonOptions;
}

export function getAreaPolygonsFromArea(area: AreaModel) {
  return {
    path: area.multiPolygon.polygons[0].rings[0].points.map(
      (point: string[]) => new google.maps.LatLng(+point[0], +point[1])
    ),
    fillColor: 'blue',
    fillOpacity: '0.1',
    strokeWeight: 0,
  } as google.maps.PolylineOptions;
}

export function jobsToH3HeatMap(
  jobs: JobStatusUpdateAndProgress[],
  resolution: number
): any[] {
  // Convert job locations to H3 indexes with the specified resolution
  const h3Indexes = jobs.map((job) => {
    return h3.geoToH3(
      parseFloat(
        job.latestJobProgress
          ? job.latestJobProgress.position.latitude
          : job.latestStatusUpdate.startLatitude
      ),
      parseFloat(
        job.latestJobProgress
          ? job.latestJobProgress.position.longitude
          : job.latestStatusUpdate.startLongitude
      ),
      resolution // Use the provided resolution
    );
  });

  // Group jobs by their H3 index
  const h3IndexToJobsMap: { [key: string]: JobStatusUpdateAndProgress[] } =
    h3Indexes.reduce((acc: any, h3Index: any, index: any) => {
      if (!acc[h3Index]) {
        acc[h3Index] = [];
      }
      acc[h3Index].push(jobs[index]);
      return acc;
    }, {});

  // Generate polygons for each H3 index
  const polygons = Object.entries(h3IndexToJobsMap).map(
    ([h3Index, jobsInH3]) => {
      const center = h3.h3ToGeo(h3Index); // Get the center of the hexagon
      const jobCount = jobsInH3.length;
      const paths = pathFromHexId(h3Index); // Convert H3 index to polygon paths. This function needs to correctly convert H3 indexes to a format that Google Maps can understand as a polygon.
      const strokeHex = getCSSVariableValue('--fuse-primary-100'); // Attempt to fetch a CSS variable for stroke color
      const fillHex = getCSSVariableValue('--fuse-primary-500'); // Attempt to fetch a CSS variable for fill color
      const maxCount = Math.max(
        ...Object.values(h3IndexToJobsMap).map((jobs) => jobs.length)
      );
      const normalizedCount = jobCount / maxCount; // Normalize job count for opacity calculation
      const fillOpacity = 0.1 + normalizedCount * 0.8; // Calculate fill opacity based on job count

      // Ensure paths are correctly generated and can be interpreted by Google Maps.
      // If hexagons are not rendering, verify that `pathFromHexId` correctly translates H3 indexes into a set of LatLng points that Google Maps can use to draw polygons.
      // Also, check if CSS variables for colors are correctly defined and accessible in this context.
      return {
        paths: paths,
        fillOpacity: fillOpacity,
        strokeColor: strokeHex ? strokeHex : '#FFB84D',
        strokeWeight: 0.2,
        fillColor: fillHex ? lightenHexColor(fillHex, 20) : '#FFB84D',
      };
    }
  );

  return polygons;
}

export const MAP_AGGREGATE_RESOLUTION_CONSTANT = 1.6;
