import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomTranslocoLoader implements TranslocoLoader {
  getTranslation(lang: string): Observable<any> {
    // Relative path from the component to the translation files
    return from(import(`./i18n/${lang}.json`));
  }
}
