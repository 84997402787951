import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { EntityDetailLayoutService } from '../entity-detail-layout/entity-detail-layout.service';
import {
  EntityHeader,
  EntityHeaderService,
} from '../entity-header-with-breadcrumbs';
import { OperatorModel } from '@fleet/model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fleet-operator-header',
  templateUrl: './operator-header.component.html',
  styleUrls: ['./operator-header.component.scss'],
})
export class OperatorHeaderComponent implements OnInit {
  header: EntityHeader;
  @Input() operator: OperatorModel;
  fleetProduct: string;
  showMenuButton: boolean;
  constructor(
    private entityDetailLayoutService: EntityDetailLayoutService,
    private changeDetectorRef: ChangeDetectorRef,
    private entityHeaderService: EntityHeaderService,
    private router: Router,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
    this.entityDetailLayoutService.showMenuButton$
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (show: boolean) => {
          this.showMenuButton = show;
          this.changeDetectorRef.markForCheck();
        },
      });
  }
  ngOnInit(): void {
    this.entityHeaderService.operatorHeader$.subscribe((header) => {
      this.header = header;
      this.changeDetectorRef.markForCheck();
    });
  }

  menuButtonClicked() {
    this.entityDetailLayoutService.setDrawOpened(true);
  }

  dataItemClicked(dataItem: any) {
    if (dataItem.link) {
      this.router.navigate([dataItem.link]);
    }
  }
}
