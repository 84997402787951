import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseMediaWatcherService, FuseNavigationItem } from '@fleet/fuse';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityDetailLayoutService } from './entity-detail-layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fleet-entity-detail-layout',
  templateUrl: './entity-detail-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `[slot=banner]:empty,
  [slot=entity-header]:empty,
  [slot=main]:empty {
    display: none;
  }
  router-outlet {
    display:none
  }`,
})
export class EntityDetailLayoutComponent implements OnInit, OnDestroy {
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean;
  currentMediaAlias: string[];
  @Input() navigation: any[];
  @Input() detailMenuLabel: string;
  @Input() withPadding = false;
  showHeader = true;

  title: string;
  routerData$: BehaviorSubject<any> = new BehaviorSubject(null);

  protected _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    protected _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected entityDetailLayoutService: EntityDetailLayoutService
  ) {
    combineLatest([
      this.entityDetailLayoutService.drawOpened$,
      this._fuseMediaWatcherService.onMediaChange$,
    ])
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: ([opened, media]: [boolean, any]) => {
          this.drawerOpened = opened;

          this.entityDetailLayoutService.setShowMenuButton(
            media.matchingAliases.length < 2 && this.drawerOpened === false
          );

          this._changeDetectorRef.markForCheck();
        },
      });
  }

  ngOnInit(): void {
    //this.routerData$.next(this._activatedRoute.snapshot.firstChild.data);

    const routeData = this._activatedRoute.snapshot.firstChild.data;
    this.title = routeData.title;
    this.showHeader = routeData.showHeader;
    if (routeData.withEntityLayoutPadding === false) {
      this.withPadding = false;
    } else {
      this.withPadding = true;
    }
    this._router.events
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const routeData = this._activatedRoute.snapshot.firstChild.data;
          this.title = routeData.title;
          this.showHeader = routeData.showHeader;
          this.routerData$.next(routeData);
          if (routeData.withEntityLayoutPadding === false) {
            this.withPadding = false;
          } else {
            this.withPadding = true;
          }
          if (this.drawerMode === 'over') {
            this.entityDetailLayoutService.setDrawOpened(false);
            // this.drawerOpened = false;
          }
          this._changeDetectorRef.markForCheck();
        }
      });

    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((media: any) => {
        this.currentMediaAlias = media.matchingAliases;
        // Set the drawerMode and drawerOpened if
        if (media.matchingAliases.includes('sm')) {
          this.drawerMode = 'side';
          //this.drawerOpened = true;
          this.entityDetailLayoutService.setDrawOpened(true);
        } else {
          this.drawerMode = 'over';

          //this.drawerOpened = false;
          this.entityDetailLayoutService.setDrawOpened(false);
        }
      });
  }

  onBackdropClicked() {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  closeDrawer() {
    //   this.drawerOpened = false;
    this.entityDetailLayoutService.setDrawOpened(false);
    this._changeDetectorRef.markForCheck();
  }

  openDrawer() {
    this.entityDetailLayoutService.setDrawOpened(true);
    this._changeDetectorRef.markForCheck();
  }
}
