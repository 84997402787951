import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './components/map/map.component';

import { MapMarkersPipe } from './pipes/map-markers.pipe';
import { MarkerSvgComponent } from './components/markers/marker-svg/marker-svg.component';
import { MatButtonModule } from '@angular/material/button';

import { PositionDetailCardModule } from '@fleet/card';

import { InternationalizationDatePipeModule } from '@fleet/internationalization';

import { ActiveJobCardModule } from '@fleet/card';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';

export const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    return acc;
  },
  {}
);

@NgModule({
  declarations: [MapComponent, MapMarkersPipe, MarkerSvgComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,

    MatButtonModule,
    PositionDetailCardModule,
    ActiveJobCardModule,
    InternationalizationDatePipeModule,
    MatProgressSpinnerModule,
    TranslocoDirective,
  ],
  exports: [MapComponent],
  providers: [
    provideTranslocoScope({
      scope: 'mapModule',
      loader: lazyTranslationloader,
    }),
  ],
})
export class MapModule {}
