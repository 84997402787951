import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
  UntypedFormControl,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { OperatorApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  IssueModel,
  OperatorUserModel,
  OperatorUserSearchResultModel,
} from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import {
  EMPTY,
  Observable,
  catchError,
  debounceTime,
  map,
  switchMap,
  tap,
} from 'rxjs';

@Component({
  selector: 'fleet-operator-user-autocomplete',
  templateUrl: './operator-user-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class OperatorUserAutocompleteComponent
  implements OnInit, ControlValueAccessor
{
  @Input() labelClass: string;
  userAutocompleteErrorState = new OperatorUserAutocompleteErrorState();

  _operatorId: string;
  @Input() set operatorId(value: string) {
    this._operatorId = value;
    if (value) {
      this.users$ = EMPTY;
      this.wireAutoComplete();
    }
  }
  get operatorId() {
    return this._operatorId;
  }
  @Input() placeHolder: string;
  @Input() label: string;
  @Input() prefixIcon = 'search';

  searchControl = new UntypedFormControl();
  searching = false;
  issues: IssueModel[] = [];
  @Input() mode: string;
  users$: Observable<OperatorUserSearchResultModel[]>;

  searchComplete = false;

  @ViewChild('input', { static: true }) input: ElementRef;

  @Output() operatorUserSelected = new EventEmitter();
  constructor(
    private operatorApiService: OperatorApiService,
    private changeDetectionRef: ChangeDetectorRef,
    private authService: AuthService,
    private networkGroupService: NetworkGroupService,
    @Self() public ngControl: NgControl
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.ngControl.control.statusChanges.subscribe((change: any) => {
        if (change === 'INVALID') {
          this.searchControl.setErrors(
            { hasErrors: true },
            { emitEvent: false }
          );

          this.searchControl.updateValueAndValidity({ emitEvent: false });
        } else {
          this.searchControl.setErrors(null, { emitEvent: false });
          this.searchControl.updateValueAndValidity({ emitEvent: false });
        }
        this.changeDetectionRef.markForCheck();
        this.changeDetectionRef.detectChanges();
      });
    }, 100);
  }

  wireAutoComplete() {
    this.users$ = this.searchControl.valueChanges.pipe(
      debounceTime(300),

      tap((value) => {
        console.log(value);
      }),
      // takeWhile((value) => value !== 'not-found' && typeof value === 'string'),
      tap(() => {
        this.searching = true;
        this.issues = [];
        this.searchComplete = false;

        this.changeDetectionRef.markForCheck();
      }),
      switchMap((value: any) => {
        if (value && value !== 'not-found' && typeof value === 'string') {
          const params = { limit: 25, offset: 0, name: value } as any;
          if (value === 'empty-search') {
            delete params['name'];
          }
          if (this.authService.networkId) {
            params['networkId'] = this.authService.networkId;
          }
          if (this.networkGroupService.networkGroupId) {
            params['networkGroupId'] = this.networkGroupService.networkGroupId;
          }

          return this.operatorApiService
            .searchUsers(params, this.operatorId)
            .pipe(
              map(
                (
                  resp:
                    | HttpResponse<ApiResponse<OperatorUserSearchResultModel[]>>
                    | any
                ) => {
                  this.searching = false;

                  this.searchComplete = true;
                  this.changeDetectionRef.markForCheck();
                  return resp.body.data;
                }
              ),

              catchError((issues: IssueModel[]) => {
                this.issues = issues;
                this.searching = false;
                this.searchComplete = false;

                this.changeDetectionRef.markForCheck();
                return [];
              })
            );
        } else {
          if (value === '') {
            this.onChange(null);
          }
          this.searching = false;
          this.searchComplete = false;
          this.changeDetectionRef.markForCheck();

          return [];
        }
      })
    );
  }

  selectUser(e: any) {
    let value = e?.option?.value;
    this.operatorUserSelected.emit(value);

    value = this.mode === 'id' ? value?.operatorUserId : value ? value : '';
    this.onChange(value);
    this.onTouched();
  }

  displayFn(user?: OperatorUserSearchResultModel): string | undefined {
    if (user && user.firstName) {
      return user.firstName + ' ' + user.lastName;
    }
    return '';
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  writeValue(value: any) {
    if (value) {
      if (!value?.organisationId || typeof value === 'string') {
        this.searching = true;
        this.changeDetectionRef.markForCheck();
        this.operatorApiService
          .getUser(
            typeof value === 'string' ? value : value.operatorUserId,
            this.operatorId
          )
          .pipe(
            map((resp: ApiResponse<OperatorUserModel> | any) => resp.data),
            catchError((error) => (this.issues = error)),
            tap(() => (this.searching = false))
          )
          .subscribe({
            next: (user: OperatorUserModel) => {
              this.searching = false;
              //on change
              this.selectUser({ option: { value: user } });

              if (this.input) {
                this.input.nativeElement.value =
                  user.firstName + ' ' + user.lastName;
              }
              this.changeDetectionRef.markForCheck();
            },
            error: (error) => {
              this.changeDetectionRef.markForCheck();
            },
          });
      } else {
        // setTimeout(() => {
        //   this.input.nativeElement.value =
        //     value.firstName + ' ' + value.lastName;
        // }, 1);
        this.input.nativeElement.value = value.firstName + ' ' + value.lastName;
        this.selectUser({ option: { value: value } });
        this.changeDetectionRef.markForCheck();
      }

      //this.input.nativeElement.value = value ? value.name : '';
    } else {
      this.searchControl.reset();
      this.changeDetectionRef.markForCheck();
    }
  }
  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onInput() {}

  inputClick(eventTarget: any) {
    if (
      eventTarget.value === '' &&
      (this.searchControl.value === '' || this.searchControl.value == null)
    ) {
      this.searchControl.setValue('empty-search');
      this.searchControl.updateValueAndValidity();
    } else {
      eventTarget.select();
    }
  }

  // searchAndSetUser() {
  //   const params = { organisationUser };
  //   if (this.authService.networkId) {
  //     params['networkId'] = this.authService.networkId;
  //   }
  //   if (this.networkGroupService.networkGroupId) {
  //     params['networkGroupId'] = this.networkGroupService.networkGroupId;
  //   }

  //   return this.organisationApiService.searchUsers(params, this.organisationId);
  // }
}
class OperatorUserAutocompleteErrorState implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    formDirective: FormGroupDirective | NgForm | null
  ): boolean {
    return (
      formDirective.form.status === 'INVALID' &&
      control.touched &&
      (!control.value || (control.value && control.value === 'empty-search'))
    );
  }
}
