import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fleet/fuse';
import {
  DriverModel,
  JobModel,
  OperatorModel,
  OperatorUserModel,
  OrganisationModel,
  OrganisationUserModel,
  TicketModel,
  TravellerModel,
  VehicleModel,
} from '@fleet/model';
import { TicketCreateEditComponent } from '../ticket-create-edit/ticket-create-edit.component';
import { TicketLinkComponent } from '../ticket-link/ticket-link.component';

@Component({
  selector: 'fleet-ticket-action-button',
  templateUrl: './ticket-action-button.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class TicketActionButtonComponent implements OnInit {
  @Input() defaultType: string;
  @Input() driver: DriverModel;
  @Input() vehicle: VehicleModel;
  @Input() traveller: TravellerModel;
  @Input() job: JobModel;
  @Input() ticket: TicketModel;
  @Input() organisation: OrganisationModel;
  @Input() organisationUser: OrganisationUserModel;
  @Input() operator: OperatorModel;
  @Input() operatorUser: OperatorUserModel;

  @Input() context: any;

  @Output() actionSuccessful = new EventEmitter();

  constructor(private matDialog: MatDialog) {}

  ngOnInit(): void {
    // this.createTicket();
  }

  createTicket() {
    const dialog = this.matDialog.open(TicketCreateEditComponent);

    dialog.componentInstance.defaultType = this.defaultType;
    if (this.driver) {
      dialog.componentInstance.driver = this.driver;
    }
    if (this.traveller) {
      dialog.componentInstance.traveller = this.traveller;
    }
    if (this.vehicle) {
      dialog.componentInstance.vehicle = this.vehicle;
    }
    if (this.job) {
      dialog.componentInstance.job = this.job;
    }
    if (this.organisation) {
      dialog.componentInstance.organisation = this.organisation;
    }
    if (this.organisationUser) {
      dialog.componentInstance.organisationUser = this.organisationUser;
    }
    if (this.operator) {
      dialog.componentInstance.operator = this.operator;
    }
    if (this.operatorUser) {
      dialog.componentInstance.operatorUser = this.operatorUser;
    }

    dialog.componentInstance.cancelled.subscribe(() => {
      dialog.close();
    });
    dialog.componentInstance.successful.subscribe((ticket: TicketModel) => {
      dialog.close();
      this.actionSuccessful.emit();
    });
  }

  linkTicket() {
    const dialog = this.matDialog.open(TicketLinkComponent);

    if (this.driver) {
      dialog.componentInstance.driver = this.driver;
    }
    if (this.traveller) {
      dialog.componentInstance.traveller = this.traveller;
    }
    if (this.job) {
      dialog.componentInstance.job = this.job;
    }
    if (this.ticket) {
      dialog.componentInstance.ticket = this.ticket;
    }
    if (this.vehicle) {
      dialog.componentInstance.vehicle = this.vehicle;
    }
    if (this.organisation) {
      dialog.componentInstance.organisation = this.organisation;
    }
    if (this.organisationUser) {
      dialog.componentInstance.organisationUser = this.organisationUser;
    }
    if (this.operator) {
      dialog.componentInstance.operator = this.operator;
    }
    if (this.operatorUser) {
      dialog.componentInstance.operatorUser = this.operatorUser;
    }

    dialog.componentInstance.cancelled.subscribe(() => {
      dialog.close();
    });

    dialog.componentInstance.successful.subscribe((ticket: TicketModel) => {
      dialog.close();
      this.actionSuccessful.emit();
    });
  }
}
