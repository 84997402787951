import { A11yModule } from '@angular/cdk/a11y';
import { DOWN_ARROW } from '@angular/cdk/keycodes';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DriverApiService } from '@fleet/api';
import { FuseAlertModule, fuseAnimations } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { SelectionListInDrawerModule } from '@fleet/layout';
import {
  ApiResponse,
  DriverModel,
  DriverSearchResultModel,
  IssueModel,
  JobDriverModel,
} from '@fleet/model';
import { MobileFormatModule } from '@fleet/pipes';
import { ProgressButtonModule } from '@fleet/shared';
import {
  driverSearchResultToJobDriver,
  removeEmptyParams,
} from '@fleet/utilities';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DriverListItemModule } from '../driver-list-item';
import { CommonModule } from '@angular/common';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);

@Component({
  selector: 'fleet-driver-search-list-autocomplete',
  template: `
    <ng-container *transloco="let t">
      <fleet-selection-list-in-drawer
        [title]="t('driverSearchListAutocomplete.findDriver')"
        (cancel)="cancel.emit()"
        *ngIf="mode === 'DRAWER'; else nonDrawerLayout"
      >
        <ng-container slot="filters">
          <ng-container *ngTemplateOutlet="form"></ng-container>
        </ng-container>

        <ng-container slot="error">
          <ng-container *ngTemplateOutlet="error"></ng-container>
        </ng-container>

        <ng-container slot="list">
          <ng-container *ngTemplateOutlet="list"></ng-container>
        </ng-container>
      </fleet-selection-list-in-drawer>

      <ng-template #nonDrawerLayout>
        <div class="flex flex-col w-full gap-3">
          <ng-container *ngTemplateOutlet="form"></ng-container>
          <ng-container *ngTemplateOutlet="error"></ng-container>
          <ng-container *ngTemplateOutlet="list"></ng-container>
        </div>
      </ng-template>

      <ng-template #driverTemplate let-driver>
        <fleet-driver-list-item [driver]="driver"></fleet-driver-list-item>
      </ng-template>

      <ng-template #list>
        <mat-selection-list class="selection-list-no-check mt-2" #driverList>
          <mat-list-option
            *ngFor="let driver of driverSearch"
            class="h-auto"
            (click)="driverSelected.emit(driver)"
            (keydown.enter)="driverSelected.emit(driver)"
            [disabled]="disableDriverIds.includes(driver.driverId)"
          >
            <ng-container
              *ngTemplateOutlet="driverTemplate; context: { $implicit: driver }"
            ></ng-container>
          </mat-list-option>

          <mat-list-option *ngIf="driverSearch?.length === 0 && searchComplete">
            {{ t('driverSearchListAutocomplete.noResults') }}
          </mat-list-option>
        </mat-selection-list>
      </ng-template>

      <ng-template #error>
        <fuse-alert
          *ngFor="let alert of issues | alertsFromIssues"
          class=""
          [appearance]="'outline'"
          [showIcon]="true"
          [type]="alert.type"
          [@shake]="alert.type === 'error'"
        >
          {{ alert.message }}
        </fuse-alert>
      </ng-template>

      <ng-template #form>
        <form [formGroup]="searchForm">
          <div class="flex flex-row gap-1">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label class="text-sm font-light">{{
                t('driverSearchListAutocomplete.firstName')
              }}</mat-label>
              <input
                formControlName="firstName"
                [value]="searchForm.controls['firstName'].value"
                matInput
              />

              <mat-icon
                [svgIcon]="'heroicons_solid:user-circle'"
                class="icon-size-5"
                matPrefix
              ></mat-icon>
            </mat-form-field>

            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label class="text-sm font-light">{{
                t('driverSearchListAutocomplete.lastName')
              }}</mat-label>
              <input
                formControlName="lastName"
                [value]="searchForm.controls['lastName'].value"
                matInput
              />

              <mat-icon
                [svgIcon]="'heroicons_solid:user-circle'"
                class="icon-size-5"
                matPrefix
              ></mat-icon>
            </mat-form-field>
          </div>

          <div class="flex flex-row gap-1">
            <mat-form-field class="w-full fuse-mat-dense">
              <mat-label class="text-sm font-light">{{
                t('driverSearchListAutocomplete.driverAuthority')
              }}</mat-label>
              <input
                formControlName="taxiAuthorityNumber"
                [value]="searchForm.controls['taxiAuthorityNumber'].value"
                matInput
              />

              <mat-icon
                [svgIcon]="'iconsmind:taxi_sign'"
                class="icon-size-5"
                matPrefix
              ></mat-icon>
            </mat-form-field>
          </div>
          <div class="w-full flex items-center justify-end">
            <button
              mat-button
              matTooltip="{{ t('driverSearchListAutocomplete.clearSearch') }}"
              type="button"
              (click)="resetForm()"
            >
              {{ t('driverSearchListAutocomplete.clear') }}
            </button>

            <fleet-progress-button
              class="ml-3"
              [state]="{
                loading: searching,
                matTooltip: t('driverSearchListAutocomplete.searchDrivers'),
                buttonLabel: t('driverSearchListAutocomplete.search')
              }"
              [disabled]="searching"
              type="submit"
              (onClick)="search()"
            >
            </fleet-progress-button>
          </div>
        </form>
      </ng-template>
    </ng-container>
  `,

  styles: [
    `
      .driver-list {
        .mat-pseudo-checkbox {
          display: none !important;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatListModule,
    CdkScrollableModule,
    A11yModule,
    SelectionListInDrawerModule,
    FormsModule,
    MatAutocompleteModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    MobileFormatModule,
    MatProgressSpinnerModule,
    ProgressButtonModule,
    DriverListItemModule,
    TranslocoDirective,
    MatTooltipModule,
  ],
  providers: [
    provideTranslocoScope({
      scope: 'driverSearchListAutocomplete',
      loader: lazyTranslationloader,
    }),
  ],
})
export class DriverSearchListAutocompleteComponent
  implements OnInit, AfterViewInit
{
  searching: boolean;
  issues: IssueModel[];
  @Input() selectedDriver: DriverModel;
  @Input() mode = 'DRAWER';

  searchControl: UntypedFormControl = new UntypedFormControl();
  searchForm: UntypedFormGroup;

  driverSearch: DriverSearchResultModel[];
  searchComplete = false;
  driver: BehaviorSubject<DriverModel> = new BehaviorSubject(null);
  jobDriver: BehaviorSubject<JobDriverModel> = new BehaviorSubject(null);
  @Input() disableDriverIds: string[] = [];

  @Output() driverSelected = new EventEmitter();

  @Output() cancel = new EventEmitter();
  @ViewChild('driverList') driverList: MatSelectionList;
  @ViewChild('input') input: ElementRef;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private driverApiService: DriverApiService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      taxiAuthorityNumber: new UntypedFormControl(),
    });
  }

  search() {
    const params = this.assembleParams(this.searchForm.value);
    this.searchComplete = false;
    this.searching = true;
    this.issues = [];
    this.changeDetectorRef.markForCheck();
    this.driverApiService.searchDrivers(params).subscribe({
      next: (
        resp: HttpResponse<ApiResponse<DriverSearchResultModel[]>> | any
      ) => {
        this.driverSearch = resp.body.data;
        this.searchComplete = true;
        this.searching = false;
        this.changeDetectorRef.markForCheck();
      },
      error: (issues: IssueModel[]) => {
        this.issues = issues;
        this.searching = false;
        this.searchComplete = false;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus();
      } else {
      }
    }, 100);
  }

  inputKeydown(event: { keyCode: number }) {
    if (event.keyCode === DOWN_ARROW) {
      //If key down, lets check if we can navigate on the service
      this.driverList.options.first.focus();
    }
  }

  assembleParams(searchParams: any) {
    const params: any = Object.assign(
      {},
      { limit: 25, offset: 0 },
      removeEmptyParams(searchParams)
    );

    return params;
  }

  resetForm() {
    this.searchComplete = false;
    this.searchForm.reset();
    this.changeDetectorRef.markForCheck();
  }
}
