<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <!-- Header -->

  <mat-drawer-container
    class="flex-auto h-full dark:bg-transparent"
    autosize
    *ngIf="
      !functionTypeAndAction ||
        (functionTypeAndAction && functionTypeAndAction | hasFunction);
      else noPermissions
    "
  >
    <!-- Drawer -->
    <mat-drawer
      #matDrawer
      *ngIf="!hideDrawer"
      [disableClose]="true"
      [mode]="drawerMode"
      [opened]="drawerOpened"
      class="w-full md:w-100 dark:bg-gray-900"
    >
      <ng-content select="[slot=sidebar]"> </ng-content>
    </mat-drawer>

    <mat-drawer-content class="flex flex-col">
      <mat-drawer-container
        (backdropClick)="setEndDrawer(false); backdropClosed.emit()"
        class="absolute inset-0 flex flex-col bg-default"
        [hasBackdrop]="true"
        [class.noBackdropColor]="endDrawerType === 'PREVIEW'"
      >
        <mat-drawer
          class="w-full"
          [ngClass]="{ 'md:w-180': largeWidth, 'md:w-120': !largeWidth }"
          #matSidebarDrawer
          [mode]="'over'"
          [position]="'end'"
          [opened]="endDrawerOpened"
        >
          <mat-drawer-content #matDrawerContent>
            <ng-content
              *ngIf="endDrawerType === 'PREVIEW'"
              select="[slot=preview]"
            >
            </ng-content>
            <ng-content
              *ngIf="endDrawerType === 'CREATE_EDIT'"
              select="[slot=create-edit]"
            ></ng-content>
          </mat-drawer-content>
        </mat-drawer>

        <mat-drawer-content
          #mainContent
          class="flex flex-col bg-default px-6 pb-12 pt-9 md:p-8 md:pb-12 lg:p-12"
          id="previewContainer"
        >
          <div class="flex flex-col justify-between gap-4">
            <div>
              <div class="flex flex-col sm:flex-row sm:items-center">
                <!-- Title -->
                <div class="flex flex-col">
                  <div
                    class="mb-2 text-1xl font-medium text-primary"
                    *ngIf="groupDisplayName"
                  >
                    {{ groupDisplayName }}
                  </div>

                  <div
                    class="text-3xl sm:text-4xl font-extrabold tracking-tight leading-none"
                  >
                    <ng-content select="[slot=title]"></ng-content>
                  </div>
                  <div
                    class="ml-0.5 mt-1 font-medium text-secondary"
                    *ngIf="searchData && searchData.pageData"
                  >
                    <ng-content select="[slot=search-summary]"></ng-content>
                  </div>
                </div>

                <!-- filter and actions -->
                <div class="flex flex-row justify-end ml-auto">
                  <div
                    *ngIf="showFilter"
                    class="flex shrink-0 items-center px-4 sm:hidden"
                  >
                    <button (click)="openSearch()" mat-stroked-button>
                      <mat-icon
                        class="icon-size-4 mt-0.5"
                        [svgIcon]="'heroicons_outline:filter'"
                      ></mat-icon>
                    </button>
                  </div>

                  <!--  ACTIONS -->

                  <ng-content select="[slot=actions]"></ng-content>
                </div>
              </div>
            </div>

            <!-- Search and  actions
            There is a row
            inisde the row there is always an aligned right actions (ml-auto for far side)
            inside the row when the draw is closed there is also a filter button
            and if the size is small the basic form hides
            If the draw is opened there is just the actions  -->

            <div class="hidden sm:flex flex-row flex-grow items-baseline mt-0">
              <div
                *ngIf="!drawerOpened"
                class="hidden sm:flex flex-grow items-baseline"
              >
                <!-- /Basic Form with Search BUtton -->
                <div class="hidden sm:flex flex-grow">
                  <ng-content select="[slot=basic-search]"></ng-content>
                </div>

                <!-- ADVANCED SEARCH BUTTN -->
                <div
                  *ngIf="showAdvancedSearch"
                  class="hidden md:pl-4 sm:flex shrink-0 items-center"
                >
                  <button (click)="openSearch()" mat-stroked-button>
                    <mat-icon
                      class="icon-size-4 mt-0.5"
                      [svgIcon]="'heroicons_outline:filter'"
                    ></mat-icon>
                    <span class="ml-2 mr-1">Advanced Search</span>
                  </button>
                </div>
              </div>

              <div class="ml-auto">
                <ng-content select="[slot=actions]"></ng-content>
              </div>
            </div>

            <ng-content select="[slot=search-chips]"></ng-content>
          </div>

          <div class="pb-3">
            <ng-content select="[slot=error]"></ng-content>
          </div>
          <!-- <div class="sm:mx-4 md:mx-6 bg-card shadow-sm"> -->
          <ng-content
            *ngIf="
              searchData && searchData.pageData && searchData.data.length > 0
            "
            select="[slot=results]"
          >
          </ng-content>
          <!-- </div> -->

          <div
            class="bg-card shadow-sm"
            *ngIf="
              !hasPageData &&
              searchData &&
              searchData.data &&
              searchData.data.length > 0
            "
          >
            <ng-content select="[slot=pagelessresults]"></ng-content>
          </div>

          <div class="mt-2" *ngIf="searchData && searchData.searching">
            <mat-spinner [diameter]="24" style="margin: 0 auto"> </mat-spinner>
          </div>

          <fleet-no-results-label
            *ngIf="
              searchData && searchData.pageData && searchData.data.length === 0
            "
            [type]="resultEntityType"
          >
          </fleet-no-results-label>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-drawer-content>
  </mat-drawer-container>

  <ng-template #noPermissions>
    <mat-drawer-container class="flex-auto h-full dark:bg-transparent" autosize>
      <mat-drawer-content class="flex flex-col">
        <mat-drawer-container
          class="absolute inset-0 flex flex-col bg-default"
          [hasBackdrop]="true"
          [class.noBackdropColor]="endDrawerType === 'PREVIEW'"
          (backdropClick)="setEndDrawer(false)"
        >
          <mat-drawer-content
            #mainContent
            class="flex flex-col bg-default"
            id="previewContainer"
          >
            <fleet-no-permission-label> </fleet-no-permission-label>
          </mat-drawer-content>
        </mat-drawer-container>
      </mat-drawer-content>
    </mat-drawer-container>
  </ng-template>
</div>
