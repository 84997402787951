import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteDetailComponent } from './note-detail.component';
import {
  ActionButtonsModule,
  BadgeModule,
  CardDescriptionListModule,
  SidebarHeaderModule,
  StatusChipModule,
} from '@fleet/ui';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
import { LineBreakPipeModule, TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [NoteDetailComponent],
  imports: [
    CommonModule,
    CardDescriptionListModule,
    SidebarHeaderModule,
    ActionButtonsModule,
    TitleCaseAndCleanPipeModule,
    InternationalizationDatePipeModule,
    LineBreakPipeModule,
    StatusChipModule,
    BadgeModule,
    TranslocoDirective,
  ],
  exports: [NoteDetailComponent],
  providers: [
    provideTranslocoScope({
      scope: 'noteDetail',
      loader: lazyTranslationloader,
    }),
  ],
})
export class NoteDetailModule {}
