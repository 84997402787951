<div class="flex flex-col w-full py-3">
  <div class="flex items-center">
    <div
      class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden"
    >
      <img
        *ngIf="driver?.imageUrl; else noImage"
        class="w-10 h-10 rounded-full object-cover"
        [src]="driver?.imageUrl"
        alt="Driver photo"
      />
      <ng-template #noImage>
        <span
          class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500"
        >
          <span class="text-xl font-medium leading-none text-white">
            {{
              driver?.firstName && driver?.lastName
                ? driver?.firstName.substring(0, 1) +
                  driver?.lastName.substring(0, 1)
                : ''
            }}
          </span>
        </span>
      </ng-template>
    </div>
    <div class="ml-4">
      <div class="font-medium">{{ driver?.displayName }}</div>
      <div class="text-sm text-secondary">
        {{ driver?.taxiAuthorityNumber }}
      </div>
    </div>

    <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
      <ng-container *ngIf="showAddButton">
        <button mat-icon-button (click)="add.emit(driver)">
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="showDeleteButton">
        <button mat-icon-button (click)="delete.emit(driver)">
          <mat-icon>delete</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
</div>
