import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  BaseSearchState,
  initialBaseSearchState,
  JobSearchResultModel,
} from '@fleet/model';
import { pageDataFromSearchResultHeaders } from '@fleet/utilities';
import { BehaviorSubject, map, Subscription } from 'rxjs';

import { JobApiService } from '@fleet/api';
import { AuthService } from '@fleet/auth';
import { NetworkGroupService } from '@fleet/network-group';

@Injectable()
export class JobSearchService {
  jobSearchState: BehaviorSubject<BaseSearchState<JobSearchResultModel>> =
    new BehaviorSubject(initialBaseSearchState);
  private apiCallSubscription: Subscription;

  get jobSearch$() {
    return this.jobSearchState.asObservable();
  }

  constructor(
    private jobApiService: JobApiService,
    private networkGroupService: NetworkGroupService,
    private authService: AuthService
  ) {}

  getJobSearch() {
    return this.jobSearchState.asObservable();
  }

  searchJobs(params: any) {
    //Job search can take a while to return, need to stop searching if its loading.
    if (!this.jobSearchState.value.searching) {
      if (this.authService.networkId) {
        params['networkId'] = this.authService.networkId;
      }
      if (this.networkGroupService.networkGroupId) {
        params['networkGroupId'] = this.networkGroupService.networkGroupId;
      }
      this.jobSearchState.next({
        ...this.jobSearchState.value,
        searching: true,
        issues: [],
      });

      if (this.apiCallSubscription) {
        this.apiCallSubscription.unsubscribe();
      }

      this.apiCallSubscription = this.jobApiService
        .searchJobs(params)
        .subscribe({
          next: (
            resp: HttpResponse<ApiResponse<JobSearchResultModel[]>> | any
          ) => {
            let jobs = [];
            if (params['offset'] === 0) {
              // FIRST PAGE
              jobs = resp.body.data;
            } else {
              // SUBSEQUENT PAGES
              jobs = [...this.jobSearchState.value.data, ...resp.body.data];
            }

            this.jobSearchState.next({
              ...this.jobSearchState.value,
              data: jobs,
              searching: false,
              pageData: pageDataFromSearchResultHeaders(resp.headers, params),
            });
          },
          error: (error) => {
            this.jobSearchState.next({
              ...this.jobSearchState.value,
              issues: error,
              searching: false,
            });
          },
        });
    }
  }

  get pageDataParams$() {
    return this.jobSearch$.pipe(
      map((search: BaseSearchState<JobSearchResultModel>) => {
        return search.pageData.params;
      })
    );
  }

  getNextPage() {
    // TEST IF NOT LAST PAGE
    if (
      this.jobSearchState.value?.pageData?.currentPage !=
      this.jobSearchState.value?.pageData?.totalPages
    ) {
      //GET NEXT PAGE
      const newOffset = String(
        this.jobSearchState.value.pageData.currentPage *
          Number(this.jobSearchState.value.pageData.params.limit)
      );
      const newParams = {
        ...this.jobSearchState.value.pageData.params,
        offset: newOffset,
      };

      this.searchJobs(newParams);
    }
  }

  removeParamAndSearch(param: string) {
    const params = Object.assign({}, this.jobSearchState.value.pageData.params);

    if (param === 'fromDate-toDate') {
      delete params['fromDate'];
      delete params['toDate'];
    } else {
      delete params[param];
    }

    this.searchJobs(params);
  }

  setJobPreview(job: any) {
    this.jobSearchState.next({
      ...this.jobSearchState.value,
      selectedPreview: job,
    });
  }

  clearState() {
    this.jobSearchState.next(initialBaseSearchState);
  }

  removeTermAndReturnParamSearchTerms(term: string) {
    const params = Object.assign({}, this.jobSearchState.value.pageData.params);
    delete params[term];

    return params;
  }
}
