import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'fleet-action-panel',
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-template #heading>
      <h3 class="text-base font-semibold leading-6 text-gray-900">
        {{ title }}
      </h3>
    </ng-template>
    <ng-template #content>
      <div class="mt-2 max-w-xl text-sm text-gray-500">
        <p>{{ description }}</p>
      </div>
    </ng-template>
    <ng-container [ngSwitch]="variation">
      <ng-container *ngSwitchCase="'SIMPLE_WITH_ACTION'">
        <div class="bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <ng-container *ngTemplateOutlet="heading"></ng-container>
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <div class="mt-5">
              <ng-content select="[slot=action]"></ng-content>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'WITH_LINK'">
        <div class="bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <ng-container *ngTemplateOutlet="heading"></ng-container>
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <div class="mt-5">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'WITH_BUTTON_AT_TOP_RIGHT'">
        <div class="bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div>
                <ng-container *ngTemplateOutlet="heading"></ng-container>
                <ng-container *ngTemplateOutlet="content"></ng-container>
                <ng-content select="[slot=action]"></ng-content>
              </div>

              <!-- <ng-content select="[slot=action]"></ng-content> -->
              <div
                class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center"
              >
                <!-- <ng-content select="[slot=action]"></ng-content> -->
              </div>
            </div>
          </div>
        </div>
        <!-- Add complete HTML for 'WITH_BUTTON_AT_TOP_RIGHT' variation here -->
      </ng-container>
      <ng-container *ngSwitchCase="'WITH_TOGGLE'">
        <div class="bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <ng-container *ngTemplateOutlet="heading"></ng-container>
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <div class="mt-5">
              <label class="inline-flex items-center">
                <input type="checkbox" class="form-checkbox" />
                <span class="ml-2">Toggle</span>
              </label>
            </div>
          </div>
        </div>
        <!-- Add complete HTML for 'WITH_TOGGLE' variation here -->
      </ng-container>
      <ng-container *ngSwitchCase="'WITH_INPUT'">
        <div class="bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <ng-container *ngTemplateOutlet="heading"></ng-container>
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <div class="mt-5">
              <input
                type="text"
                class="form-input mt-1 block w-full"
                placeholder="Input"
              />
            </div>
          </div>
        </div>
        <!-- Add complete HTML for 'WITH_INPUT' variation here -->
      </ng-container>

      <ng-container *ngSwitchCase="'SIMPLE_WELL'">
        <div class="bg-gray-50 sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <ng-container *ngTemplateOutlet="heading"></ng-container>
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <div class="mt-5">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionPanelComponent {
  @Input() variation:
    | 'SIMPLE'
    | 'SIMPLE_WITH_ACTION'
    | 'WITH_LINK'
    | 'WITH_BUTTON_AT_TOP_RIGHT'
    | 'WITH_TOGGLE'
    | 'WITH_INPUT'
    | 'WITH_WELL';
  @Input() title: string;
  @Input() description: string;
}
