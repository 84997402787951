<ng-container>
  <mat-menu #appMenu="matMenu" [overlapTrigger]="false">
    <button mat-menu-item (click)="createTicket()">
      <div class="flex flex-row items-center">
        <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>

        <div>Create Ticket</div>
      </div>
    </button>
  </mat-menu>

  <button mat-icon-button [matMenuTriggerFor]="appMenu">
    <mat-icon [svgIcon]="'heroicons_outline:ticket'"></mat-icon>
  </button>
</ng-container>
