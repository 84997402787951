import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobOrganisationCardComponent } from './job-organisation-card.component';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    return acc;
  },
  {}
);
@NgModule({
  declarations: [JobOrganisationCardComponent],
  imports: [CommonModule, RouterModule, MatTooltipModule, TranslocoDirective],
  exports: [JobOrganisationCardComponent],
  providers: [
    provideTranslocoScope({
      scope: 'jobOrganisationCard',
      loader: lazyTranslationloader,
    }),
  ],
})
export class JobOrganisationCardModule {}
