import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { FleetReponsiveElementBaseComponent } from '@fleet/layout';
import {
  NgResizeObserver,
  ngResizeObserverProviders,
} from 'ng-resize-observer';

@Component({
  selector: 'fleet-ticket-search-results',
  templateUrl: './ticket-search-results.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...ngResizeObserverProviders],
})
export class TicketSearchResultsComponent
  extends FleetReponsiveElementBaseComponent
  implements OnInit
{
  @Input() excludeType:
    | 'DRIVER'
    | 'JOB'
    | 'TRAVELLER'
    | 'ORGANISATION'
    | 'VEHICLE'
    | 'OPERATOR'
    | 'ORGANISATION_USER'
    | 'OPERATOR_USER';
  DEFAULT_COLUMNS_JOB = [
    'created',
    'category',
    'subject',
    'updated',
    'status',
    'associations',
    'actions',
  ];
  DEFAULT_COLUMNS_ENTITY = [
    'created',
    'category',
    'subject',
    'updated',
    'status',
    'associations',
  ];

  @ViewChild('table', { read: ElementRef })
  public matTableRef: ElementRef;

  @Input() allowLinking = false;
  @Input() allowEditing = false;

  @Input() search: any;
  @Output() selectTicket = new EventEmitter();
  @Output() linkTicket = new EventEmitter();
  @Output() editTicket = new EventEmitter();

  @Output() navigateEntity = new EventEmitter();

  types: any;

  constructor(resize$: NgResizeObserver, private router: Router) {
    super(resize$);
  }
  ngOnInit(): void {}

  viewAssociation(association: {
    ticketAssociationId: string;
    type: string;
    id: string;
  }) {
    this.navigateEntity.emit({
      id: association.id,
      type: association.type.toLowerCase(),
    });
  }
}
