import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserAuthState } from './user-auth.reducer';

export const getUserAuthState =
  createFeatureSelector<UserAuthState>('userAuth');

export const getOrganisationId = createSelector(
  getUserAuthState,
  (state: UserAuthState) => state.user.organisationId
);

export const getOrganisationUserId = createSelector(
  getUserAuthState,
  (state: UserAuthState) => state.user.organisationUserId
);

export const getUser = createSelector(
  getUserAuthState,
  (state: UserAuthState) => state.user
);

export const verificationModel = createSelector(
  getUserAuthState,
  (state: UserAuthState) => state.verificationModel
);

export const loginModel = createSelector(
  getUserAuthState,
  (state: UserAuthState) => state.loginModel
);

export const samlQueryParams = createSelector(
  getUserAuthState,
  (state: UserAuthState) => state.samlQueryParams
);
