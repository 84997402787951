<ng-container *ngIf="header">
  <div class="lg:flex lg:items-center lg:justify-between">
    <div class="flex-1 min-w-0">
      <div class="flex flex-row items-center gap-4">
        <mat-icon
          class="cursor-pointer"
          *ngIf="showMenuButton"
          (click)="menuButtonClicked()"
          >menu_open</mat-icon
        >
        <!-- </button> -->
        <nav class="flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li *ngFor="let breadcrumb of header?.breadcrumbs">
              <fleet-breadcrumb-item
                [breadcrumb]="breadcrumb"
              ></fleet-breadcrumb-item>
            </li>
          </ol>
        </nav>
      </div>

      <div class="flex flex-row items-center mt-3" *ngIf="header.title">
        <!-- <button
          class="mr-5"
          mat-icon-button
          *ngIf="showMenuButton"
          (click)="menuButtonClicked()"
        >
          <mat-icon>menu</mat-icon>
        </button> -->
        <ng-container *ngIf="!header.panels; else panels">
          <fleet-avatar
            *ngIf="header?.avatar"
            [image]="header.avatar.image"
            [initials]="header.avatar.initials"
            [size]="14"
            class="mr-5"
          >
          </fleet-avatar>
          <div>
            <div class="flex flex-row">
              <h2
                class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate dark:text-white"
              >
                <div>
                  {{ header?.title }}
                </div>
              </h2>
              <div class="ml-3">
                <ng-content select="[slot=breadcrumb-action]"> </ng-content>
              </div>
            </div>

            <div
              class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
            >
              <ng-container *ngFor="let dataItem of header?.dataItems">
                <fleet-entity-header-data-item
                  [ngClass]="{ 'cursor-pointer': dataItem.link }"
                  [dataItem]="dataItem"
                ></fleet-entity-header-data-item>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-template #panels>
          <dl
            class="grid grid-cols-1 divide-y lg:grid-cols-3 lg:divide-y-0 lg:divide-x"
            [ngClass]="{ 'rounded-xl bg-card overflow-hidden shadow': isCard }"
          >
            <div class="flex flex-row items-center">
              <fleet-avatar
                class="min-w-fit"
                [image]="header?.avatar?.image"
                [size]="20"
              >
              </fleet-avatar>

              <dd
                class="flex flex-row justify-between items-center truncate px-4 py-5 sm:p-6"
              >
                <div class="flex flex-col gap-1 truncate">
                  <div
                    class="text-2xl font-semibold leading-tight truncate"
                    matTooltip="{{ header.titleTooltip }} "
                  >
                    {{ header.title }}
                  </div>
                  <span
                    class="text-md leading-tight text-primary truncate"
                    matTooltip="{{ header?.subtitleTooltip }}"
                  >
                    {{ header?.subtitle }}
                  </span>

                  <fleet-status-chip
                    (click)="
                      header?.statusLink ? dataItemClicked(header) : null
                    "
                    class="cursor-pointer py-1"
                    [status]="header?.status"
                  >
                  </fleet-status-chip>
                </div>
              </dd>
            </div>

            <div
              class="px-4 py-5 sm:p-6 justify-center items-center"
              *ngFor="let panel of header?.panels"
            >
              <dd class="flex flex-row justify-between items-center truncate">
                <div
                  class="flex flex-col gap-2 items-start justify-between truncate"
                >
                  <div
                    class="text-md text-secondary flex items-center gap-1 flex-row w-full truncate"
                    *ngFor="let dataItem of panel?.dataItems"
                  >
                    <span class="flex flex-row items-center gap-1 truncate">
                      <mat-icon
                        class="mr-1"
                        [ngClass]="dataItem?.iconStyle"
                        [svgIcon]="dataItem?.svgIcon"
                      >
                        {{ dataItem.icon }}
                      </mat-icon>
                      <fleet-dialable-phone-number
                        *ngIf="dataItem.dialablePhoneNumber; else normal"
                        phoneNumberClasses="leading-5 truncate font-medium"
                        [phoneNumber]="dataItem.dialablePhoneNumber.phoneNumber"
                        [tooltip]="dataItem.dialablePhoneNumber.tooltip"
                      ></fleet-dialable-phone-number>
                      <ng-template #normal>
                        <div
                          class="leading-5 truncate font-medium"
                          [ngClass]="dataItem.labelStyle"
                          [matTooltip]="dataItem.toolTip"
                          *ngIf="!dataItem.link; else link"
                        >
                          {{ dataItem.label }}
                        </div>
                        <ng-template #link>
                          <span
                            [ngClass]="dataItem.labelStyle"
                            class="leading-5 truncate font-medium cursor-pointer"
                            matTooltip="{{ dataItem.label }}"
                            (click)="
                              dataItem.link ? dataItemClicked(dataItem) : null
                            "
                          >
                            {{ dataItem.label }}
                          </span>
                        </ng-template>

                        <div
                          class="truncate font-small"
                          *ngIf="dataItem.secondaryLabel"
                        >
                          {{ dataItem.secondaryLabel }}
                        </div>
                      </ng-template>
                    </span>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
