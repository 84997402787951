import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  AuthService,
  ChangeMfaDialogComponent,
  ChangePhonenumberDialogComponent,
  ChangeUsernameDialogComponent,
} from '@fleet/auth';
import { ChangePasswordComponent } from '../change-password';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fleet/fuse';
import { Router } from '@angular/router';
import { NetworkGroupApiService } from '@fleet/api';
import { NetworkGroupService } from '@fleet/network-group';
import {
  DriverModel,
  NetworkGroupModel,
  NetworkUserModel,
  OrganisationUserModel,
  VerificationModel,
} from '@fleet/model';
import { FleetNavigationService } from '@fleet/navigation';

@Component({
  selector: 'fleet-user-menu',
  templateUrl: './user-menu.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class UserMenuComponent implements OnInit {
  user: any;
  @Input() showAvatar = true;
  groups: any[];

  constructor(
    private authService: AuthService,
    private matDialog: MatDialog,
    private router: Router,
    private networkGroupService: NetworkGroupService,
    private changeDetectorRef: ChangeDetectorRef,
    private fleetNavigationService: FleetNavigationService
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe({
      next: (user: any) => {
        this.user = user;
        if (
          this.authService.decodedToken &&
          this.authService.decodedToken.type === 'NETWORK' &&
          this.authService.decodedToken.groups &&
          this.authService.decodedToken.groups.length > 0
        ) {
          this.groups = this.authService.decodedToken.groups;
        } else {
          this.groups = [];
        }
        this.changeDetectorRef.markForCheck();
      },
    });

    //get
  }

  signOut() {
    this.authService.logout();
  }

  changeUsername() {
    // this.router.navigate(['/auth/change-password']);
    const dialog = this.matDialog.open(ChangeUsernameDialogComponent);

    dialog.componentInstance.user = this.user;

    dialog.componentInstance.changeSuccess.subscribe((email) => {
      this.authService.updateUserPartial({ username: email });
    });
  }

  changePassword() {
    //open up dialog for change password

    const dialog = this.matDialog.open(ChangePasswordComponent);

    dialog.componentInstance.changePasswordSuccess.subscribe(() => {
      dialog.close();
    });
  }

  changeGroup(group: any) {}

  changeMFA() {
    const dialog = this.matDialog.open(ChangeMfaDialogComponent);

    dialog.componentInstance.user = this.user;

    //we are hookingup to the success with user emit
    dialog.componentInstance.changeMFASuccessWithUser.subscribe((mfa) => {
      this.authService.updateUserPartial(mfa);
    });
  }

  changePhoneNumber() {
    const dialog = this.matDialog.open(ChangePhonenumberDialogComponent);

    dialog.componentInstance.user = this.user;

    dialog.componentInstance.changeSuccess.subscribe((phoneNumber) => {
      this.authService.updateUserPartial({ phoneNumber: phoneNumber });
    });
  }
}
