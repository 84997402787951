import { FuseNavigationItem } from '@fleet/model';

export const jobDetailNavigation: FuseNavigationItem[] = [
  {
    title: 'jobDetails',
    id: 'job.overview',
    type: 'group',
    children: [
      {
        title: 'overview',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: './dashboard',
        securityFunctions: ['JOB_DETAIL:read'],
      },
      {
        id: 'job.map',
        title: 'map',
        type: 'basic',
        icon: 'heroicons_outline:map',
        link: './map',
        securityFunctions: ['JOB_MAP:read'],
      },
      {
        id: 'job.timeline',
        title: 'timeline',
        type: 'basic',
        icon: 'heroicons_outline:clock',
        link: './timeline',
        securityFunctions: ['JOB_TIMELINE:read'],
      },
      {
        id: 'job.dispatch_logs',
        title: 'dispatchLogs',
        type: 'basic',
        icon: 'heroicons_outline:server',
        link: './dispatch-logs',
        securityFunctions: ['JOB_DISPATCH_LOGS:read'],
      },
    ],
  },
  {
    title: 'payment',
    type: 'group',
    securityFunctions: ['JOB_PRICING:read', 'JOB_PAYMENT:read'],
    children: [
      {
        title: 'pricing',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: './pricing',
        securityFunctions: ['JOB_PRICING:read'],
      },
      //call below module job-payment-sales
      {
        title: 'payment',
        type: 'basic',
        icon: 'heroicons_outline:credit-card',
        link: './payments',
        securityFunctions: ['JOB_PAYMENT:read'],
      },
    ],
  },
  {
    id: 'job.service',
    title: 'service',
    type: 'group',
    securityFunctions: [
      'JOB_RATINGS:read',
      'JOB_CALLS:read',
      'JOB_NOTES:read',
      'JOB_TICKETS:read',
    ],
    children: [
      {
        title: 'ratings',
        type: 'basic',
        icon: 'heroicons_outline:star',
        link: './ratings',
        securityFunctions: ['JOB_RATINGS:read'],
      },

      {
        title: 'calls',
        type: 'basic',
        icon: 'heroicons_outline:phone',
        link: './communications',
        securityFunctions: ['JOB_CALLS:read'],
      },

      {
        title: 'notes',
        type: 'basic',
        icon: 'heroicons_outline:pencil-alt',
        link: './notes',
        securityFunctions: ['JOB_NOTES:read'],
      },
      {
        title: 'serviceTickets',
        type: 'basic',
        icon: 'heroicons_outline:support',
        link: './tickets',
        securityFunctions: ['JOB_TICKETS:read'],
      },
    ],
  },
];

export const travellerJobDetailNavigation: FuseNavigationItem[] = [
  {
    title: 'jobDetails',
    id: 'job.overview',
    type: 'group',
    children: [
      {
        title: 'overview',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: './dashboard',
      },
      //leave map
      {
        id: 'job.map',
        title: 'map',
        type: 'basic',
        icon: 'heroicons_outline:map',
        link: './map',
      },
    ],
  },
  {
    title: 'payment',
    type: 'group',
    children: [
      {
        title: 'pricing',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: './pricing',
      },
      //call below module job-payment-sales
      {
        title: 'payment',
        type: 'basic',
        icon: 'heroicons_outline:credit-card',
        link: './payments',
      },
    ],
  },
];
