import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetterIconModule } from '@fleet/ui';
import { LocationModel } from '@fleet/model';
import { JobWaypointDisplayPipe } from '../job-waypoint-display-pipe/job-waypoint-display.pipe';

@Component({
  selector: 'fleet-job-waypoint-display',
  standalone: true,
  imports: [CommonModule, LetterIconModule],
  template: ` <div class="flex flex-row gap-2 items-center">
    <fleet-letter-icon
      [text]="waypoint?.letter"
      iconSize="5"
    ></fleet-letter-icon>
    <div class="flex flex-col leading-none gap-1">
      <div class="text-md">{{ waypoint?.location?.displayLine1 }}</div>
      <div *ngIf="waypoint?.location?.displayLine1 !== 'As Directed'">
        <span class="text-secondary uppercase text-sm">{{
          waypoint.location?.displayLine2
        }}</span>
      </div>
    </div>
  </div>`,
  styles: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobWaypointDisplayComponent {
  @Input() waypoint: { location: LocationModel; letter: string };
}
