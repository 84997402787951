import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseMediaWatcherService } from '@fleet/fuse';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityDetailLayoutService } from '../entity-detail-layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  selector: 'fleet-entity-settings-layout',
  templateUrl: './entity-settings-layout.component.html',
  styles: [
    `
      router-outlet {
        display: none;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitySettingsLayoutComponent implements OnInit {
  @Input() showBackButton = true;
  @Input() navigationMenu: any[] = [];
  title: string;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean;
  currentMediaAlias: string[];
  @Input() entityName: string;
  @Input() customURL: string = null;
  @Input() withPadding = false;
  showHeader = true;
  isSmallScreen = false;
  @Input() hideDrawer = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    protected entityDetailLayoutService: EntityDetailLayoutService
  ) {
    combineLatest([
      this.entityDetailLayoutService.drawOpened$,
      this._fuseMediaWatcherService.onMediaChange$,
    ])
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: ([opened, media]: [boolean, any]) => {
          this.drawerOpened = opened;

          this.entityDetailLayoutService.setShowMenuButton(
            media.matchingAliases.length === 0 && this.drawerOpened === false
          );

          this._changeDetectorRef.markForCheck();
        },
      });
  }

  ngOnInit(): void {
    const routeData = this._activatedRoute.snapshot.firstChild.data;
    this.title = routeData.title;
    this.showHeader = routeData.showHeader;
    if (routeData.withEntityLayoutPadding === false) {
      this.withPadding = false;
    } else {
      this.withPadding = true;
    }

    this._changeDetectorRef.markForCheck();

    this._router.events
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const routeData = this._activatedRoute.snapshot.firstChild.data;
          this.title = routeData.title;
          this.showHeader = routeData.showHeader;
          if (routeData.withEntityLayoutPadding === false) {
            this.withPadding = false;
          } else {
            this.withPadding = true;
          }
          if (this.drawerMode === 'over') {
            this.entityDetailLayoutService.setDrawOpened(false);
            // this.drawerOpened = false;
          }
          this._changeDetectorRef.markForCheck();
        }
      });

    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((media: any) => {
        this.currentMediaAlias = media.matchingAliases;
        // Set the drawerMode and drawerOpened if
        if (media.matchingAliases.includes('md')) {
          this.drawerMode = 'side';
          this.entityDetailLayoutService.setDrawOpened(true);
        } else {
          this.drawerMode = 'over';

          // this.drawerOpened = false;
          this.entityDetailLayoutService.setDrawOpened(false);
        }
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  closeDrawer() {
    this.entityDetailLayoutService.setDrawOpened(false);
    this._changeDetectorRef.markForCheck();
  }

  toggleDrawer() {
    this.entityDetailLayoutService.toggleDraw();
  }
}
