/* eslint-disable @typescript-eslint/no-empty-function */
import { Inject, Injectable } from '@angular/core';
import {
  AuthApiService,
  ValidationApiService,
  VerificationApiService,
} from '@fleet/api';

import {
  FunctionPermissionModel,
  LoginModel,
  NetworkGroupModel,
  NetworkModel,
  VerificationModel,
  MultiFactorAuthenticationModel,
} from '@fleet/model';
import { decodeToken, handleApiError } from '@fleet/utilities';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';

import { DateTime } from 'luxon';

import { HttpClient } from '@angular/common/http';
import { Params, Router } from '@angular/router';
import { paths } from '@fleet/environment';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import {
  GetUser,
  UpdateVerificationPartial,
} from '../+state/auth/user-auth.actions';
import { preserveAndClearKeys } from './local-storage-manager';
import { allowedTravellerFunctionsForNow } from './traveller-allowed-security-functions';

@Injectable({
  providedIn: 'root',
})
export abstract class AuthService {
  user$: BehaviorSubject<any> = new BehaviorSubject(null);
  registered: boolean = null;
  groupAccess: BehaviorSubject<NetworkGroupModel[]> = new BehaviorSubject(null);
  decodedToken: any;
  network: NetworkModel;
  networkHost: string;
  authHost: string;
  permissions: BehaviorSubject<FunctionPermissionModel[]> = new BehaviorSubject(
    []
  );
  authUserReady$: Observable<boolean>;
  fleetProduct: string;

  constructor(
    private authApiService: AuthApiService,
    private verificationApiService: VerificationApiService,
    private router: Router,
    protected validationApiService: ValidationApiService,
    private store: Store<any>,
    protected http: HttpClient,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
    this.authHost = env.host + paths.auth;
    this.authUserReady$ = combineLatest([
      this.permissions.asObservable(),
      this.user$.asObservable(),
    ]).pipe(
      map(([permissions, user]) => {
        if (permissions.length > 0 && user) {
          return true;
        }
        return false;
      })
    );
  }

  get permissions$() {
    return this.permissions.asObservable();
  }

  set accessToken(token: string) {
    if (token && token !== 'null') {
      try {
        localStorage.setItem('access_token', token);
        this.decodedToken = decodeToken(token);
        if (this.networkId && this.decodedToken.type === 'NETWORK') {
          setTimeout(() => {
            this.getNetwork(this.networkId);
          }, 100);
        }
      } catch (error: any) {
        this.logout();
      }
    } else {
      this.logout();
    }
  }

  get accessToken(): string {
    return localStorage.getItem('access_token') ?? '';
  }

  get userValue() {
    return this.user$.value;
  }

  get networkId(): string {
    return this.decodedToken ? this.decodedToken.networkId : null;
  }

  get userType(): string {
    return this.decodedToken ? this.decodedToken.type : null;
  }

  get organisationUserId(): string {
    return this.decodedToken ? this.decodedToken.organisationUserId : null;
  }

  get networkUserId(): string {
    return this.decodedToken ? this.decodedToken.networkUserId : null;
  }

  get driverId(): string {
    return this.decodedToken ? this.decodedToken.driverId : null;
  }

  protected host: string;

  abstract signIn(login: LoginModel): Observable<any>;

  abstract getUser(payload: any): Observable<any>;

  abstract refreshToken(jwt: any): Observable<any>;

  abstract signup(payload: any): Observable<any>;

  abstract findUser(payload: any): Observable<any>;

  abstract usernameInUse(username: string): Observable<any>;

  abstract resetMFA?(payload?: any): Observable<any>;

  setPermissions(value: any) {
    this.permissions.next(value);
  }

  //BELOW ARE NOT ABSTRACT PLEASE USE AUTH API AND VERIFICAION API WHERE NESS

  changePassword(payload: any) {
    return this.authApiService.changePassword(payload);
  }

  changeUsername(payload: any): Observable<any> {
    return this.authApiService.changeUsername(payload);
  }

  changeMFA(payload: any, login: LoginModel): Observable<any> {
    return this.authApiService.changeMfa(payload, login);
  }

  forgotPassword(payload: any) {
    return this.authApiService.forgotPassword(payload);
  }

  resetPassword(payload: any) {
    return this.authApiService.resetPassword(payload);
  }

  verifyEmail(verification: VerificationModel) {
    return this.verificationApiService.verifyEmail(verification);
  }

  verifyEmailWithJwt(verification: VerificationModel) {
    return this.verificationApiService.verifyEmailWithJWT(verification);
  }

  getInvitation(activateKey: string) {
    return this.verificationApiService.getInvitation(activateKey);
  }

  sendEmailActivationCode(payload: any) {
    return this.verificationApiService.sendEmailActivationCode(payload);
  }

  verify(verification: VerificationModel): Observable<any> {
    return this.verificationApiService.verifyFullResponse(verification);
  }

  verifyPhoneNumber(payload: any, includeJwt: boolean) {
    return this.verificationApiService.verifyPhoneNumber(payload, includeJwt);
  }

  changePhoneNumber(payload: any) {
    return this.authApiService.changePhoneNumber(payload);
  }

  sendMFACode(payload: MultiFactorAuthenticationModel, login: LoginModel) {
    return this.verificationApiService.sendMFACode(payload, login);
  }

  samlLogin(
    loginModel: any,
    urlSearchParams: URLSearchParams
  ): Observable<any> {
    return this.authApiService.samlLogin(loginModel, urlSearchParams);
  }

  setUser(user: any) {
    this.user$.next(user);
    if (user) {
      let userId = '';
      if (user.networkUserId) {
        userId = 'networkUserId:' + user.networkUserId;
      }

      Sentry.setUser({ email: user.username });
    } else {
      Sentry.setUser(null);
    }
  }

  updateUserPartial(partialUpdate: any) {
    this.setUser(Object.assign({}, this.user$.value, partialUpdate));
  }

  getNetwork(networkId: string) {
    this.http
      .get(`${this.host}/${networkId}`)
      .pipe(catchError(handleApiError))
      .subscribe({
        next: (resp: any) => {
          this.network = resp.data;
        },
      });
  }

  getPermissions(): Observable<any> {
    return this.http
      .get(`${this.authHost}/permission`)
      .pipe(catchError(handleApiError));
  }

  userHasFunction(functionType: string, action: string) {
    if (
      this.fleetProduct === 'TRAVELLER' &&
      allowedTravellerFunctionsForNow.includes(functionType)
    ) {
      return true;
    } else {
      if (this.permissions.value) {
        //test permissions for presence
        const f = this.permissions.value.find(
          (f: any) => f.type == functionType
        ) as any;

        if (f) {
          if (action === 'any') {
            //just return the function
            return f;
          } else {
            //return true/false
            return f[action];
          }
        }
      }

      return false;
    }
  }

  clearToken() {
    localStorage.removeItem('access_token');
    this.decodedToken = null;
  }

  checkAuth(): Observable<any> {
    //check user
    if (this.user$.value) {
      return of(true);
    }
    //check token exists
    if (!this.accessToken) {
      return of(false);
    }
    //check token expired
    return this.checkToken().pipe(
      mergeMap((token: any) => {
        if (token) {
          if (this.decodedToken.type) {
            return this.getPermissions().pipe(
              take(1),
              mergeMap((resp: any) => {
                this.setPermissions(resp.data);
                this.store.dispatch(
                  new GetUser({
                    decodedJwt: this.decodedToken,
                    route: false,
                  })
                );
                return of(true);
              }),
              catchError((error) => {
                console.log('err');
                return of(false);
              })
            );
          } else {
            return of(true);
          }
        } else {
          return of(false);
        }
      })
    );
  }

  checkToken(): Observable<any> {
    // if the token needs to be refreshed, we will get a new one
    try {
      const decodedToken = decodeToken(this.accessToken);

      const decodedTime = DateTime.fromMillis(decodedToken.exp).toUTC();
      const now = DateTime.now().toUTC();
      const sessionTimeRemaining = decodedTime
        .diff(now)
        .shiftTo('minutes').minutes;

      if (sessionTimeRemaining > 0) {
        if (sessionTimeRemaining < 5) {
          return this.refreshToken(this.accessToken).pipe(
            map((token: any) => {
              if (token) {
                this.accessToken = token;
                return of(token);
              }
              return of(null);
            }),
            catchError((error) => {
              return of(null);
            })
          );
        }
        if (!this.decodedToken) {
          this.decodedToken = decodeToken(this.accessToken);
        }
        return of(this.accessToken);
      } else {
        return of(null);
      }
    } catch (error: any) {
      return of(null);
    }
  }

  isTokenExpired() {
    if (this.accessToken) {
      try {
        const decodedToken = decodeToken(this.accessToken);

        const decodedTime = DateTime.fromMillis(decodedToken.exp).toUTC();
        const now = DateTime.now().toUTC();

        const sessionTimeRemaining = decodedTime.diff(now).milliseconds;

        if (sessionTimeRemaining > 0) {
          return false;
        }
      } catch (error: any) {
        return true;
      }
    }
    return true;
  }

  updateVerificationTokenParital(partialUpdate: any) {
    this.store.dispatch(new UpdateVerificationPartial(partialUpdate));
  }

  logout(samlLoginOverideParams?: any) {
    preserveAndClearKeys();
    localStorage.removeItem('access_token');

    this.user$.next(null);
    if (samlLoginOverideParams) {
      this.router.navigate(['/auth/saml/login'], {
        queryParams: samlLoginOverideParams,
      });
    } else {
      this.router.navigate(['/auth/login']);
    }
  }
}
