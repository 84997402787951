import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { fuseAnimations } from '@fleet/fuse';
import { DriverModel, JobDriverModel } from '@fleet/model';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    return acc;
  },
  {}
);
@Component({
  selector: 'fleet-driver-selected-list-item',
  template: `
    <div
      class="bg-card rounded-md border p-4 mb-4 flex flex-col"
      tabindex="-1"
      *ngIf="driver?.driverId || jobDriver?.driverId"
    >
      <div class="flex flex-row gap-2 w-full items-center">
        <mat-icon class="icon-size-5 cursor-default">local_taxi</mat-icon>
        <div class="cursor-default">
          {{ driver ? driver.fullName : jobDriver.name }}
        </div>
        <div class="flex-grow"></div>

        <button
          #travellerMenuButton
          mat-icon-button
          [matMenuTriggerFor]="travellerMenu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

      <mat-menu #travellerMenu="matMenu">
        <button
          *ngFor="let action of actions"
          mat-menu-item
          (click)="actionItem.emit(action)"
        >
          <ng-container *transloco="let t">
            {{ t('driverSelectedListItem.' + action) }}
          </ng-container>
        </button>
      </mat-menu>
    </div>
  `,
  providers: [
    provideTranslocoScope({
      scope: 'driverSelectedListItem',
      loader: lazyTranslationloader,
    }),
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TitleCaseAndCleanPipeModule,
    MatMenuModule,
    TranslocoDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class DriverSelectedListItemComponent implements OnInit {
  @Input() jobDriver: JobDriverModel;
  @Input() driver: DriverModel;

  @Input() actions: string[];
  @Output() actionItem = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
