import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotAuthorisedComponent } from './not-authorised.component';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    return acc;
  },
  {}
);

@NgModule({
  declarations: [NotAuthorisedComponent],
  imports: [CommonModule, TranslocoDirective],
  exports: [NotAuthorisedComponent],
  providers: [
    provideTranslocoScope({
      scope: 'notAuthorised',
      loader: lazyTranslationloader,
    }),
  ],
})
export class NotAuthorisedModule {}
