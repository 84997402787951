import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthApiService,
  DriverApiService,
  ValidationApiService,
  VerificationApiService,
} from '@fleet/api';
import { AuthService } from './auth.service';
import { paths } from '@fleet/environment';
import { handleApiError } from '@fleet/utilities';
import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';
import { LoginModel } from '@fleet/model';

@Injectable({
  providedIn: 'root',
})
export class DriverAuthService extends AuthService {
  host: string;
  fleetProduct: string;
  nonAuthorisedHttp: HttpClient;

  constructor(
    private driverApiService: DriverApiService,
    http: HttpClient,
    private backend: HttpBackend,
    authApiService: AuthApiService,
    verificationApiService: VerificationApiService,
    validationApiService: ValidationApiService,
    store: Store<any>,

    @Inject('env') env: any,
    router: Router
  ) {
    super(
      authApiService,
      verificationApiService,
      router,
      validationApiService,
      store,
      http,
      env
    );
    this.nonAuthorisedHttp = new HttpClient(backend);
    this.host = env.host + paths.driver;

    this.fleetProduct = env.fleetProduct;
  }

  signIn(login: LoginModel) {
    const authdata = btoa(login.username + ':' + login.password);
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Basic ' + authdata,

      'fleet-product': this.fleetProduct,
      'fleet-channel': 'WEB',
    });

    return this.nonAuthorisedHttp
      .post(`${this.host}/signin`, null, {
        headers: headers,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  getUser(payload: any): Observable<any> {
    return this.driverApiService.getDriver(payload.driverId);
  }
  refreshToken(jwt: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + jwt,

      'fleet-product': this.fleetProduct,
      'fleet-channel': 'WEB',
    });
    return this.nonAuthorisedHttp
      .get<string>(`${this.host}/refreshToken`, {
        headers: headers,
        observe: 'response',
      })
      .pipe(
        map((resp: any) => {
          const authHeader = resp.headers.get('Authorization');
          const jwt = authHeader.replace('Bearer ', '');
          return jwt;
        }),
        catchError((error) => of(null))
      );
  }
  signup(payload: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'fleet-product': this.fleetProduct,
      'fleet-channel': 'WEB',
    });
    return this.http
      .post(`${this.host}/signup`, payload, {
        headers: headers,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  findUser(payload: any): Observable<any> {
    return this.validationApiService.driverLookup(payload);
  }

  usernameInUse(username: string): Observable<any> {
    return this.validationApiService.driverUsernameInUse(username);
  }

  resetMFA(payload: any): Observable<any> {
    return this.driverApiService.resetMFA(payload.driverId);
  }
}
