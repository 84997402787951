import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { CreditCardPipeModule } from '@fleet/pipes';
import { JobTravellerProfileSelectorComponent } from './job-traveller-profile-selector.component';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [JobTravellerProfileSelectorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    CreditCardPipeModule,
    TranslocoDirective,
  ],

  providers: [
    provideTranslocoScope({
      scope: 'travellerProfileSelector',
      loader: lazyTranslationloader,
    }),
  ],
  exports: [JobTravellerProfileSelectorComponent],
})
export class JobTravellerProfileSelectorModule {}
