import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TicketApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  DriverModel,
  IssueModel,
  JobModel,
  OperatorModel,
  OperatorUserModel,
  OrganisationModel,
  OrganisationUserModel,
  TicketModel,
  TravellerModel,
  VehicleModel,
} from '@fleet/model';
import { DialogLayoutComponent } from '@fleet/ui';
import { BehaviorSubject } from 'rxjs';

export interface TicketLinkState {
  loading: boolean;
  issues: IssueModel[];
  buttonLabel: string;
  title: string;
  instructions: string;
}

@Component({
  selector: 'fleet-ticket-link',
  templateUrl: './ticket-link.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class TicketLinkComponent
  extends DialogLayoutComponent
  implements OnInit
{
  DEFAULT_STATE: TicketLinkState = {
    loading: false,
    issues: [],
    buttonLabel: 'Link',
    title: 'Link Ticket',
    instructions: 'Link the following to this ticket:',
  };

  ticketLinkState: BehaviorSubject<TicketLinkState> = new BehaviorSubject(
    this.DEFAULT_STATE
  );

  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('driverId').patchValue(value.driverId);

      this.ticketLinkState.next({
        ...this.ticketLinkState.value,
        instructions: `Link a ticket for ${value.firstName} ${value.lastName}`,
      });
    }
  }
  get driver() {
    return this._driver;
  }

  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('travellerId').patchValue(value.travellerId);

      this.ticketLinkState.next({
        ...this.ticketLinkState.value,
        instructions: `Link a ticket for ${value.firstName} ${value.lastName}`,
      });
    }
  }

  get traveller() {
    return this._traveller;
  }

  _vehicle: VehicleModel;
  @Input() set vehicle(value: VehicleModel) {
    this._vehicle = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('vehicleId').patchValue(value.vehicleId);
      this.ticketLinkState.next({
        ...this.ticketLinkState.value,
        instructions: `Link a ticket for ${value.displayName}`,
      });
    }
  }
  get vehicle() {
    return this._vehicle;
  }

  _job: JobModel;
  @Input() set job(value: JobModel) {
    this._job = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('jobId').patchValue(value.jobId);

      if (value.driver) {
        this.form.get('driverId').patchValue(value.driver.driverId);
        this.form.get('linkDriver').patchValue(true);
      }
      if (value.vehicle) {
        this.form.get('vehicleId').patchValue(value.vehicle.vehicleId);
        this.form.get('linkVehicle').patchValue(true);
      }
      if (value.traveller) {
        this.form.get('travellerId').patchValue(value.traveller.travellerId);
        this.form.get('linkTraveller').patchValue(true);
      }

      if (value.organisation) {
        this.form
          .get('organisationId')
          .patchValue(value.organisation.organisationId);
        this.form.get('linkOrganisation').patchValue(true);
      }
      if (value.organisation && value.organisation.organisationUserId) {
        this.form
          .get('organisationId')
          .patchValue(value.organisation.organisationId);
        this.form.get('linkOrganisationUser').patchValue(true);
      }

      if (!this.ticket) {
        this.form.get('externalTicket').setValidators([Validators.required]);
      }
    }
  }

  get job() {
    return this._job;
  }

  _organisation: OrganisationModel;
  @Input() set organisation(value: OrganisationModel) {
    this._organisation = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('organisationId').patchValue(value.organisationId);

      this.ticketLinkState.next({
        ...this.ticketLinkState.value,
        instructions: `Link a ticket for ${value.name}`,
      });
    }
  }
  get organisation() {
    return this._organisation;
  }

  _operator: OperatorModel;
  @Input() set operator(value: OperatorModel) {
    this._operator = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('operatorId').patchValue(value.operatorId);

      this.ticketLinkState.next({
        ...this.ticketLinkState.value,
        instructions: `Link a ticket for ${value.displayName}`,
      });
    }
  }
  get operator() {
    return this._operator;
  }

  _organisationUser: OrganisationUserModel;
  @Input() set organisationUser(value: OrganisationUserModel) {
    this._organisationUser = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('organisationUserId').patchValue(value.organisationUserId);

      this.ticketLinkState.next({
        ...this.ticketLinkState.value,
        instructions: `Link a ticket for ${value.username}`,
      });
    }
  }
  get organisationUser() {
    return this._organisationUser;
  }

  _operatorUser: OperatorUserModel;
  @Input() set operatorUser(value: OperatorUserModel) {
    this._operatorUser = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('operatorUserId').patchValue(value.operatorUserId);

      this.ticketLinkState.next({
        ...this.ticketLinkState.value,
        instructions: `Link a ticket for ${value.username}`,
      });
    }
  }
  get operatorUser() {
    return this._operatorUser;
  }

  _ticket: TicketModel;
  @Input() set ticket(value: TicketModel) {
    this._ticket = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.form.get('externalTicket').patchValue(value);
    }
  }
  get ticket() {
    return this._ticket;
  }

  form: UntypedFormGroup;

  @Output() successful = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private ticketApiService: TicketApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.form) {
      this.buildForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      driverId: [],
      travellerId: [],
      vehicleId: [],
      jobId: [],
      transactionReferenceNumber: [],
      organisationId: [],
      organisationUserId: [],
      linkDriver: [],
      linkTraveller: [],
      linkVehicle: [],
      linkOrganisation: [],
      linkOrganisationUser: [],
      linkPayment: [],
      externalTicket: [null, [Validators.required]],
    });
  }

  linkTicket() {
    this.ticketLinkState.next({
      ...this.ticketLinkState.value,
      loading: true,
      issues: [],
    });
    this.changeDetectorRef.markForCheck();

    let payload;

    if (!this.ticket) {
      payload = Object.assign({}, this.form.value.externalTicket);
      if (this.job) {
        payload['jobId'] = this.job.jobId;
      }
      if (this.driver) {
        payload['driverId'] = this.driver.driverId;
      }
      if (this.traveller) {
        payload['travellerId'] = this.traveller.travellerId;
      }
      if (this.vehicle) {
        payload['vehicleId'] = this.vehicle.vehicleId;
      }

      if (this.organisation) {
        payload['organisationId'] = this.organisation.organisationId;
      }

      if (this.organisationUser) {
        payload['organisationUserId'] =
          this.organisationUser.organisationUserId;
      }
    } else {
      payload = Object.assign({}, this.ticket, this.form.value);

      if (this.job && !this.form.value.linkDriver) {
        delete payload['driverId'];
      }
      if (this.job && !this.form.value.linkTraveller) {
        delete payload['travellerId'];
      }
      if (this.job && !this.form.value.linkVehicle) {
        delete payload['vehicleId'];
      }
      if (this.job && !this.form.value.linkPayment) {
        delete payload['transactionReferenceNumber'];
      }

      if (this.job && !this.form.value.linkOrganisation) {
        delete payload['organisationId'];
      }

      if (this.job && !this.form.value.linkOrganisation) {
        delete payload['organisationUserId'];
      }
    }

    delete payload['externalTicket'];
    delete payload['linkDriver'];
    delete payload['linkVehicle'];
    delete payload['linkTraveller'];
    delete payload['linkPayment'];
    delete payload['linkOrganisation'];
    delete payload['linkOrganisationUser'];

    this.ticketApiService.updateTicket(payload?.ticketId, payload).subscribe({
      next: (resp: ApiResponse<TicketModel>) => {
        this.ticketLinkState.next({
          ...this.ticketLinkState.value,
          loading: false,
        });
        this.successful.emit(resp.data);
        this.changeDetectorRef.markForCheck();
      },
      error: (error: IssueModel[]) => {
        this.ticketLinkState.next({
          ...this.ticketLinkState.value,
          loading: false,
          issues: error,
        });

        this.changeDetectorRef.markForCheck();
      },
    });
  }

  setError(issues: IssueModel[]) {
    this.ticketLinkState.next({
      ...this.ticketLinkState.value,
      loading: false,
      issues: issues,
    });

    this.changeDetectorRef.markForCheck();
  }
}
