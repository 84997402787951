<fuse-alert
  *ngFor="let alert of issues | alertsFromIssues"
  [appearance]="'outline'"
  [showIcon]="true"
  [type]="alert.type"
  [@shake]="alert.type === 'error'"
>
  {{ alert.message }}
</fuse-alert>
<mat-form-field class="w-full">
  <mat-label *ngIf="label" [class]="labelClass ? labelClass : ''">{{
    label
  }}</mat-label>
  <input
    autocomplete="off"
    data-lpignore="true"
    data-form-type="other"
    #input
    matInput
    [placeholder]="placeHolder"
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    (click)="inputClick($event.target)"
  />
  <mat-icon matPrefix *ngIf="prefixIcon">{{ prefixIcon }} </mat-icon>
  <mat-spinner matSuffix diameter="18" *ngIf="searching"></mat-spinner>
  <mat-error *ngIf="searchControl?.errors?.required">
    This field is required
  </mat-error>
  <mat-error
    *ngIf="
      searchControl?.errors?.userNotSelected && !searchControl?.errors?.required
    "
    >{{ searchControl?.errors?.userNotSelected }}</mat-error
  >
</mat-form-field>

<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayFn.bind(this)"
  (optionSelected)="selectUser($event)"
>
  <ng-container *ngIf="users$ | async as search">
    <mat-option *ngFor="let user of search" [value]="user">
      <span>{{ user.firstName + ' ' + user.lastName }}</span>
    </mat-option>
    <mat-option *ngIf="search.length === 0 && searchComplete" [disabled]="true"
      >No results found</mat-option
    >
  </ng-container>
</mat-autocomplete>
