import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validator,
  Validators,
} from '@angular/forms';
import { ServiceApiService } from '@fleet/api';
import {
  ApiResponse,
  ServiceClassModel,
  ServiceClassWithLines,
  ServiceLineModel,
} from '@fleet/model';
import { hasRequiredValidator } from '@fleet/utilities';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'fleet-service-class-and-line-selector',
  templateUrl: './service-class-and-line-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServiceClassAndLineSelectorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ServiceClassAndLineSelectorComponent),
      multi: true,
    },
  ],
})
export class ServiceClassAndLineSelectorComponent
  implements OnInit, ControlValueAccessor, OnDestroy, Validator, AfterViewInit
{
  @Input() defaultLineType: string = null;
  @Input() label: string;
  @Input() style: 'SINGLE' | 'BOTH' = 'BOTH';
  @Input() viewMode: 'SELECT' | 'CUSTOM_SELECT' = 'CUSTOM_SELECT';
  @Input() isStacking = false;
  @Input() singleSelection = false;
  @Input() excludeLineTypes: any[] = [];
  private _allowedServiceClassesAndLines: [
    {
      serviceClass: string;
      serviceLines: string[];
    }
  ];

  @Input()
  set allowedServiceClassesAndLines(
    value: [
      {
        serviceClass: string;
        serviceLines: string[];
      }
    ]
  ) {
    this._allowedServiceClassesAndLines = value;

    if (value) {
      //search has happened ALREADY, we need to filter it from here

      const currentServiceClassesWithLines = this.serviceClassesWithLines.value;

      if (currentServiceClassesWithLines.length > 0) {
        const filteredServiceClassesWithLines =
          currentServiceClassesWithLines.map((c) => {
            let allowedServiceClass: any;
            if (
              this._allowedServiceClassesAndLines &&
              this._allowedServiceClassesAndLines.length > 0
            ) {
              allowedServiceClass = this._allowedServiceClassesAndLines.find(
                (allowed) => allowed.serviceClass === c.class.type
              );
            }

            const filteredLines = c.lines.filter((line) => {
              const isCorrectServiceClass =
                line.serviceClassId === c.class.serviceClassId;
              const isExcluded =
                this.excludeLineTypes.length > 0 &&
                !this.excludeLineTypes.includes(line.type);
              const isAllowed =
                !allowedServiceClass ||
                allowedServiceClass.serviceLines.includes(line.type);

              return isCorrectServiceClass && !isExcluded && isAllowed;
            });

            return {
              class: c.class,
              lines: filteredLines.map((line) => ({
                ...line,
                serviceClassType: c.class.type,
              })),
            };
          });

        this.serviceClassesWithLines.next(filteredServiceClassesWithLines);
        this.updateControlsWithFetchedData(filteredServiceClassesWithLines);
        this.serviceClassAndLinesLoaded.emit(filteredServiceClassesWithLines);
      }
      //if not, set this, and when the search happens later it will filter for us.
    }
  }

  get allowedServiceClassesAndLines(): [
    {
      serviceClass: string;
      serviceLines: string[];
    }
  ] {
    return this._allowedServiceClassesAndLines;
  }
  @Input() readOnly = false;
  @Input() required = false;
  @Input() includeNoneOption = false;
  @Input() inSearchForm = false;

  @Output() serviceClassAndLinesLoaded = new EventEmitter();

  private _unsubscribeAll: Subject<any> = new Subject();

  serviceClassesWithLines: BehaviorSubject<ServiceClassWithLines[]> =
    new BehaviorSubject([]);

  selectedLine: ServiceLineModel;
  expanded = false;
  serviceClassControl = new FormControl();
  serviceLineControl = new FormControl();
  singleClassLineControl = new FormControl();
  hostFocused = false;

  _params: any;
  @Input() set params(value: any) {
    const previousResult = JSON.stringify(this._params);
    this._params = value;
    if (value && previousResult !== JSON.stringify(value)) {
      this.searchServiceClasses(value);
    }
  }

  get params() {
    return this._params;
  }

  public ngControl: NgControl;

  constructor(
    private serviceApi: ServiceApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.ngControl = this.injector.get(NgControl);
      if (this.ngControl?.control != null) {
        this.setValidatorsAndSubscribeToStatusChanges();
      }
    });
  }

  ngAfterViewInit(): void {
    this.registerControlValueChanges();
    this.serviceClassesWithLines
      .pipe(filter((results: any[]) => results && results.length > 0))
      .subscribe((results: any[]) => {
        let x = this.serviceLineControl.value;
        let u = this.singleClassLineControl.value;

        if (
          (((!this.singleClassLineControl.value ||
            (this.singleClassLineControl.value &&
              !this.singleClassLineControl.value.serviceLineId)) &&
            this.style === 'SINGLE') ||
            (!this.serviceClassControl.value && this.style === 'BOTH')) &&
          results.length > 0
        ) {
          if (this.defaultLineType) {
            //For defaulting to a booking line here
            const defaultLine = results
              .map((pair: any) => pair.lines)
              .reduce((acc: any, val: any) => acc.concat(val), [])
              .find(
                (serviceLine: any) => serviceLine.type === this.defaultLineType
              );
            if (defaultLine) {
              if (this.style === 'SINGLE') {
                this.singleClassLineControl.setValue(defaultLine);
              } else {
                this.serviceLineControl.setValue(defaultLine);
              }
            }
            this.onChange(
              defaultLine
                ? {
                    serviceClassId: defaultLine.serviceClassId,
                    serviceLineId: defaultLine.serviceLineId,
                    serviceLine: defaultLine.type,
                    serviceClassType: defaultLine.serviceClassType,
                  }
                : null
            );
            this.onTouched();
          }
        } else {
          this.onChange(
            this.singleClassLineControl.value &&
              this.singleClassLineControl.value.serviceLineId
              ? {
                  serviceClassId:
                    this.singleClassLineControl.value.serviceClassId,
                  serviceLineId:
                    this.singleClassLineControl.value.serviceLineId,
                  serviceLine: this.singleClassLineControl.value.type,
                  serviceClassType:
                    this.singleClassLineControl.value.serviceClassType,
                }
              : null
          );
          this.onTouched();
        }

        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnInit(): void {}

  private setValidatorsAndSubscribeToStatusChanges(): void {
    const control = this.ngControl.control;
    if (hasRequiredValidator(this.ngControl.control)) {
      const validators = control.validator ? [control.validator] : [];

      if (this.style === 'SINGLE') {
        this.singleClassLineControl.setValidators(validators);
      } else {
        this.serviceClassControl.setValidators(validators);
        this.serviceLineControl.setValidators(validators);
      }

      this.updateValueAndValidity();
    }

    control.statusChanges.subscribe(() => {
      // Reapply the validators from the ngControl to ensure they are still applied

      if (hasRequiredValidator(this.ngControl.control)) {
        const validators = control.validator ? [control.validator] : [];

        if (this.style === 'SINGLE') {
          this.singleClassLineControl.setValidators(validators);
        } else {
          this.serviceClassControl.setValidators([Validators.required]);
          this.serviceLineControl.setValidators([Validators.required]);
        }
      }

      this.updateValueAndValidity();
      this.markControlsAsTouched();
      this.changeDetectorRef.markForCheck();
    });
  }

  private updateValueAndValidity(): void {
    if (this.style === 'SINGLE') {
      this.singleClassLineControl.updateValueAndValidity({
        emitEvent: false,
        onlySelf: true,
      });
    } else {
      this.serviceClassControl.updateValueAndValidity({
        emitEvent: false,
        onlySelf: true,
      });
      this.serviceLineControl.updateValueAndValidity({
        emitEvent: false,
        onlySelf: true,
      });
    }
  }

  private markControlsAsTouched(): void {
    if (this.ngControl.control.touched) {
      if (this.style === 'SINGLE') {
        this.singleClassLineControl.markAsTouched();
      } else {
        this.serviceLineControl.markAsTouched();
        this.serviceClassControl.markAsTouched();
      }
    }
  }

  private registerControlValueChanges(): void {
    if (this.style === 'SINGLE') {
      this.registerSingleControlValueChanges();
    } else {
      this.registerPairedControlValueChanges();
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  writeValue(
    service: { serviceClassId: string; serviceLineId: string } | string
  ): void {
    let x = this.serviceLineControl.value;
    let y = this.singleClassLineControl.value;
    if (typeof service === 'string') {
      this.handleStringValue(service, false);
    } else if (service && this.style === 'BOTH') {
      this.handleBothStyleValue(service, false);
    } else if (service && this.style === 'SINGLE') {
      this.handleSingleStyleValue(service, false);
    } else {
      if (this.style === 'BOTH') {
        this.handleBothStyleValue(null, false);
      } else {
        this.handleSingleStyleValue(null, false);
      }
    }

    this.serviceClassesWithLines.next([
      ...this.serviceClassesWithLines.getValue(),
    ]);

    this.changeDetectorRef.markForCheck();
  }

  private handleStringValue(service: string, emitEvent: boolean): void {
    if (this.style === 'SINGLE') {
      const foundServiceLine = this.findServiceLineById(service);
      this.singleClassLineControl.setValue(
        foundServiceLine || { serviceLineId: service },
        { emitEvent: emitEvent }
      );

      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

  private handleBothStyleValue(
    service: {
      serviceClassId: string;
      serviceLineId: string;
    },
    emitEvent: boolean
  ): void {
    this.setServiceClassControlValue(service, emitEvent);
    this.setServiceLineControlValue(service, emitEvent);
  }

  private handleSingleStyleValue(
    service: {
      serviceClassId: string;
      serviceLineId: string;
    },
    emitEvent: boolean
  ): void {
    if (service) {
      const matchingLine: any = this.serviceClassesWithLines
        .getValue()
        .map((scWithLines) => scWithLines.lines)
        .reduce((acc, lines) => acc.concat(lines), [])
        .find((line) => line.serviceLineId === service.serviceLineId);

      this.singleClassLineControl.setValue(
        matchingLine ? matchingLine : service,
        {
          emitEvent: emitEvent,
        }
      );

      if (matchingLine) {
        this.selectedLine = matchingLine;
      }
    } else {
      this.singleClassLineControl.setValue(null, { emitEvent: emitEvent });
      this.selectedLine = null;
    }
  }

  private setServiceLineControlValue(
    service: {
      serviceClassId: string;
      serviceLineId: string;
    },
    emitEvent: boolean
  ): void {
    if (service && service.serviceLineId) {
      let value = this.singleSelection
        ? { serviceLineId: service.serviceLineId }
        : service.serviceLineId.split(',').map((s) => ({ serviceLineId: s }));
      const allServiceLines = this.serviceClassesWithLines
        .getValue()
        .map((pair) => pair.lines)
        .reduce((acc, val) => acc.concat(val), []);

      if (Array.isArray(value)) {
        value = value.map(
          (val) =>
            allServiceLines.find(
              (line) => line.serviceLineId === val.serviceLineId
            ) || val
        );
      } else {
        value =
          allServiceLines.find(
            (line) => line.serviceLineId === (value as any)?.serviceLineId
          ) || value;
      }

      this.serviceLineControl.setValue(value, { emitEvent: emitEvent });
    } else {
      this.serviceLineControl.setValue(null, { emitEvent: emitEvent });
    }
  }

  private setServiceClassControlValue(
    service: {
      serviceClassId: string;
      serviceLineId: string;
    },
    emitEvent: boolean
  ): void {
    if (service) {
      const foundServiceClass = this.serviceClassesWithLines.value.find(
        (scWithLines) =>
          scWithLines.class.serviceClassId === service.serviceClassId
      )?.class;
      this.serviceClassControl.setValue(
        foundServiceClass || { serviceClassId: service.serviceClassId },
        { emitEvent: emitEvent }
      );
    } else {
      this.serviceClassControl.setValue(null, { emitEvent: emitEvent });
    }
  }

  private findServiceLineById(serviceLineId: string): any {
    return this.serviceClassesWithLines
      .getValue()
      .map((pair) => pair.lines)
      .reduce((acc, val) => acc.concat(val), [])
      .find((serviceLine) => serviceLine.serviceLineId === serviceLineId);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  validate(control: FormControl) {
    const isSingleStyle = this.style === 'SINGLE';
    const isBothStyle = this.style === 'BOTH';
    const hasSingleSelection = this.singleSelection;
    const hasServiceClassValue = !!this.serviceClassControl.value;
    const hasServiceLineValue = !!this.serviceLineControl.value;
    const isServiceLineValueArrayEmpty =
      Array.isArray(this.serviceLineControl.value) &&
      this.serviceLineControl.value.length === 0;
    const hasSingleClassLineValue = !!this.singleClassLineControl.value;
    const isServiceClassControlValid = this.serviceClassControl.valid;
    const isServiceLineControlValid = this.serviceLineControl.valid;

    if (!hasSingleSelection) {
      if (
        isBothStyle &&
        ((!hasServiceClassValue && !isServiceClassControlValid) ||
          ((!hasServiceLineValue || isServiceLineValueArrayEmpty) &&
            !isServiceLineControlValid))
      ) {
        return { message: 'Service Class/Line has not been selected' };
      }
      if (isSingleStyle && !hasSingleClassLineValue) {
        return { message: 'Service Class/Line has not been selected' };
      }
    } else if (
      (isSingleStyle && hasSingleSelection && hasSingleClassLineValue) ||
      (isBothStyle &&
        hasSingleSelection &&
        hasServiceLineValue &&
        !isServiceLineValueArrayEmpty)
    ) {
      return null;
    } else {
      return { message: 'Service Class/Line has not been selected' };
    }
    return null;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.serviceClassControl.disable({ emitEvent: false })
      : this.serviceClassControl.enable({ emitEvent: false });

    isDisabled
      ? this.serviceLineControl.disable({ emitEvent: false })
      : this.serviceLineControl.enable({ emitEvent: false });
  }

  searchServiceClasses(payload: any) {
    this.serviceApi.searchServiceClasses(payload).subscribe({
      next: (resp: ApiResponse<ServiceClassModel[]>) => {
        if (resp.data.length === 0) {
          this.serviceClassControl.setErrors({
            noServiceClasses: true,
          });
          this.serviceClassControl.markAllAsTouched();
        }

        this.changeDetectorRef.markForCheck();

        this.fetchServiceLinesByClasses(resp.data);
      },
    });
  }

  fetchServiceLinesByClasses(classes: ServiceClassModel[]) {
    forkJoin(
      classes.map((serviceClass: ServiceClassModel) =>
        this.serviceApi.searchServiceLines(serviceClass.serviceClassId, {})
      )
    )
      .pipe(
        map((responses: ApiResponse<ServiceLineModel[]>[]) =>
          responses
            .map((resp: ApiResponse<ServiceLineModel[]>) => resp.data)
            .flat()
        ),
        tap((lines: ServiceLineModel[]) => {
          let serviceClassesWithLines;

          // const x = this.allowedServiceClassesAndLines;

          if (this.allowedServiceClassesAndLines) {
            serviceClassesWithLines = classes.map((c) => {
              let allowedServiceClass: any;
              if (
                this.allowedServiceClassesAndLines &&
                this.allowedServiceClassesAndLines.length > 0
              ) {
                allowedServiceClass = this.allowedServiceClassesAndLines.find(
                  (allowed) => allowed.serviceClass === c.type
                );
              }

              const filteredLines = lines.filter((line) => {
                const isCorrectServiceClass =
                  line.serviceClassId === c.serviceClassId;
                // const isNotRankHail = line.type !== 'RANK_HAIL';
                const isExcluded =
                  this.excludeLineTypes.length > 0 &&
                  !this.excludeLineTypes.includes(line.type);

                const isAllowed =
                  !allowedServiceClass ||
                  allowedServiceClass.serviceLines.includes(line.type);

                return isCorrectServiceClass && !isExcluded && isAllowed;
              });

              return {
                class: c,
                lines: filteredLines.map((line) => ({
                  ...line,
                  serviceClassType: c.type, // Add serviceClassType from the service class
                })),
              };
            });
          } else {
            serviceClassesWithLines = classes.map((c) => ({
              class: c,
              lines: lines
                .filter(
                  (line) =>
                    line.serviceClassId === c.serviceClassId &&
                    !this.excludeLineTypes.includes(line.type)
                )
                .map((line) => ({
                  ...line,
                  serviceClassType: c.type, // Add serviceClassType from the service class
                })),
            }));
          }

          this.serviceClassesWithLines.next(serviceClassesWithLines);

          this.serviceClassAndLinesLoaded.emit(serviceClassesWithLines);

          this.updateControlsWithFetchedData(serviceClassesWithLines);
        })
      )
      .subscribe({
        next: () => this.changeDetectorRef.markForCheck(),
      });
  }

  private updateControlsWithFetchedData(serviceClassesWithLines: any[]) {
    if (this.serviceClassControl.value) {
      const serviceAndLine = serviceClassesWithLines.find(
        (s) =>
          s.class.serviceClassId ===
          this.serviceClassControl.value.serviceClassId
      );
      this.serviceClassControl.setValue(serviceAndLine.class);
    }

    if (this.style === 'SINGLE') {
      this.updateSingleStyleControl(serviceClassesWithLines);
    } else if (
      this.style === 'BOTH' &&
      !serviceClassesWithLines.flatMap((sc) => sc.lines).length
    ) {
      this.serviceLineControl.setErrors({ noServiceLines: true });
      this.serviceLineControl.markAllAsTouched();
    }
  }

  private updateSingleStyleControl(serviceClassesWithLines: any[]) {
    const foundServiceLine = serviceClassesWithLines
      .flatMap((scWithLines) => scWithLines.lines)
      .find(
        (serviceLine) =>
          serviceLine.serviceLineId === this.getSingleControlValue()
      );

    this.singleClassLineControl.setValue(
      foundServiceLine || { serviceLineId: this.getSingleControlValue() },
      { emitEvent: true, onlySelf: true }
    );
  }

  private getSingleControlValue(): string {
    return this.singleClassLineControl.value &&
      (this.singleClassLineControl.value as any)?.serviceLineId
      ? (this.singleClassLineControl.value as any)?.serviceLineId
      : this.singleClassLineControl.value;
  }

  toggleList() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.singleClassLineControl.reset();
    }
    this.changeDetectorRef.detectChanges();
    this.changeDetectorRef.markForCheck();
  }

  lineSelected(serviceLine: ServiceLineModel) {
    this.singleClassLineControl.setValue(serviceLine);
    this.expanded = false;
    this.changeDetectorRef.detectChanges();
    this.changeDetectorRef.markForCheck();
  }

  registerSingleControlValueChanges() {
    this.singleClassLineControl.valueChanges
      .pipe(
        tap((line: any) => {
          this.selectedLine = line;
        }),
        distinctUntilChanged((prev, curr) => {
          return prev && curr && prev.serviceLineId === curr.serviceLineId;
        })
      )
      .subscribe({
        next: (line: any) => {
          if (line && line.serviceLineId) {
            this.onChange({
              serviceLineId: line.serviceLineId,
              serviceClassId: line.serviceClassId,
              serviceLine: line.type,
              serviceClassType:
                this.serviceClassesWithLines
                  .getValue()
                  .find((sc) => sc.class.serviceClassId === line.serviceClassId)
                  ?.class.type || null,
            });
            this.onTouched();
          }

          if (!line || (line && !line.serviceLineId)) {
            this.onChange(null);
            this.onTouched();
          }
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  registerPairedControlValueChanges() {
    this.serviceLineControl.valueChanges.subscribe((value: any) => {
      this.handleServiceLineValueChanges(value);
      this.changeDetectorRef.markForCheck();
    });

    this.serviceClassControl.valueChanges.subscribe(
      (serviceClass: ServiceClassModel) => {
        this.handleServiceClassValueChanges(serviceClass);
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  private handleServiceLineValueChanges(value: any) {
    if (value) {
      const changeObject = {
        serviceClassId: this.serviceClassControl.value.serviceClassId,

        serviceLineId: this.singleSelection
          ? value.serviceLineId
          : value.map((s: any) => s.serviceLineId).join(','),
        serviceLine: value.type,
        serviceClassType: this.serviceClassControl.value.serviceClassType
          ? this.serviceClassControl.value.serviceClassType
          : null,
      };

      this.onChange(changeObject);
    } else {
      this.onChange({
        serviceClassId: this.serviceClassControl.value
          ? this.serviceClassControl.value.serviceClassId
          : null,
        serviceClassType: this.serviceClassControl.value.serviceClassType
          ? this.serviceClassControl.value.serviceClassType
          : null,
        serviceLineId: null,
        serviceLine: null,
      });
    }
    this.onTouched();
  }

  private handleServiceClassValueChanges(serviceClass: ServiceClassModel) {
    if (serviceClass) {
      const changeObject = {
        serviceClassId: this.serviceClassControl.value.serviceClassId,
        serviceLineId: null,
      } as any;

      this.onChange(changeObject);
      this.onTouched();

      const serviceClassLines = this.getServiceClassLines(
        serviceClass.serviceClassId
      );
      if (serviceClassLines.length === 0) {
        this.handleEmptyServiceClassLines(serviceClass);
      } else {
        this.handleNonEmptyServiceClassLines(serviceClassLines);
      }
    } else {
      this.serviceLineControl.reset();
    }
  }

  private getServiceClassLines(serviceClassId: string) {
    return (
      this.serviceClassesWithLines.value.find(
        (scWithLines) => scWithLines.class.serviceClassId === serviceClassId
      )?.lines || []
    );
  }

  private handleEmptyServiceClassLines(serviceClass: ServiceClassModel) {
    this.onChange({
      serviceClassId: serviceClass.serviceClassId,
      serviceClassType: serviceClass.type,
      serviceClassLine: null,
    });
    this.onTouched();
  }

  private handleNonEmptyServiceClassLines(
    serviceClassLines: ServiceLineModel[]
  ) {
    const foundLines = serviceClassLines.filter(
      (line) => !this.excludeLineTypes.includes(line.type)
    );
    if (this.serviceLineControl.value) {
      if (this.singleSelection) {
        this.setSingleSelectionValue(foundLines);
      } else {
        this.setMultipleSelectionValue(foundLines);
      }
    } else if (foundLines.length === 1 && !this.singleSelection) {
      this.serviceLineControl.setValue(serviceClassLines);
    }
  }

  private setSingleSelectionValue(foundLines: ServiceLineModel[]) {
    const serviceLine = foundLines.find(
      (line) => line.serviceLineId === this.serviceLineControl.value
    );
    this.serviceLineControl.setValue(serviceLine);
  }

  private setMultipleSelectionValue(foundLines: ServiceLineModel[]) {
    const lineIds = Array.isArray(this.serviceLineControl.value)
      ? this.serviceLineControl.value.map(
          (line: ServiceLineModel) => line.serviceLineId
        )
      : this.serviceLineControl.value.split(',');
    const lines = foundLines.filter((line) =>
      lineIds.includes(line.serviceLineId)
    );
    this.serviceLineControl.patchValue(lines);
  }
}
