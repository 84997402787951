import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountModelFormComponent } from './account-model-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { RadioGroupListWithDescriptionComponent } from '@fleet/ui';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BsbFormControlModule } from '@fleet/shared';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    console.log('loading');
    return acc;
  },
  {}
);
@NgModule({
  declarations: [AccountModelFormComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    BsbFormControlModule,
    TranslocoDirective,
    RadioGroupListWithDescriptionComponent,
    MatOptionModule,
    MatSelectModule,
  ],
  exports: [AccountModelFormComponent],
  providers: [
    provideTranslocoScope({
      scope: 'accountModelForm',
      loader: lazyTranslationloader,
    }),
  ],
})
export class AccountModelFormModule {}
