import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AuthorisationService,
  NotAuthorisedRouteDetail,
} from '../authorisation.service';

@Component({
  selector: 'fleet-not-authorised',
  templateUrl: './not-authorised.component.html',
  styleUrls: ['./not-authorised.component.scss'],
})
export class NotAuthorisedComponent implements OnInit, OnDestroy {
  fleetProduct: string;

  notAuthorisedRouteDetails$: Observable<NotAuthorisedRouteDetail>;
  constructor(
    private authorisationService: AuthorisationService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
  }

  ngOnInit(): void {
    this.notAuthorisedRouteDetails$ =
      this.authorisationService.notAuthorisedRouteDetail$;
  }

  ngOnDestroy(): void {
    this.authorisationService.setNotAuthorisedRouteDetails(null);
  }
}
