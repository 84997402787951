<ng-container *transloco="let t">
  <div class="max-w-6xl p-6 md:p-8 md:pb-12 lg:p-12">
    <h2 class="mb-6 text-lg leading-6 font-medium text-gray-900">
      {{ t('notAuthorised.notAuthorisedTitle') }}
    </h2>
    <div class="mb-8">
      {{ t('notAuthorised.notAuthorisedMessage') }}
    </div>

    <ng-container *ngIf="fleetProduct === 'HUB'">
      <ng-container *ngIf="notAuthorisedRouteDetails$ | async as detail">
        <h3 class="text-sm font-medium">
          {{ t('notAuthorised.requiredSecurityFunctions') }}
        </h3>
        <div class="mt-2 text-sm">
          <ul role="list" class="list-disc pl-5 space-y-1">
            <li *ngFor="let function of detail.functionTypes" class="text-sm">
              {{ function }}
            </li>
          </ul>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
