import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TicketApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  DriverModel,
  IssueModel,
  JobModel,
  OperatorModel,
  OrganisationModel,
  PaymentTransactionModel,
  ReferenceDataItemModel,
  TicketCategoryModel,
  TicketModel,
  TravellerModel,
  VehicleModel,
} from '@fleet/model';
import { ReferenceService } from '@fleet/reference';
import { DialogLayoutComponent, OnscreenNotificationService } from '@fleet/ui';
import { successNotification, failureNotification } from '@fleet/utilities';
import { Observable } from 'rxjs';
import {
  OperatorUserModel,
  OrganisationUserModel,
} from '../../../../model/src/lib/api-model';

interface TicketAssociationModel {
  type: string;
  id: string;
}

@Component({
  selector: 'fleet-ticket-create-edit',
  templateUrl: './ticket-create-edit.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class TicketCreateEditComponent
  extends DialogLayoutComponent
  implements OnInit
{
  @Input() defaultType: string;
  associationTypes: string[] = [];
  filteredAssociations: string[] = [];
  loading = false;
  issues: any[] = [];
  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.filteredAssociations.push('DRIVER');

      if (this.driver.billingPaymentMethod) {
        this.filteredAssociations.push('PAYMENT_METHOD');
      }

      this.form.patchValue({ group: 'DRIVER' });
    }
  }
  get driver() {
    return this._driver;
  }

  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.filteredAssociations.push('TRAVELLER');

      this.form.patchValue({ group: 'TRAVELLER' });

      this.changeDetectorRef.markForCheck();
    }
  }

  get traveller() {
    return this._traveller;
  }

  _vehicle: VehicleModel;
  @Input() set vehicle(value: VehicleModel) {
    this._vehicle = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.filteredAssociations.push('VEHICLE');

      this.form.patchValue({ group: 'VEHICLE' });

      this.changeDetectorRef.markForCheck();
    }
  }

  get vehicle() {
    return this._vehicle;
  }

  _operator: OperatorModel;
  @Input() set operator(value: OperatorModel) {
    this._operator = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.filteredAssociations.push('OPERATOR');
      this.form.patchValue({ group: 'OPERATOR' });

      this.changeDetectorRef.markForCheck();
    }
  }

  get operator() {
    return this._operator;
  }

  _operatorUser: OperatorUserModel;
  @Input() set operatorUser(value: OperatorUserModel) {
    this._operatorUser = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.filteredAssociations.push('OPERATOR_USER');

      this.form.patchValue({ group: 'OPERATOR_USER' });
    }
  }

  get operatorUser() {
    return this._operatorUser;
  }

  _organisation: OrganisationModel;
  @Input() set organisation(value: OrganisationModel) {
    this._organisation = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.filteredAssociations.push('ORGANISATION');

      this.form.patchValue({ group: 'ORGANISATION' });

      this.changeDetectorRef.markForCheck();
    }
  }

  get organisation() {
    return this._organisation;
  }

  _organisationUser: OrganisationUserModel;
  @Input() set organisationUser(value: OrganisationUserModel) {
    this._organisationUser = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.filteredAssociations.push('ORGANISATION_USER');

      this.form.patchValue({ group: 'ORGANISATION_USER' });
    }
  }

  get organisationUser() {
    return this._organisationUser;
  }

  _job: JobModel;
  @Input() set job(value: JobModel) {
    this._job = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.filteredAssociations.push('JOB');

      if (value.traveller) {
        this.filteredAssociations.push('TRAVELLER');
      }

      if (value.driver) {
        this.filteredAssociations.push('DRIVER');
      }

      if (value.organisation) {
        this.filteredAssociations.push('ORGANISATION');
      }

      if (value.vehicle) {
        this.filteredAssociations.push('VEHICLE');
      }

      this.form.patchValue({ group: 'JOB' });
      this.changeDetectorRef.markForCheck();
    }
  }

  get job() {
    return this._job;
  }

  @Output() successful = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  @Input() transaction: PaymentTransactionModel;

  _ticket: TicketModel;
  @Input() set ticket(value: TicketModel) {
    this._ticket = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }

      this.ticketApiService
        .getTicket(value.ticketId)
        .subscribe((resp: ApiResponse<TicketModel>) => {
          this.form.patchValue(resp.data);

          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        });

      this.changeDetectorRef.markForCheck();
    }
  }

  get ticket() {
    return this._ticket;
  }
  form: UntypedFormGroup;
  ticketCategoryGroups$: Observable<ReferenceDataItemModel[]>;
  searchingTicketCategories = false;
  ticketCategories: TicketCategoryModel[];
  // ticketAssociations: TicketAssociationModel[] = [];

  selectedTicketAssociations: [
    {
      ticketAssociation: TicketAssociationModel;
      included: boolean;
      disabled: boolean;
    }
  ] = [] as any;

  constructor(
    private fb: UntypedFormBuilder,
    private ticketApiService: TicketApiService,
    private referenceService: ReferenceService,
    private changeDetectorRef: ChangeDetectorRef,
    private onscreenNotificationService: OnscreenNotificationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.ticketCategoryGroups$ = this.referenceService.ticketCategoryGroups$;

    if (!this.form) {
      this.buildForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      group: [null, [Validators.required]],
      ticketCategoryId: [null, [Validators.required]],
      subject: [null, [Validators.required]],
      content: [null, [Validators.required]],
      associations: this.fb.array([]),
      status: ['NEW'],
    });
    this.form.get('group').valueChanges.subscribe((value: string) => {
      if (value) {
        const params = this.prepareParamsForTicketCategorySearch(value);
        this.form.get('ticketCategoryId').reset();
        this.searchTicketCategories(params);
      }
    });
    this.form
      .get('ticketCategoryId')
      .valueChanges.subscribe((selectedCategoryId: string) => {
        if (selectedCategoryId) {
          const matchingCategory = this.ticketCategories.find(
            (category) => category.ticketCategoryId === selectedCategoryId
          );
          if (matchingCategory && matchingCategory.associations) {
            this.selectedTicketAssociations = matchingCategory.associations
              .filter((assoc) => this.filteredAssociations.includes(assoc))
              .map((assoc) => {
                return {
                  ticketAssociation: { type: assoc, id: null },
                  included: true,
                  disabled: assoc === this.defaultType,
                };
              }) as any;

            this.changeDetectorRef.markForCheck();
          }
        }
      });
  }

  submit() {
    if (this.ticket) {
      this.updateTicket();
    } else {
      this.createTicket();
    }
  }
  updateTicket() {
    this.loading = true;
    this.issues = [];

    const payload = Object.assign({}, this.form.getRawValue());

    delete payload['group'];

    this.ticketApiService
      .updateTicket(this.ticket.ticketId, payload)
      .subscribe({
        next: (resp: ApiResponse<TicketModel>) => {
          this.loading = false;
          this.successful.emit(resp.data);
          this.onscreenNotificationService.setNotification({
            ...successNotification,
            title: 'Success',
            subTitle: 'Ticket updated successfully!',
          });
        },
        error: (issues: IssueModel[]) => {
          this.loading = false;
          this.issues = issues;
          this.changeDetectorRef.markForCheck();
          this.onscreenNotificationService.setNotification({
            ...failureNotification,
            title: 'Error',
            subTitle: 'Failed to update ticket.',
          });
        },
      });
  }

  createTicket() {
    this.loading = true;
    this.issues = [];

    const payload = Object.assign({}, this.form.getRawValue());

    delete payload['group'];

    payload.associations = this.selectedTicketAssociations
      .filter((assoc) => assoc.included)
      .map((assoc) => assoc.ticketAssociation);
    payload.associations.forEach((ticketAssociation: any) => {
      switch (ticketAssociation.type) {
        case 'DRIVER':
          if (this.driver) {
            ticketAssociation.id = this.driver.driverId;
          } else if (this.job && this.job.driver) {
            ticketAssociation.id = this.job.driver.driverId;
          }
          break;
        case 'TRAVELLER':
          if (this.traveller) {
            ticketAssociation.id = this.traveller.travellerId;
          } else if (this.job && this.job.traveller) {
            ticketAssociation.id = this.job.traveller.travellerId;
          }
          break;
        case 'VEHICLE':
          if (this.vehicle) {
            ticketAssociation.id = this.vehicle.vehicleId;
          } else if (this.job && this.job.vehicle) {
            ticketAssociation.id = this.job.vehicle.vehicleId;
          }
          break;
        case 'OPERATOR':
          if (this.operator) {
            ticketAssociation.id = this.operator.operatorId;
          }
          // else if (this.job && this.job.operator) {
          //   ticketAssociation.id = this.job.operator.operatorId;
          // }
          break;
        case 'OPERATOR_USER':
          if (this.operatorUser) {
            ticketAssociation.id = this.operatorUser.operatorUserId;
          }
          // else if (this.job && this.job.operatorUser) {
          //   ticketAssociation.id = this.job.operatorUser.operatorUserId;
          // }
          break;
        case 'ORGANISATION':
          if (this.organisation) {
            ticketAssociation.id = this.organisation.organisationId;
          } else if (this.job && this.job.organisation) {
            ticketAssociation.id = this.job.organisation.organisationId;
          }
          break;
        case 'ORGANISATION_USER':
          if (this.organisationUser) {
            ticketAssociation.id = this.organisationUser.organisationUserId;
          }
          // else if (this.job && this.job.organisationUser) {
          //   ticketAssociation.id = this.job.organisation.organisationId;
          // }
          break;
        case 'JOB':
          if (this.job) {
            ticketAssociation.id = this.job.jobId;
          }
          break;
        case 'ASSET':
          if (this.job) {
            ticketAssociation.id = this.job.vehicle.assets[0].assetId;
          }
          break;
        case 'PAYMENT_METHODD':
          if (this.driver) {
            ticketAssociation.id =
              this.driver.billingPaymentMethod.paymentMethodId;
          }
          break;
      }
    });

    payload.associations = payload.associations.filter(
      (assoc: TicketAssociationModel) =>
        assoc.id !== null && assoc.id !== '' && assoc.id !== undefined
    );

    this.ticketApiService.createTicket(payload).subscribe({
      next: (resp: ApiResponse<TicketModel>) => {
        this.loading = false;
        this.successful.emit(resp.data);
        this.onscreenNotificationService.setNotification({
          ...successNotification,
          title: 'Success',
          subTitle: 'Ticket created successfully!',
        });
      },
      error: (issues: IssueModel[]) => {
        this.loading = false;
        this.issues = issues;
        this.changeDetectorRef.markForCheck();
        this.onscreenNotificationService.setNotification({
          ...failureNotification,
          title: 'Error',
          subTitle: 'Failed to create ticket.',
        });
      },
    });
  }

  searchTicketCategories(params: any) {
    params['limit'] = 100;
    params['offset'] = 0;
    this.searchingTicketCategories = true;
    this.changeDetectorRef.markForCheck();
    this.ticketApiService.searchTicketCategories(params).subscribe({
      next: (resp: HttpResponse<ApiResponse<TicketCategoryModel[]>>) => {
        this.ticketCategories = resp.body.data;
        this.searchingTicketCategories = false;
        this.changeDetectorRef.markForCheck();
      },
      error: (issues: IssueModel[]) => {
        this.searchingTicketCategories = false;
        this.issues = issues;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  handleTicketAssociationChange(
    ticketAssociation: TicketAssociationModel,
    isChecked: boolean,
    index: number
  ): void {
    if (isChecked) {
      this.selectedTicketAssociations[index] = {
        ...this.selectedTicketAssociations[index],
        ticketAssociation: ticketAssociation,
        included: true,
      };
    } else {
      this.selectedTicketAssociations[index].included = false;
    }

    this.changeDetectorRef.markForCheck();
  }

  prepareParamsForTicketCategorySearch(group: string) {
    let applicableTo: string;
    const params: any = {
      group: group,
    };
    if (this.driver) {
      applicableTo = 'DRIVER';
    } else if (this.operator) {
      applicableTo = 'OPERATOR';
    } else if (this.traveller) {
      applicableTo = 'TRAVELLER';
    } else if (this.organisation) {
      applicableTo = 'ORGANISATION';
    }
    if (applicableTo) {
      params['applicableTo'] = applicableTo;
    }
    return params;
  }

  get formAssociationsControls() {
    return (this.form.get('associations') as FormArray).controls;
  }

  // Create new method that will get categories - this will be called on each of the inputs for driver etc.
  // (source) then filter this array by this value to get the categories back, and then once a category
  //  is selected do search for issues
}
