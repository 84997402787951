import { NgModule } from '@angular/core';
import { OrganisationSearchListAutocompleteComponent } from './organisation-search-list-autocomplete.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MatListModule } from '@angular/material/list';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { SelectionListInDrawerModule } from '@fleet/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [OrganisationSearchListAutocompleteComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,

    MatListModule,

    CdkScrollableModule,
    A11yModule,
    SelectionListInDrawerModule,
    FormsModule,
    ReactiveFormsModule,
    TitleCaseAndCleanPipeModule,
    MatAutocompleteModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    MatProgressSpinnerModule,
    TranslocoDirective,
  ],
  providers: [
    provideTranslocoScope({
      scope: 'organisationStaffListAutocomplete',
      loader: lazyTranslationloader,
    }),
  ],
  exports: [OrganisationSearchListAutocompleteComponent],
})
export class OrganisationSearchListAutocompleteModule {}
