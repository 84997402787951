import { Pipe, PipeTransform } from '@angular/core';
import { FareElementModel } from '@fleet/model';

@Pipe({
  name: 'fareGroupTotals',
})
export class FareGroupTotalsPipe implements PipeTransform {
  transform(group: FareElementModel[], ...args: unknown[]): any {
    let totalTax = 0.0;
    let amountTaxExclusive = 0.0;
    let total = 0.0;
    group.forEach((element: FareElementModel) => {
      totalTax += Number.parseFloat(element.totalTax);
      total += Number.parseFloat(element.amount);
      amountTaxExclusive += Number.parseFloat(element.amountTaxExclusive);
    });
    return { totalTax: totalTax, total: total, subTotal: amountTaxExclusive };
  }
}
