import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravellerDetailContainerComponent } from './components/traveller-detail-container/traveller-detail-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { travellerDetailRoutes } from './traveller-detail.routing';
import { RouterModule } from '@angular/router';
import {
  EntityDetailLayoutModule,
  EntityHeaderWithBreadcrumbsModule,
} from '@fleet/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {
  FuseAlertModule,
  FuseCardModule,
  FuseNavigationModule,
} from '@fleet/fuse';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatSidenavModule } from '@angular/material/sidenav';
import { TravellerStatusModule } from '../traveller-status/traveller-status.module';
import { TicketActionButtonModule } from '@fleet/ticket';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { provideTranslocoScope } from '@jsverse/transloco';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    return acc;
  },
  {}
);
@NgModule({
  declarations: [TravellerDetailContainerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(travellerDetailRoutes),
    EntityDetailLayoutModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSortModule,
    MatTableModule,
    FuseNavigationModule,
    FuseCardModule,
    FuseAlertModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,

    MatSidenavModule,
    TravellerStatusModule,
    TicketActionButtonModule,
    EntityHeaderWithBreadcrumbsModule,
    HasFunctionPipeModule,
  ],

  providers: [
    provideTranslocoScope({
      scope: 'travellerDetail',
      loader: lazyTranslationloader,
    }),
  ],
})
export class TravellerDetailModule {}
