import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { AvailableLangs, TranslocoService } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'fleet-language-selector',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule],
  template: `
    <button mat-icon-button [matMenuTriggerFor]="languagesMenu">
      <mat-icon>language</mat-icon>
    </button>
    <mat-menu #languagesMenu="matMenu">
      <button
        mat-menu-item
        *ngFor="let lang of availableLangs"
        (click)="setActiveLang(lang.id)"
      >
        <span>{{ lang.label }}</span>
      </button>
    </mat-menu>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent {
  availableLangs: any[] = this.translocoService.getAvailableLangs();
  activeLang = this.translocoService.getActiveLang();

  constructor(
    private translocoService: TranslocoService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  setActiveLang(lang: string): void {
    this.translocoService.setActiveLang(lang);
    this.translocoService.load(lang).subscribe({});
    this.activeLang = lang;
    this.changeDetectorRef.markForCheck();
  }
}
