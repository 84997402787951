<div class="ngx-mat-tel-input-container flex flex-row items-center">
  <button
    type="button"
    mat-button
    [matMenuTriggerFor]="menu"
    class="country-selector"
    [disabled]="(onlyCountries && onlyCountries.length === 1) || disabled"
    tabindex="-1"
  >
    <div class="flex flex-row items center">
      <span
        class="country-selector-flag flag"
        [ngClass]="selectedCountry?.flagClass"
      ></span>
      <span class="country-selector-code" *ngIf="selectedCountry?.dialCode"
        >+{{ selectedCountry.dialCode }}</span
      >
    </div>
  </button>

  <input
    [placeholder]="inputPlaceholder"
    autocomplete="off"
    data-lpignore="true"
    data-form-type="other"
    matInput
    id="telInput"
    type="tel"
    [ngClass]="cssClass"
    (blur)="onTouched()"
    (keypress)="onInputKeyPress($event)"
    [(ngModel)]="phoneNumber"
    (ngModelChange)="onPhoneNumberChange()"
    [disabled]="disabled"
    [aria-describedby]="describedBy"
    #focusable
  />
</div>

<mat-menu
  #menu="matMenu"
  class="ngx-mat-tel-input-mat-menu-panel"
  backdropClass="ngx-mat-tel-input-overlay-backdrop"
  overlayPanelClass="ngx-mat-tel-input-overlay-pane"
>
  <input
    autocomplete="off"
    data-lpignore="true"
    data-form-type="other"
    *ngIf="enableSearch"
    class="country-search"
    [(ngModel)]="searchCriteria"
    type="text"
    [placeholder]="searchPlaceholder"
    (click)="$event.stopPropagation()"
  />
  <button
    type="button"
    mat-menu-item
    class="country-list-button"
    *ngFor="let country of preferredCountriesInDropDown"
    (click)="onCountrySelect(country, focusable)"
  >
    <div class="icon-wrapper">
      <div class="flag" [ngClass]="country?.flagClass"></div>
    </div>
    <div class="label-wrapper">
      {{ country?.name }}
      <span *ngIf="country?.dialCode">+{{ country.dialCode }}</span>
    </div>
  </button>
  <mat-divider *ngIf="preferredCountriesInDropDown?.length"></mat-divider>
  <ng-container *ngFor="let country of allCountries">
    <button
      type="button"
      mat-menu-item
      class="country-list-button"
      *ngIf="country | search : searchCriteria"
      (click)="onCountrySelect(country, focusable)"
    >
      <div class="icon-wrapper">
        <div class="flag" [ngClass]="country?.flagClass"></div>
      </div>
      <div class="label-wrapper">
        {{ country?.name }} +{{ country.dialCode }}
      </div>
    </button>
  </ng-container>
</mat-menu>
