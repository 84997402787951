import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ContractModel,
  InvoiceModel,
  IssueModel,
  JobExportSearchCriteriaModel,
  LocationModel,
  LoginModel,
  NetworkConditionModel,
  NoteModel,
  OrganisationGroupModel,
  OrganisationModel,
  OrganisationStaffExportSearchCriteriaModel,
  OrganisationStaffModel,
  OrganisationTransactionApprovalSearchModel,
  OrganisationUserModel,
  PaymentMethodModel,
  PaymentModel,
  PlanModel,
  TransactionExportCriteriaModel,
} from '@fleet/model';
import { catchError, map } from 'rxjs/operators';

import { paths } from '@fleet/environment';
import { encodeParams, handleApiError } from '@fleet/utilities';

import { ApiResponse, JobModel, LocationSearchResultModel } from '@fleet/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganisationApiService {
  host;
  travellerEndpoint;
  policyHost;
  authHost;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.organisation;
    this.authHost = env.host + paths.auth;
    this.travellerEndpoint = env.host + paths.traveller;
    this.policyHost = env.host + paths.policy;
  }

  // setEnvironment(environment) {
  //   this.host = environment + paths.organisation;
  //   this.travellerEndpoint = environment + paths.traveller;
  //   this.policyHost = environment + paths.policy;
  // }

  searchOrganisations(params: any) {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getOrganisation(
    organisationId: string
  ): Observable<ApiResponse<OrganisationModel> | any> {
    return this.http
      .get(`${this.host}/${organisationId}`)
      .pipe(catchError(handleApiError));
  }

  createOrganisation(payload: OrganisationModel) {
    return this.http
      .post(`${this.host}`, payload)
      .pipe(catchError(handleApiError));
  }

  updateOrganisation(payload: OrganisationModel, orgId: string) {
    return this.http
      .put(`${this.host}/${orgId}`, payload)
      .pipe(catchError(handleApiError));
  }

  addGroup(payload: OrganisationGroupModel, orgId: string) {
    return this.http
      .post(`${this.host}/${orgId}/group`, payload)
      .pipe(catchError(handleApiError));
  }

  updateGroup(payload: OrganisationGroupModel, orgId: string) {
    return this.http
      .put(
        `${this.host}/${orgId}/group/${payload.organisationGroupId}`,
        payload
      )
      .pipe(catchError(handleApiError));
  }

  searchGroups(params: any, orgId: string): Observable<any> {
    return this.http
      .get(`${this.host}/${orgId}/group`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  addStaffMemberToGroup(
    organisationId: string,
    groupId: string,
    staff: OrganisationStaffModel
  ) {
    return this.http
      .post(`${this.host}/${organisationId}/group/${groupId}/staff`, staff)
      .pipe(catchError(handleApiError));
  }

  removeStaffMemberFromGroup(
    organisationId: string,
    groupId: string,
    staffId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${organisationId}/group/${groupId}/staff/${staffId}`
      )
      .pipe(catchError(handleApiError));
  }

  getGroup(groupId: string, orgId: string) {
    return this.http
      .get(`${this.host}/${orgId}/group/${groupId}`)
      .pipe(catchError(handleApiError));
  }

  deleteGroup(groupId: string, orgId: string) {
    return this.http.delete(`${this.host}/${orgId}/group/${groupId}`);
  }

  searchLocations(
    params: any,
    orgId: string
  ): Observable<HttpResponse<ApiResponse<LocationSearchResultModel[]>> | any> {
    return this.http
      .get(`${this.host}/${orgId}/location`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  addLocation(params: any, orgId: string) {
    return this.http
      .post(`${this.host}/${orgId}/location`, params)
      .pipe(catchError(handleApiError));
  }

  getLocation(
    locationId: any,
    orgId: any
  ): Observable<ApiResponse<LocationModel> | any> {
    return this.http
      .get(`${this.host}/${orgId}/location/${locationId}`)
      .pipe(catchError(handleApiError));
  }

  updateLocation(payload: any, orgId: string, organisationLocationId: string) {
    delete payload.selectedOrganisationId;
    delete payload.address;
    return this.http
      .put(`${this.host}/${orgId}/location/${organisationLocationId}`, payload)
      .pipe(catchError(handleApiError));
  }

  deleteLocation(organisationLocationId: string, orgId: string) {
    return this.http
      .delete(`${this.host}/${orgId}/location/${organisationLocationId}`)
      .pipe(catchError(handleApiError));
  }

  updatePaymentMethod(payload: PaymentMethodModel, orgId: string) {
    return this.http
      .put(
        `${this.host}/${orgId}/paymentMethod/${payload.paymentMethodId}`,
        payload
      )
      .pipe(catchError(handleApiError));
  }

  searchPaymentMethods(
    params: any,
    orgId: any
  ): Observable<HttpResponse<ApiResponse<PaymentMethodModel[]>> | any> {
    return this.http
      .get(`${this.host}/${orgId}/paymentMethod`, {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  deletePaymentMethod(paymentMethodId: string, orgId: string) {
    return this.http
      .delete(`${this.host}/${orgId}/paymentMethod/${paymentMethodId}`)
      .pipe(catchError(handleApiError));
  }

  getPaymentMethod(paymentId: string, orgId: string) {
    return this.http.get(`${this.host}/${orgId}/paymentMethod/${paymentId}`);
  }

  addPaymentMethod(payload: any, orgId: string) {
    return this.http
      .post(`${this.host}/${orgId}/paymentMethod`, payload)
      .pipe(catchError(handleApiError));
  }

  searchStaff(
    params: {
      [x: string]: string | number | boolean;
      name?: any;
      limit?: string;
      offset?: string;
      sort?: string;
      status?: string;
    },
    organisationId: any
  ) {
    return this.http
      .get(`${this.host}/${organisationId}/staff`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  addStaff(payload: OrganisationStaffModel, orgId: string) {
    return this.http
      .post(`${this.host}/${orgId}/staff`, payload)
      .pipe(catchError(handleApiError));
  }

  updateStaff(payload: OrganisationStaffModel, orgId: string) {
    return this.http
      .put(
        `${this.host}/${orgId}/staff/${payload.organisationStaffId}`,
        payload
      )
      .pipe(catchError(handleApiError));
  }

  removeStaff(staffId: any, orgId: any) {
    return this.http
      .delete(`${this.host}/${orgId}/staff/${staffId}`)
      .pipe(catchError(handleApiError));
  }

  searchUsers(params: any, orgId: string) {
    return this.http
      .get(`${this.host}/${orgId}/user`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  addUser(payload: OrganisationUserModel, orgId: string) {
    return this.http
      .post(`${this.host}/${orgId}/user`, payload)
      .pipe(catchError(handleApiError));
  }

  getUser(
    userId: string,
    orgId: string
  ): Observable<OrganisationUserModel | any> {
    return this.http
      .get(`${this.host}/${orgId}/user/${userId}`)
      .pipe(catchError(handleApiError));
  }

  updateUser(payload: any, orgId: string, organisationUserId: string) {
    delete payload.selectedOrganisationId;
    return this.http
      .put(`${this.host}/${orgId}/user/${organisationUserId}`, payload)
      .pipe(catchError(handleApiError));
  }

  removeUser(organisationUserId: string, orgId: string) {
    return this.http
      .delete(`${this.host}/${orgId}/user/${organisationUserId}`)
      .pipe(catchError(handleApiError));
  }

  getStaff(
    staffId: string,
    orgId: string
  ): Observable<OrganisationStaffModel | any> {
    return this.http
      .get(`${this.host}/${orgId}/staff/${staffId}`)
      .pipe(catchError(handleApiError));
  }

  getDailyActivity(params: any, organisationId: string) {
    return this.http
      .get(`${this.host}/${organisationId}/activity/daily`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getRollingActivity(params: any, organisationId: string) {
    return this.http
      .get(`${this.host}/${organisationId}/activity/rolling`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchBookings(
    params: { [x: string]: string | number | boolean },
    orgId: any
  ) {
    return this.http
      .get(`${this.host}/${orgId}/booking/recent`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  getBooking(bookingId: string, orgId: string) {
    return this.http
      .get(`${this.host}/${orgId}/receipt/${bookingId}`)
      .pipe(catchError(handleApiError));
  }

  addPaymentMethodToGroup(
    organisationId: string,
    organisationGroupId: string,
    paymentMethod: PaymentMethodModel
  ) {
    return this.http
      .post(
        `${this.host}/${organisationId}/group/${organisationGroupId}/paymentMethod`,
        paymentMethod
      )
      .pipe(catchError(handleApiError));
  }

  removePaymentMethodFromGroup(
    organisationId: string,
    organisationGroupId: string,
    paymentMethodId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${organisationId}/group/${organisationGroupId}/paymentMethod/${paymentMethodId}`
      )
      .pipe(catchError(handleApiError));
  }

  addLocationToGroup(
    location: LocationModel,
    organisationGroupId: string,
    organisationId: string
  ) {
    // const update = Object.assign(
    //   {},
    //   { phoneNumber: location.phoneNumber },
    //   location
    // );
    // delete update.organisationId;
    // delete update.contactNumber;
    // if (update.isMember) {
    //   //from assigning list with member field
    //   delete update.isMember;
    // }
    // delete update.address;

    return this.http
      .post(
        `${this.host}/${organisationId}/group/${organisationGroupId}/location`,
        location
      )
      .pipe(catchError(handleApiError));
  }

  removeLocationFromGroup(
    location: LocationModel,
    organisationGroupId: string,
    organisationId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${organisationId}/group/${organisationGroupId}/location/${location.organisationGroupLocationId}`
      )
      .pipe(catchError(handleApiError));
  }

  removeUserFromGroup(
    organisationId: string,
    organisationGroupId: string,
    orgnaisationUserId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${organisationId}/group/${organisationGroupId}/user/${orgnaisationUserId}`
      )
      .pipe(catchError(handleApiError));
  }

  unlinkGroupFromContract(
    organisationId: string,
    organisationGroupId: string,
    contractId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${organisationId}/contract/${contractId}/organisationGroup/${organisationGroupId}`
      )
      .pipe(catchError(handleApiError));
  }

  addUserToGroup(
    organisationId: string,
    organisationGroupId: string,
    user: any
  ) {
    if (user.isMember) {
      //from assigning list with member field
      delete user.isMember;
    }

    return this.http
      .post(
        `${this.host}/${organisationId}/group/${organisationGroupId}/user`,
        user,
        {
          observe: 'response',
        }
      )
      .pipe(catchError(handleApiError));
  }

  linkContractToGroup(
    organisationId: string,
    organisationGroupId: string,
    contract: ContractModel
  ) {
    return this.http
      .post(
        `${this.host}/${organisationId}/contract/${contract.contractId}/organisationGroup/${organisationGroupId}`,
        contract,
        {
          observe: 'response',
        }
      )
      .pipe(catchError(handleApiError));
  }

  issueCard(organisationId: string, staffId: string) {
    return this.http
      .post(`${this.host}/${organisationId}/staff/${staffId}/card`, {})
      .pipe(catchError(handleApiError));
  }

  // markCardLost(paymentMethodId, passengerId) {
  //   return this.http
  //     .put(`${this.travellerEndpoint}/${travellerId}/paymentMethod/${paymentMethodId}/lost`, {})
  //     .pipe(catchError(handleApiError));
  // }

  // markCardStolen(paymentMethodId, passengerId) {
  //   return this.http
  //     .put(`${this.travellerEndpoint}/${travellerId}/paymentMethod/${paymentMethodId}/stolen`, {})
  //     .pipe(catchError(handleApiError));
  // }

  // markCardBlocked(paymentMethodId, passengerId) {
  //   return this.http
  //     .put(`${this.travellerEndpoint}/${travellerId}/paymentMethod/${paymentMethodId}/suspend`, {})
  //     .pipe(catchError(handleApiError));
  // }

  // reinstateCard(paymentMethodId, passengerId) {
  //   return this.http
  //     .put(`${this.travellerEndpoint}/${travellerId}/paymentMethod/${paymentMethodId}/reinstate`, {})
  //     .pipe(catchError(handleApiError));
  // }

  // deactivateCard(paymentMethodId, passengerId) {
  //   return this.http
  //     .delete(`${this.travellerEndpoint}/${travellerId}/paymentMethod/${paymentMethodId}`, {})
  //     .pipe(catchError(handleApiError));
  // }

  // updateCardwithAction(paymentMethod: PaymentMethodSearchResultModel, action: string) {
  //   return this.http
  //     .put(
  //       `${this.travellerEndpoint}/${paymentMethod.travellerId}/paymentMethod/${paymentMethod.paymentMethodId}/${action}`,
  //       {}
  //     )
  //     .pipe(catchError(handleApiError));
  // }

  // reissueCard(paymentMethodId, passengerId) {
  //   return this.http
  //     .put(`${this.travellerEndpoint}/${travellerId}/paymentMethod/${paymentMethodId}/reissue`, {})
  //     .pipe(catchError(handleApiError));
  // }

  getCards(organisationId: string, params: any) {
    return this.http
      .get(`${this.host}/${organisationId}/paymentMethod`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  createPolicy(params: any) {
    return this.http
      .post(`${this.policyHost}`, params)
      .pipe(catchError(handleApiError));
  }

  updatePolicy(params: any, policyId: string) {
    return this.http
      .put(`${this.policyHost}/${policyId}`, params)
      .pipe(catchError(handleApiError));
  }

  getPolicy(policyId: string, organisationId: string) {
    return this.http
      .get(`${this.policyHost}/${policyId}`)
      .pipe(catchError(handleApiError));
  }

  addPolicyToOrganisation(
    organisationId: string,
    params: any
  ): Observable<any> {
    return this.http
      .post(`${this.host}/${organisationId}/policy`, params)
      .pipe(catchError(handleApiError));
  }

  addPolicyToStaff(staffId: string, organisationId: string, params: any) {
    return this.http
      .post(`${this.host}/${organisationId}/staff/${staffId}/policy`, params)
      .pipe(catchError(handleApiError));
  }

  addPolicyToGroup(groupId: string, organisationId: string, params: any) {
    return this.http
      .post(`${this.host}/${organisationId}/group/${groupId}/policy`, params)
      .pipe(catchError(handleApiError));
  }

  removePolicyFromStaff(
    staffId: string,
    policyId: string,
    organisationId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${organisationId}/staff/${staffId}/policy/${policyId}`
      )
      .pipe(catchError(handleApiError));
  }

  removePolicyFromGroup(
    groupId: string,
    policyId: string,
    organisationId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${organisationId}/group/${groupId}/policy/${policyId}`
      )
      .pipe(catchError(handleApiError));
  }

  removePolicyFromOrganisation(policyId: string, organisationId: string) {
    return this.http
      .delete(`${this.host}/${organisationId}/policy/${policyId}`)
      .pipe(catchError(handleApiError));
  }

  searchPolicies(params: any, organisationId: string) {
    return this.http
      .get(`${this.host}/${organisationId}/policy`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  removePolicy(policyId: any) {
    return this.http
      .delete(`${this.policyHost}/${policyId}`)
      .pipe(catchError(handleApiError));
  }

  searchContracts(
    params: { [x: string]: string | number | boolean },
    organisationId: string
  ) {
    return this.http
      .get(`${this.host}/${organisationId}/contract`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  createContract(params: any, organisationId: string) {
    return this.http
      .post(`${this.host}/${organisationId}/contract`, params)
      .pipe(catchError(handleApiError));
  }

  updateContract(params: any, contractId: string, organisationId: string) {
    delete params.billingContacts; // this shouldnt exist on update?
    return this.http
      .put(`${this.host}/${organisationId}/contract/${contractId}`, params)
      .pipe(catchError(handleApiError));
  }

  getContract(
    contractId: string,
    organisationId: string
  ): Observable<ApiResponse<ContractModel>> | any {
    return this.http
      .get(`${this.host}/${organisationId}/contract/${contractId}`)
      .pipe(catchError(handleApiError));
  }

  removeContract(contractId: string, organisationId: string) {
    return this.http
      .delete(`${this.host}/${organisationId}/contract/${contractId}`)
      .pipe(catchError(handleApiError));
  }

  activateContract(contractId: string, organisationId: string) {
    return this.http
      .post(
        `${this.host}/${organisationId}/contract/${contractId}/activate`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  getLocationOnGroupLocationId(
    organisationGroupLocationId: any,
    groupId: any,
    orgId: any
  ) {
    return this.http
      .get(
        `${this.host}/${orgId}/group/${groupId}/location/${organisationGroupLocationId}`
      )
      .pipe(
        map((resp: any) => resp),
        catchError(handleApiError)
      );
  }

  searchContacts(
    params: { [x: string]: string | number | boolean },
    contractId: string,
    organisationId: string
  ) {
    return this.http
      .get(`${this.host}/${organisationId}/contract/${contractId}/contact`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  createContact(params: any, contractId: any, organisationId: any) {
    return this.http
      .post(
        `${this.host}/${organisationId}/contract/${contractId}/contact`,
        params
      )
      .pipe(catchError(handleApiError));
  }

  removeContact(contactId: any, contractId: any, organisationId: any) {
    return this.http
      .delete(
        `${this.host}/${organisationId}/contract/${contractId}/contact/${contactId}`
      )
      .pipe(catchError(handleApiError));
  }

  updateContact(
    params: any,
    contractId: string,
    contactId: string,
    organisationId: string
  ) {
    return this.http
      .put(
        `${this.host}/${organisationId}/contract/${contractId}/contact/${contactId}`,
        params
      )
      .pipe(catchError(handleApiError));
  }

  getActiveBookings(
    organisationId: any,
    { params }: any
  ): Observable<HttpResponse<ApiResponse<JobModel[]>> | any> {
    return this.http
      .get(`${this.host}/${organisationId}/booking/active`, {
        params: params,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  getBookingTransactionDetails(bookingId: any, organisationId: any) {
    return this.http
      .get(`${this.host}/${organisationId}/booking/${bookingId}/transaction`)
      .pipe(catchError(handleApiError));
  }

  inviteUser(organisationId: any, organisationUserId: any): Observable<any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/user/${organisationUserId}/invite`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  inviteStaff(
    organisationId: string,
    organisationStaffId: string
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/staff/${organisationStaffId}/invite`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  resetPassword(loginModel: LoginModel) {
    return this.http
      .post(`${this.authHost}/resetPassword`, loginModel)
      .pipe(catchError(handleApiError));
  }

  forgotPassword(loginModel: LoginModel) {
    return this.http
      .post(`${this.authHost}/forgotPassword`, loginModel)
      .pipe(catchError(handleApiError));
  }

  processUploadedStaff(orgId: string, documentId: string) {
    return this.http
      .post(`${this.host}/${orgId}/staff/processUpload/${documentId}`, {})
      .pipe(catchError(handleApiError));
  }

  processUploadedGroups(orgId: string, documentId: string) {
    return this.http
      .post(`${this.host}/${orgId}/group/processUpload/${documentId}`, {})
      .pipe(catchError(handleApiError));
  }

  processUploadedJobs(orgId: string, documentId: string) {
    return this.http
      .post(`${this.host}/${orgId}/job/processUpload/${documentId}`, {})
      .pipe(catchError(handleApiError));
  }

  getBatchJobStatus(jobId: string, orgId: string) {
    return this.http
      .get(`${this.host}/${orgId}/job/${jobId}`)
      .pipe(catchError(handleApiError));
  }

  createNoncePaymentMethod(organisationId: any, payment: any) {
    return this.http
      .post(`${this.host}/${organisationId}/paymentMethod`, payment)
      .pipe(catchError(handleApiError));
  }

  updateGroups(payload: OrganisationGroupModel[], orgId: string) {
    return this.http
      .put(`${this.host}/${orgId}/group`, payload)
      .pipe(catchError(handleApiError));
  }
  searchTransactions(params: any, organisationId: string) {
    return this.http
      .get(`${this.host}/${organisationId}/transaction`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  getUserRoles(organisationId: string, organisationUserId: string) {
    return this.http
      .get(`${this.host}/${organisationId}/user/${organisationUserId}/role`, {})
      .pipe(catchError(handleApiError));
  }

  addCondition(
    condition: NetworkConditionModel,
    organisationId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/${organisationId}/condition`, condition)
      .pipe(catchError(handleApiError));
  }

  removeCondition(
    driverConditionId: string,
    organisationId: string
  ): Observable<ApiResponse<any> | IssueModel[] | any> {
    return this.http
      .delete(`${this.host}/${organisationId}/condition/${driverConditionId}`)
      .pipe(catchError(handleApiError));
  }

  selectPlan(
    organisationId: string,
    plan: PlanModel
  ): Observable<ApiResponse<OrganisationModel> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/${organisationId}/selectPlan`, plan)
      .pipe(catchError(handleApiError));
  }

  linkContractToPaymentType(
    organisationId: string,
    contractId: string,
    paymentMethod: any
  ): Observable<ApiResponse<ContractModel> | any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/contract/${contractId}/paymentMethod`,
        paymentMethod
      )
      .pipe(catchError(handleApiError));
  }

  linkContractToPaymentMethod(
    organisationId: string,
    contractId: string,
    paymentMethodId: string
  ) {
    return this.http
      .post(
        `${this.host}/${organisationId}/contract/${contractId}/paymentMethod/${paymentMethodId}`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  unlinkContractToPaymentMethod(
    organisationId: string,
    contractId: string,
    paymentMethodId: string
  ) {
    return this.http
      .delete(
        `${this.host}/${organisationId}/contract/${contractId}/paymentMethod/${paymentMethodId}`
      )
      .pipe(catchError(handleApiError));
  }

  getInvoice(
    organisationId: string,
    invoiceId: string
  ): Observable<ApiResponse<InvoiceModel> | any> {
    return this.http
      .get(`${this.host}/${organisationId}/invoice/${invoiceId}`)
      .pipe(catchError(handleApiError));
  }

  searchInvoices(organisationId: any, params: any): Observable<any> {
    return this.http
      .get(`${this.host}/${organisationId}/invoice`, {
        observe: 'response',
        params: params,
      })
      .pipe(catchError(handleApiError));
  }

  payInvoice(
    organisationId: string,
    invoiceId: any,
    invoicePaymentModel: any
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/chargeInvoice/${invoiceId}/pay`,
        invoicePaymentModel
      )
      .pipe(catchError(handleApiError));
  }

  voidInvoice(organisationId: any, invoiceId: any, invoice: InvoiceModel) {
    return this.http
      .request(
        'delete',
        `${this.host}/${organisationId}/chargeInvoice/${invoiceId}`,
        {
          body: invoice,
        }
      )
      .pipe(catchError(handleApiError));
  }

  exportJobs(
    organisationId: string,
    organisationJobExport: JobExportSearchCriteriaModel
  ): Observable<any> {
    return this.http
      .post(`${this.host}/${organisationId}/export/job`, organisationJobExport)
      .pipe(catchError(handleApiError));
  }

  exportStatus(organisationId: string, batchExportId: string): Observable<any> {
    return this.http
      .get(`${this.host}/${organisationId}/export/${batchExportId}`)
      .pipe(catchError(handleApiError));
  }

  exportTransactions(
    organisationId: string,
    organisationTransactionExport: TransactionExportCriteriaModel
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/export/transaction`,
        organisationTransactionExport
      )
      .pipe(catchError(handleApiError));
  }

  exportStaff(
    organisationId: string,
    organisationStaffExport: OrganisationStaffExportSearchCriteriaModel
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/export/staff`,
        organisationStaffExport
      )
      .pipe(catchError(handleApiError));
  }

  exportTransactionApprovals(
    organisationId: string,
    organisationTransactionExport: OrganisationTransactionApprovalSearchModel
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/export/transactionApproval`,
        organisationTransactionExport
      )
      .pipe(catchError(handleApiError));
  }

  searchTransactionApprovals(organisationId: string, params: any) {
    return this.http
      .get(`${this.host}/${organisationId}/transactionApproval`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  createOrganisationGroupContract(
    organisationId: string,
    organisationGroupId: string,
    contract: ContractModel
  ): Observable<ApiResponse<ContractModel> | any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/group/${organisationGroupId}/contract`,
        contract
      )
      .pipe(catchError(handleApiError));
  }

  updateOrganisationGroupContract(
    organisationId: string,
    organisationGroupId: string,
    contract: ContractModel
  ): Observable<ApiResponse<ContractModel> | any> {
    return this.http
      .put(
        `${this.host}/${organisationId}/group/${organisationGroupId}/contract`,
        contract
      )
      .pipe(catchError(handleApiError));
  }

  searchOrganisationGroupContracts(
    organisationId: string,
    organisationGroupId: string,
    params: any
  ): Observable<HttpResponse<ApiResponse<ContractModel[]>> | any> {
    return this.http
      .get(
        `${this.host}/${organisationId}/group/${organisationGroupId}/contract`,
        {
          params: encodeParams(params),
          observe: 'response',
        }
      )
      .pipe(catchError(handleApiError));
  }

  getOrganisationGroupContract(
    organisationId: string,
    organisationGroupId: string,
    contractId: string
  ): Observable<ApiResponse<ContractModel> | any> {
    return this.http
      .get(
        `${this.host}/${organisationId}/group/${organisationGroupId}/contract/${contractId}`
      )
      .pipe(catchError(handleApiError));
  }

  deleteOrganisationGroupContract(
    organisationId: string,
    organisationGroupId: string,
    contractId: string
  ): Observable<ApiResponse<null> | any> {
    return this.http
      .delete(
        `${this.host}/${organisationId}/group/${organisationGroupId}/contract/${contractId}`
      )
      .pipe(catchError(handleApiError));
  }

  createOrganisationGroupPaymentMethod(
    organisationId: string,
    organisationGroupId: string,
    paymentMethod: PaymentMethodModel
  ): Observable<ApiResponse<PaymentMethodModel> | any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/group/${organisationGroupId}/paymentMethod`,
        paymentMethod
      )
      .pipe(catchError(handleApiError));
  }

  updateOrganisationGroupPaymentMethod(
    organisationId: string,
    organisationGroupId: string,
    paymentMethod: PaymentMethodModel
  ): Observable<ApiResponse<PaymentMethodModel> | any> {
    return this.http
      .put(
        `${this.host}/${organisationId}/group/${organisationGroupId}/paymentMethod`,
        paymentMethod
      )
      .pipe(catchError(handleApiError));
  }

  searchOrganisationGroupPaymentMethods(
    organisationId: string,
    organisationGroupId: string,
    params: any
  ): Observable<HttpResponse<ApiResponse<PaymentMethodModel[]>> | any> {
    return this.http
      .get(
        `${this.host}/${organisationId}/group/${organisationGroupId}/paymentMethod`,
        {
          params: encodeParams(params),
          observe: 'response',
        }
      )
      .pipe(catchError(handleApiError));
  }

  getOrganisationGroupPaymentMethod(
    organisationId: string,
    organisationGroupId: string,
    paymentMethodId: string
  ): Observable<ApiResponse<PaymentMethodModel> | any> {
    return this.http
      .get(
        `${this.host}/${organisationId}/group/${organisationGroupId}/paymentMethod/${paymentMethodId}`
      )
      .pipe(catchError(handleApiError));
  }

  deleteOrganisationGroupPaymentMethod(
    organisationId: string,
    organisationGroupId: string,
    paymentMethodId: string
  ): Observable<ApiResponse<null> | any> {
    return this.http
      .delete(
        `${this.host}/${organisationId}/group/${organisationGroupId}/paymentMethod/${paymentMethodId}`
      )
      .pipe(catchError(handleApiError));
  }

  linkOrganisationGroupContractToPaymentMethod(
    organisationId: string,
    organisationGroupId: string,
    contractId: string,
    paymentMethod: PaymentMethodModel
  ): Observable<ApiResponse<PaymentMethodModel> | any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/group/${organisationGroupId}/contract/${contractId}/paymentMethod`,
        paymentMethod
      )
      .pipe(catchError(handleApiError));
  }

  applyAdjustment(
    organisationId: string,
    paymentMethodId: string,
    adjustment: any
  ) {
    return this.http
      .post(
        `${this.host}/${organisationId}/paymentMethod/${paymentMethodId}/adjust`,
        adjustment
      )
      .pipe(catchError(handleApiError));
  }

  payChargeInvoice(
    organisationId: string,
    invoiceReference: string,
    payload: any
  ): Observable<ApiResponse<PaymentModel> | any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/chargeInvoice/${invoiceReference}/pay`,
        payload
      )
      .pipe(catchError(handleApiError));
  }

  enableMfa(
    organisationId: string,
    organisationUserId: string
  ): Observable<HttpResponse<ApiResponse<any[]>> | any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/user/${organisationUserId}/enableMfa`,
        {},
        { observe: 'response' }
      )
      .pipe(catchError(handleApiError));
  }

  disableMfa(
    organisationId: string,
    organisationUserId: string
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/${organisationId}/user/${organisationUserId}/disableMfa`,
        {}
      )
      .pipe(catchError(handleApiError));
  }
}
