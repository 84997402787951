import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  DriverModel,
  JobModel,
  OperatorModel,
  OrganisationModel,
  PaymentTransactionModel,
  TravellerModel,
  VehicleModel,
} from '@fleet/model';
import { DateTime } from 'luxon';
import { TicketSearchForm } from '../../ticket-search-form.service';

@Component({
  selector: 'fleet-ticket-search-form',
  templateUrl: './ticket-search-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TicketSearchFormComponent implements OnInit {
  @Output() search = new EventEmitter();

  _driver: DriverModel;
  @Input() set driver(value) {
    this._driver = value;

    if (value) {
      this.form.patchValue({ driverId: this.driver.driverId });
    }
  }
  get driver() {
    return this._driver;
  }

  @Input() vehicle: VehicleModel;
  @Input() traveller: TravellerModel;
  @Input() job: JobModel;
  @Input() transaction: PaymentTransactionModel;

  @Input() organisation: OrganisationModel;
  @Input() operator: OperatorModel;

  constructor(public form: TicketSearchForm) {}

  ngOnInit() {}

  searchTickets() {
    const searchParams = Object.assign({}, this.form.value, {
      limit: 100,
      offset: 0,
    });

    Object.keys(searchParams).forEach((key) => {
      if (searchParams[key] == null || searchParams[key].toString() == '') {
        delete searchParams[key];
      }
    });

    if (this.form.get('fromDate').value) {
      searchParams['fromDate'] = DateTime.fromISO(
        this.form.get('fromDate').value
      ).toISODate();
    }
    if (this.form.get('toDate').value) {
      searchParams['toDate'] = DateTime.fromISO(
        this.form.get('toDate').value
      ).toISODate();
    }

    this.search.emit(searchParams);
  }

  clearAndSearch() {
    this.form.get('toDate').patchValue(null);
    this.form.get('fromDate').patchValue(null);
    if (!this.organisation) {
      this.form.get('organisationId').reset();
    }
    if (!this.operator) {
      this.form.get('operatorId').reset();
    }
    this.form.get('organisationUserId').reset();
    this.form.get('operatorUserId').reset();

    this.searchTickets();
  }
}
