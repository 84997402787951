<ng-container *transloco="let t">
  <fleet-sidebar-form-layout
    class="flex-auto"
    [title]="t('entityPtd.' + title)"
    (closeSidebar)="cancelled.emit()"
  >
    <form
      *ngIf="form"
      [formGroup]="form"
      form="ngForm"
      class="flex flex-col flex-auto"
      (ngSubmit)="refundPTD()"
    >
      <div class="mt-8 px-6 flex flex-col gap-3">
        <mat-form-field>
          <mat-label>
            {{ t('entityPtd.labelAmount', { label: t('entityPtd.' + label) }) }}
          </mat-label>
          <input
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
            currencyMask
            [options]="{ align: 'left', allowNegative: false }"
            matInput
            formControlName="refundAmount"
            [placeholder]="
              t('entityPtd.placeholderAmount', {
                label: t('entityPtd.' + label)
              })
            "
          />
          <mat-hint *ngIf="paymentTransactionGroup?.totalRefundableAmount">
            {{ t('entityPtd.maxRefundableAmountHint') }}
            {{ paymentTransactionGroup?.totalRefundableAmount | currency }}
          </mat-hint>
          <mat-error *ngIf="form.get('refundAmount')?.errors?.required">
            {{ t('entityPtd.refundAmountRequiredError') }}
          </mat-error>
          <mat-error *ngIf="form.get('refundAmount').errors?.max">
            {{
              paymentTransactionGroup?.totalRefundableAmount
                ? t('entityPtd.refundAmountMaxError', {
                    amount:
                      paymentTransactionGroup?.totalRefundableAmount | currency
                  })
                : t('entityPtd.refundAmountMaxErrorFallback')
            }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label> {{ t('entityPtd.reasonLabel') }} </mat-label>
          <textarea
            matInput
            formControlName="reason"
            [placeholder]="t('entityPtd.reasonPlaceholder')"
            maxlength="255"
            matInput
          ></textarea>
          <mat-error> {{ t('entityPtd.reasonRequiredError') }} </mat-error>
        </mat-form-field>
      </div>
      <div class="flex-grow"></div>

      <fuse-alert
        *ngFor="let alert of issues | alertsFromIssues"
        class="pb-3 mx-6"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type"
        [@shake]="alert.type === 'error'"
      >
        {{ alert.message }}
      </fuse-alert>

      <fleet-action-buttons>
        <button mat-button type="button" (click)="cancelled.emit()">
          {{ t('entityPtd.cancelButton') }}
        </button>
        <fleet-progress-button
          class="pl-3"
          [disabled]="!form.valid"
          (onClick)="refundPTD()"
          [state]="{
            buttonLabel: t('entityPtd.' + buttonLabel),
            loading: loading
          }"
        >
        </fleet-progress-button>
      </fleet-action-buttons>
    </form>
  </fleet-sidebar-form-layout>
</ng-container>
