import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AuthService } from '@fleet/auth';
import { fuseAnimations } from '@fleet/fuse';
import { ApiResponse, IssueModel, OperatorModel } from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { Observable } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { OperatorApiService } from '@fleet/api';
import { HttpResponse } from '@angular/common/http';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'fleet-operator-autocomplete',
  templateUrl: './operator-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OperatorAutocompleteComponent),
      multi: true,
    },
  ],
})
export class OperatorAutocompleteComponent
  implements OnInit, ControlValueAccessor
{
  @Output() operatorSelected = new EventEmitter();
  @Input() inSearch = false;
  @Input() placeHolder: string;
  @Input() label: string;
  @Input() prefixIcon = 'search';

  searchControl = new UntypedFormControl();
  searching = false;
  issues: IssueModel[] = [];

  @Input() mode: string;
  operators$: Observable<OperatorModel[]>;

  searchComplete = false;
  @ViewChild('input', { static: true }) input: ElementRef;
  constructor(
    private operatorApiService: OperatorApiService,
    private changeDetectionRef: ChangeDetectorRef,
    private authService: AuthService,
    private networkGroupService: NetworkGroupService
  ) {}

  ngOnInit(): void {
    this.operators$ = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeWhile((value) => value !== 'not-found'),
      tap(() => {
        this.searching = true;
        this.issues = [];
        this.searchComplete = false;
        this.changeDetectionRef.markForCheck();
      }),
      switchMap((value) => {
        if (value) {
          const params = { limit: 25, offset: 0, displayName: value } as any;
          if (value === 'empty-search') {
            delete params['displayName'];
          }
          if (this.authService.networkId) {
            params['networkId'] = this.authService.networkId;
          }
          if (this.networkGroupService.networkGroupId) {
            params['networkGroupId'] = this.networkGroupService.networkGroupId;
          }

          return this.operatorApiService.searchOperators(params).pipe(
            map((resp: HttpResponse<ApiResponse<OperatorModel[]>> | any) => {
              this.searchComplete = true;
              this.searching = false;
              this.changeDetectionRef.markForCheck();
              return resp.body.data;
            }),

            catchError((issues: IssueModel[]) => {
              this.issues = issues;
              this.searchComplete = false;
              this.searching = false;
              this.changeDetectionRef.markForCheck();
              return [];
            })
          );
        } else {
          this.searchComplete = false;
          this.searching = false;
          this.changeDetectionRef.markForCheck();
          return [];
        }
      })
    );
  }

  selectOperator(e: any) {
    let value = e.option.value;
    value = this.mode === 'id' ? value.operatorId : value ? value : '';
    this.operatorSelected.emit(e.option.value);

    this.onChange(value);
    this.onTouched();
  }

  displayFn(operator?: OperatorModel): string | undefined {
    if (operator && operator.displayName) {
      return operator.displayName;
    }
    return '';
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  writeValue(value: any) {
    if (value) {
      this.searching = true;
      this.issues = [];
      if (!value.operatorId || typeof value === 'string') {
        this.operatorApiService
          .getOperator(value)
          .pipe(
            map((resp: ApiResponse<OperatorModel> | any) => resp.data),
            catchError((error) => (this.issues = error)),
            tap(() => (this.searching = false))
          )
          .subscribe((operator: OperatorModel) => {
            // this.selectOperator({ option: { value: r } });
            this.operatorSelected.emit(operator);

            this.setNativeElementValue(operator);
          });
      } else {
        this.searchControl.setValue(value, { emitEvent: false });
      }
    } else {
      this.setNativeElementValue(null);
    }
  }

  private setNativeElementValue(value: OperatorModel) {
    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.value = value ? value?.displayName : '';
      }
    }, 1);
  }
  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onInput() {}

  inputClick(eventTarget: any) {
    if (this.searchControl.value === '' || this.searchControl.value == null) {
      this.searchControl.setValue('empty-search');
      this.searchControl.updateValueAndValidity();
    } else {
      eventTarget.select();
    }
  }
}
