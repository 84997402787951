import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  LoginModel,
  OrganisationUserModel,
  VerificationModel,
} from '@fleet/model';
import { decodeToken, handleApiError } from '@fleet/utilities';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import {
  AuthApiService,
  ValidationApiService,
  VerificationApiService,
} from '@fleet/api';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class OrganisationAuthService extends AuthService {
  nonAuthorisedHttp: HttpClient;
  user: OrganisationUserModel;
  verificationModel: VerificationModel;

  fleetHeaders: HttpHeaders = new HttpHeaders({
    'fleet-channel': 'WEB',
    'fleet-product': 'BUSINESS',
  });

  constructor(
    // private http: HttpClient,
    private backend: HttpBackend,
    authApiService: AuthApiService,
    verificationApiService: VerificationApiService,
    validationApiService: ValidationApiService,
    store: Store<any>,
    router: Router,
    http: HttpClient,
    @Inject('env') env: any
  ) {
    super(
      authApiService,
      verificationApiService,
      router,
      validationApiService,
      store,
      http,
      env
    );
    this.nonAuthorisedHttp = new HttpClient(backend);
    this.host = env.host + paths.organisation;
  }

  signup(payload: any) {
    const headers: HttpHeaders = new HttpHeaders({
      'fleet-product': 'BUSINESS',
      'fleet-channel': 'WEB',
    });
    return this.nonAuthorisedHttp
      .post(`${this.host}/signup`, payload, {
        headers: headers,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  signIn(login: LoginModel) {
    const authdata = btoa(login.username + ':' + login.password);
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Basic ' + authdata,

      'fleet-product': 'BUSINESS',
      'fleet-channel': 'WEB',
    });

    return this.nonAuthorisedHttp
      .post(`${this.host}/signin`, login.verificationToken ? login : null, {
        headers: headers,
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  getUser(payload: any) {
    return this.http
      .get(
        `${this.host}/${payload.organisationId}/user/${payload.organisationUserId}`
      )
      .pipe(catchError((error) => handleApiError(error)));
  }

  refreshToken(jwt: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + jwt,

      'fleet-product': 'BUSINESS',
      'fleet-channel': 'WEB',
    });
    return this.nonAuthorisedHttp
      .get<string>(`${this.host}/refreshToken`, {
        headers: headers,
        observe: 'response',
      })
      .pipe(
        map((resp: any) => {
          const newjwt = resp.headers.get('Authorization');
          return newjwt;
        }),
        catchError((error) => of(null))
      );
  }

  findUser(payload: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  usernameInUse(username: string): Observable<any> {
    return of(false);
  }

  resetMFA(payload: any) {
    return this.http
      .post(
        `${this.host}/${payload.organisationId}/user/${payload.organisationUserId}/resetMFA`,
        {}
      )
      .pipe(catchError((error) => handleApiError(error)));
  }
}
