import { NgModule } from '@angular/core';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule, FuseNavigationModule } from '@fleet/fuse';

import { CommonModule } from '@angular/common';
import { FleetLayoutComponent } from './fleet-layout.component';
import { ButtonComponent, GroupViewControlModule } from '@fleet/ui';
import { UserMenuModule } from '@fleet/auth-shared';

import { FuseLoadingBarModule } from '@fleet/fuse';
import { UpdateBannerComponent, VersionBannerComponent } from '@fleet/version';
import { ExportModule } from '@fleet/export';
import { LanguageSelectorComponent } from '@fleet/internationalization';

@NgModule({
  declarations: [FleetLayoutComponent],
  exports: [FleetLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    FuseFullscreenModule,
    FuseNavigationModule,
    GroupViewControlModule,
    UserMenuModule,
    FuseLoadingBarModule,
    UpdateBannerComponent,
    ExportModule,
    VersionBannerComponent,
    LanguageSelectorComponent,
    ButtonComponent,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class FleetLayoutModule {}
