import { NgModule } from '@angular/core';

import { JobTimePipe } from './job-time.pipe';
import { JobStartTimeComponent } from './job-start-time/job-start-time.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
// import { DateTimePickerModule, TimeInputModule } from '@fleet/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { JobIssuesModule } from '../job-issues/job-issues.module';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TimeControlModule } from '@fleet/custom-controls';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [JobStartTimeComponent, JobTimePipe],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,

    ReactiveFormsModule,
    JobIssuesModule,

    MatDatepickerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    TimeControlModule,
    TranslocoDirective,
  ],
  exports: [JobTimePipe, JobStartTimeComponent],
  providers: [
    provideTranslocoScope({
      scope: 'jobTime',
      loader: lazyTranslationloader,
    }),
  ],
})
export class JobTimeModule {}
