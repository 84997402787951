import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobTravellerComponent } from './job-traveller.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MobileFormatModule, TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { ProgressButtonModule } from '@fleet/shared';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatIntlTelInputModule } from '@fleet/custom-controls';
import { JobTravellerUpdateFormComponent } from './job-traveller-update-form/job-traveller-update-form.component';
import { DialogLayoutModule } from '@fleet/ui';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
export const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    return acc;
  },
  {}
);

@NgModule({
  declarations: [JobTravellerComponent, JobTravellerUpdateFormComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    TitleCaseAndCleanPipeModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MobileFormatModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    ReactiveFormsModule,
    ProgressButtonModule,
    MatTooltipModule,
    NgxMatIntlTelInputModule,
    DialogLayoutModule,

    TranslocoDirective,
  ],
  exports: [JobTravellerComponent],
  providers: [
    provideTranslocoScope({
      scope: 'jobTraveller',
      loader: lazyTranslationloader,
    }),
  ],
})
export class JobTravellerModule {}
