import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeConverter',
  standalone: true,
})
export class TimeConverterPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '00:00:00';
    }

    // Convert string to a number (minutes can be fractional, e.g., 7.14)
    const totalMinutes = parseFloat(value);

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    const seconds = Math.floor(((totalMinutes % 60) - minutes) * 60);

    // Format each part to ensure 2 digits (e.g., 01:02:03)
    const formattedHours = this.padNumber(hours);
    const formattedMinutes = this.padNumber(minutes);
    const formattedSeconds = this.padNumber(seconds);

    // Return formatted time string
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  // Helper function to ensure two digits for hours, minutes, and seconds
  private padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
}
