import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TestStoredCardApiService {
  host: string;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.testStoredcard;
  }

  declineSale(saleId: string): Observable<any> {
    return this.http
      .post(`${this.host}/${saleId}/decline`, {})
      .pipe(catchError(handleApiError));
  }
}
