import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { JobCardModule } from '@fleet/card';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { RelatedEntitySearchLayoutModule } from '@fleet/layout';
import { NotesListCardModule } from '@fleet/note';
import {
  RemovePropertiesPipeModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { SearchChipsListModule } from '@fleet/shared';
import { SidebarHeaderModule } from '@fleet/ui';
import { JobSearchResultsModule } from '../../job-search/components/job-search-results/job-search-results.module';
import { EntityJobSearchFormModule } from './entity-job-search-form/entity-job-search-form.module';
import { EntityJobSearchComponent } from './entity-job-search.component';
import { TicketActionButtonModule } from '@fleet/ticket';

@NgModule({
  declarations: [EntityJobSearchComponent],
  imports: [
    CommonModule,
    RelatedEntitySearchLayoutModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,

    SearchChipsListModule,
    RemovePropertiesPipeModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    SidebarHeaderModule,
    JobCardModule,
    EntityJobSearchFormModule,
    JobSearchResultsModule,
    TitleCaseAndCleanPipeModule,
    NotesListCardModule,
    MatTooltipModule,
    HasFunctionPipeModule,
    TicketActionButtonModule,
  ],
  exports: [EntityJobSearchComponent],
})
export class EntityJobSearchModule {}
