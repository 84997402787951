<ng-container *ngIf="search">
  <fleet-related-entity-search-layout
    *transloco="let t"
    [showFilterButton]="job ? false : true"
    [resultEntityType]="t('entityCallSearch.calls')"
    [title]="t('entityCallSearch.title')"
    #searchLayout
    class="w-full flex"
    [requiresPadding]="true"
    (backdropClosed)="setMode(null)"
    (nextPage)="getNextPage()"
    [searchData]="search"
  >
    <ng-container slot="progress-bar">
      <mat-progress-bar
        *ngIf="search.searching"
        [mode]="'indeterminate'"
      ></mat-progress-bar>
    </ng-container>

    <ng-container slot="search-chips">
      <fleet-search-chips-list
        *ngIf="search.pageData"
        [disabled]="search.searching ? true : false"
        (removeSearchTerm)="removeParamAndSearch($event)"
        [searchParams]="
          search.pageData.params | removeProperties : REMOVED_PROPERTIES
        "
        [paramLabelMap]="paramLabelMap"
      ></fleet-search-chips-list>
    </ng-container>

    <ng-container slot="expanded-search">
      <form
        class="pt-6 pb-4 px-4"
        [formGroup]="searchForm"
        *ngIf="!job && searchForm"
      >
        <ng-container *transloco="let t">
          <div class="flex gap-5 flex-wrap w-full">
            <div class="flex flex-wrap gap-5">
              <fleet-date-with-time-control
                #startDate
                formControlName="fromDate"
                [dateLabel]="t('entityCallSearch.fromDateLabel')"
                [timeLabel]="'&nbsp;'"
                [labelClass]="'text-sm font-light'"
              >
              </fleet-date-with-time-control>

              <fleet-date-with-time-control
                #endDate
                formControlName="toDate"
                [dateLabel]="t('entityCallSearch.toDateLabel')"
                [timeLabel]="'&nbsp;'"
                [labelClass]="'text-sm font-light'"
              >
              </fleet-date-with-time-control>
            </div>

            <ng-container *ngIf="noEntity">
              <mat-form-field class="w-full md:w-100">
                <input
                  [placeholder]="t('entityCallSearch.phoneNumberPlaceholder')"
                  matInput
                  formControlName="phoneNumber"
                />
                <mat-label class="text-sm font-light">
                  <ng-container *transloco="let t">
                    {{ t('entityCallSearch.phoneNumberLabel') }}
                  </ng-container></mat-label
                >
              </mat-form-field>
            </ng-container>
          </div>

          <div class="flex shrink items-center justify-end border-t pt-5">
            <button
              mat-button
              [matTooltip]="t('entityCallSearch.clearSearchTooltip')"
              type="button"
              (click)="clearAndSearchByTabIndex(lastIndexTab)"
            >
              {{ t('entityCallSearch.clearSearchTooltip') }}
            </button>
            <button
              class="px-6 ml-3 bg-primary-600 text-white disabled:bg-gray-200"
              type="button"
              [matTooltip]="t('entityCallSearch.searchCallsTooltip')"
              [disabled]="search.searching || !searchForm.valid ? true : false"
              mat-flat-button
              (click)="populateRouteAndSearchByTabIndex(lastIndexTab)"
            >
              {{ t('entityCallSearch.searchCallsTooltip') }}
            </button>
          </div>
        </ng-container>
      </form>
    </ng-container>

    <fuse-alert
      slot="error"
      *ngFor="let alert of search.issues | alertsFromIssues"
      class=""
      [appearance]="'outline'"
      [showIcon]="true"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>

    <div slot="detail-drawer">
      <fleet-entity-call-detail
        *ngIf="drawerType === 'PREVIEW'"
        [entityType]="entityType"
        [call]="search.selectedPreview"
        [autoPlay]="autoPlay"
        (cancelled)="closeDrawer()"
      >
      </fleet-entity-call-detail>
    </div>

    <div slot="pre-results" class="flex flex-col gap-3">
      <ng-container *transloco="let t">
        <mat-tab-group
          #matTabs
          mat-stretch-tabs="false"
          mat-align-tabs="start"
          *ngIf="
            ('CALL_SEARCH_DIRECT:read' | hasFunction) ||
              (('CALL_SEARCH_CALL_CENTRE:read' | hasFunction) && noEntity) ||
              (securityFunctions?.read && !noEntity);
            else noPermissions
          "
          [animationDuration]="'0'"
          (selectedIndexChange)="populateRouteAndSearchByTabIndex($event)"
        >
          <mat-tab
            [label]="t('entityCallSearch.callCenterTabLabel')"
            *ngIf="
              (('CALL_SEARCH_CALL_CENTRE:read' | hasFunction) && noEntity) ||
              (securityFunctions?.read && !noEntity)
            "
          >
            <ng-template matTabContent>
              <ng-container *ngTemplateOutlet="results"></ng-container>
            </ng-template>
          </mat-tab>
          <mat-tab
            [label]="t('entityCallSearch.directTabLabel')"
            *ngIf="
              (('CALL_SEARCH_DIRECT:read' | hasFunction) && noEntity) ||
              (securityFunctions?.read && !noEntity)
            "
          >
            <ng-template matTabContent>
              <ng-container *ngTemplateOutlet="results"></ng-container>
            </ng-template>
          </mat-tab>
        </mat-tab-group>

        <ng-template #noPermissions>
          <span>{{ t('entityCallSearch.noPermissionsMessage') }}</span>
        </ng-template>
      </ng-container>
    </div>

    <ng-template #results>
      <div class="mt-4">
        <fleet-entity-call-search-results
          *ngIf="search && search.data.length > 0"
          [disablePreview]="!('CALL_DETAIL:read' | hasFunction)"
          [disableRecordings]="!('CALL_RECORDING:read' | hasFunction)"
          #searchResults
          class="flex flex-grow overflow-y-auto"
          [search]="search"
          (previewCall)="previewCall($event)"
          (playRecording)="playRecording($event)"
        >
        </fleet-entity-call-search-results>
      </div>
    </ng-template>
  </fleet-related-entity-search-layout>
</ng-container>
