<ng-container *transloco="let t">
  <mat-form-field class="w-full">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-icon class="icon-size-5" matPrefix>date_range</mat-icon>
    <mat-select #scheduleSelect [formControl]="scheduleControl" name="item">
      <mat-option *ngFor="let option of startTimeOptions" [value]="option">
        {{ t('jobTime.' + option) }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="w-full" *ngIf="scheduleControl.value === 'pickDate'">
    <input
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
      matInput
      #calendarInput
      [placeholder]="t('jobTime.chooseDatePlaceholder')"
      [matDatepicker]="calendarPicker"
      [formControl]="calendarControl"
      [min]="minDate"
    />
    <mat-icon class="icon-size-5" matPrefix>event</mat-icon>
    <mat-datepicker #calendarPicker></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="calendarPicker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>

    <mat-error *ngIf="calendarControl?.errors?.required">
      {{ t('jobTime.validDateRequired') }}
    </mat-error>
  </mat-form-field>

  <mat-form-field
    *ngIf="showControl"
    class="w-full"
    [subscriptSizing]="'dynamic'"
  >
    <input
      id="time-input"
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
      matInput
      type="time"
      style="height: 100%"
      #timeInput
      [formControl]="timeControl"
    />

    <mat-icon class="icon-size-5" matPrefix>schedule</mat-icon>

    <mat-error *ngIf="timeControl?.errors && !timeControl?.errors?.BEFORE_TIME">
      {{ t('jobTime.validTimeFuture') }}
    </mat-error>
    <mat-hint>{{ t('jobTime.timeHint') }}</mat-hint>
    <mat-error *ngIf="timeControl?.errors?.BEFORE_TIME">
      {{ t('jobTime.timeNotInPast') }}
    </mat-error>
  </mat-form-field>

  <mat-error *ngFor="let issue of jobState | jobIssues : 'startTime'">
    {{ issue.message }}
  </mat-error>
</ng-container>
