/* eslint-disable no-empty */
import { Store } from '@ngrx/store';

import * as userAuthActions from './user-auth.actions';
import { UserAuthActionTypes } from './user-auth.actions';
import * as fromUserAuth from './user-auth.reducer';

import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { DateTime, Interval } from 'luxon';
import { EMPTY, of, timer } from 'rxjs';

import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { UserInitialRouteService } from '../../services/user-initial-route.service';

import {
  ApiResponse,
  FunctionPermissionModel,
  LoginModel,
  ValidationModel,
  VerificationModel,
} from '@fleet/model';
import { WebsocketService } from '@fleet/socket';
import {
  JwtHelperService,
  failureNotification,
  handleApiErrorSync,
  successNotification,
} from '@fleet/utilities';
import { AuthService } from '../../services';

import { FleetNavigationService } from '@fleet/navigation';

import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { preserveAndClearKeys } from '../../services/local-storage-manager';
import { GetInvitation } from './user-auth.actions';

import { NetworkGroupService } from '@fleet/network-group';
import { ProductConfigurationService } from '@fleet/product-configuration';

import {
  getUserAuthState,
  loginModel,
  samlQueryParams,
  verificationModel,
} from './user-auth.selectors';
import { MatDialog } from '@angular/material/dialog';
import { ChangeMfaDialogComponent } from '../../components/change-mfa-dialog/change-mfa-dialog.component';
import { paths } from '@fleet/environment';

@Injectable()
export class UserAuthEffects {
  renderer: Renderer2;
  fleetProduct: string;
  samlPostUrl: string;
  constructor(
    private store$: Store<fromUserAuth.UserAuthState>,
    private router: Router,
    private actions$: Actions,
    private service: AuthService,
    private jwtHelper: JwtHelperService,
    private socketService: WebsocketService,
    private rendererFactory: RendererFactory2,
    private onScreenNotificationService: OnscreenNotificationService,
    private networkGroupService: NetworkGroupService,
    private productConfigurationService: ProductConfigurationService,
    private matDialog: MatDialog,
    private userInitialRouteService: UserInitialRouteService,
    private fleetNNavigationService: FleetNavigationService,
    @Inject('env') env: any
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.fleetProduct = env.fleetProduct;
    this.samlPostUrl = env.host + paths.samlAuth;
  }

  samlLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthActions.samlLogin),
        map((action: any) => action.payload),
        mergeMap((payload) => {
          const urlSearchParams = new URLSearchParams(payload.params);
          return this.service.samlLogin(payload.login, urlSearchParams).pipe(
            mergeMap((resp: HttpResponse<any>) => {
              if (resp.status === 202) {
                const verification = resp.body as VerificationModel;
                if (verification.verificationId) {
                  //configured
                  const verifyPayload = {
                    payload: {
                      verification: verification,
                      routedFrom: 'saml-login',
                    },
                  };
                  this.store$.dispatch(
                    userAuthActions.verifyMFA(verifyPayload)
                  );
                } else {
                  // not configured
                  this.store$.dispatch(
                    userAuthActions.configureMFA({
                      payload: {
                        verification: verification,
                        from: 'saml-login',
                      },
                    })
                  );
                }
              } else {
                urlSearchParams.append(
                  'ssoToken',
                  resp.headers.get('x-sso-token')
                );

                //redirect back to sso client
                window.location.replace(
                  this.samlPostUrl + `?${urlSearchParams.toString()}`
                );
              }
              return EMPTY;
            }),
            catchError((error: any) => {
              this.store$.dispatch(
                userAuthActions.samlLoginFailure({ payload: error })
              );
              return EMPTY;
            })
          );
        })
      ),
    { dispatch: false }
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.Login),
      map((action: userAuthActions.Login) => action.payload),
      mergeMap((payload) =>
        this.service.signIn(payload as any).pipe(
          mergeMap((resp: HttpResponse<ApiResponse<any>>) => {
            if (resp.status === 202) {
              // Handle specific logic for 202 MFA Challenge
              const verification = resp.body.data as VerificationModel;
              if (verification.verificationId) {
                //configured
                const verifyPayload = {
                  payload: {
                    verification: verification,
                    routedFrom: 'mfa-login',
                  },
                };

                return [userAuthActions.verifyMFA(verifyPayload)];
              } else {
                // not configured
                return [
                  userAuthActions.configureMFA({
                    payload: { verification: verification, from: 'login' },
                  }),
                ];
              }
            } else {
              const authHeader = resp.headers.get('Authorization');
              const jwt = authHeader.replace('Bearer ', '');
              //set permissions
              this.service.setPermissions(resp.body.data.permissions);
              if (this.fleetProduct === 'DRIVER') {
                try {
                  (window as any).MobileCallBack.postMessage(
                    JSON.stringify({
                      action: 'SIGNIN',
                      data: {
                        loginModel: {
                          jwtToken: jwt,
                          username: payload.username,
                          password: payload.password,
                        } as LoginModel,
                      },
                    })
                  );
                } catch (error) {
                  console.log(error);
                }

                try {
                  (window as any).flutter_inappwebview.callHandler(
                    'MobileCallBack',

                    JSON.stringify({
                      action: 'SIGNIN',
                      data: {
                        loginModel: {
                          jwtToken: jwt,
                          username: payload.username,
                          password: payload.password,
                        } as LoginModel,
                      },
                    })
                  );
                } catch (error) {
                  console.log(error);
                }
              }

              return [
                new userAuthActions.SetJWT(jwt),
                new userAuthActions.LoginSuccess(jwt),
              ];
            }
          }),

          catchError((error) => of(new userAuthActions.LoginFailure(error)))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.LoginSuccess),
      map((action: userAuthActions.LoginSuccess) => action.payload),
      map((jwt) => {
        const decode = this.jwtHelper.decodeToken(jwt);

        return new userAuthActions.GetPermissions({
          decodedJwt: decode,
          route: true,
        });
      })
    )
  );

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.LoginFailure),
        tap(() => {
          this.router.navigate(['/auth/login']);
        })
      ),
    { dispatch: false }
  );

  getPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.GetPermissions),
      mergeMap((action: any) =>
        this.service.getPermissions().pipe(
          map(
            (resp: ApiResponse<FunctionPermissionModel[]>) =>
              new userAuthActions.GetPermissionsSuccess({
                permissions: resp.data,
                decodedJwt: action.payload.decodedJwt,
                route: action.payload.route,
              })
          ),
          catchError((error) =>
            of(new userAuthActions.GetPermissionsFailure(error))
          )
        )
      )
    )
  );

  getPermissionsSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.GetPermissionsSuccess),
      tap((action: userAuthActions.GetPermissionsSuccess) => {
        this.service.setPermissions(action.payload.permissions);
      }),
      map(
        (action: userAuthActions.GetPermissionsSuccess) =>
          new userAuthActions.GetUser({
            decodedJwt: action.payload.decodedJwt,
            route: action.payload.route,
          })
      )
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.GetUser),
      map((action: userAuthActions.GetUser) => action.payload),
      mergeMap((payload: any) =>
        this.service.getUser(payload.decodedJwt).pipe(
          map(
            (resp: ApiResponse<any>) =>
              new userAuthActions.GetUserSuccess({
                user: resp.data,
                route: payload.route,
              })
          ),
          catchError((error) => of(new userAuthActions.GetUserFailure(error)))
        )
      )
    )
  );

  getUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.GetUserSuccess),
        map((action: userAuthActions.GetUserSuccess) => action.payload),
        tap((payload: { user: any; route: boolean }) => {
          // this.socketService.init(
          //   `organisation.${user.organisationId}-${user.organisationUserId}`
          // );

          const jwt = this.service.accessToken;
          const decode = this.jwtHelper.decodeToken(jwt);

          this.service.setUser(payload.user);

          this.fleetNNavigationService.configureMenuForUser(
            decode.type,
            this.service.permissions.value,
            payload.user
          );

          // set network group service if not network user
          if (
            decode.type !== 'NETWORK' &&
            this.productConfigurationService.networkConfig
          ) {
            this.networkGroupService.getNetworkGroup(
              this.productConfigurationService.networkConfigValue
                .networkGroupId,
              this.productConfigurationService.networkConfigValue.networkId
            );
          }

          //check for kiosk mode in business and route if set
          if (this.fleetProduct === 'BUSINESS') {
            if (localStorage.getItem('kiosk_mode') === 'true') {
              this.router.navigate(['/kiosk']);
            }
          }
          if (payload.route) {
            this.userInitialRouteService.routeUser(
              decode.type,
              this.service.permissions.value,
              payload.user
            );
          }
        })
      ),
    { dispatch: false }
  );

  setJWT$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.SetJWT),
        map((action: userAuthActions.SetJWT) => action.jwt),
        tap((jwt) => {
          if (jwt) {
            this.service.accessToken = jwt;

            const decodedToken = this.jwtHelper.decodeToken(jwt);
            localStorage.setItem('username', decodedToken.username);

            const start = DateTime.now();
            const finish = DateTime.fromMillis(decodedToken.exp);

            const millisecondsToLock = Interval.fromDateTimes(
              start,
              finish
            ).toDuration().milliseconds;

            const lockTimer$ = timer(millisecondsToLock);
            lockTimer$.subscribe((tick) => {
              this.store$.dispatch(new userAuthActions.Lock());
            });
          }
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.Logout),
        map((action: userAuthActions.Logout) => action.payload),
        tap((samlLoginOverideParams) => {
          if (this.fleetProduct === 'DRIVER') {
            try {
              (window as any).MobileCallBack.postMessage(
                JSON.stringify({ action: 'LOGOUT' })
              );
            } catch (error) {}

            try {
              (window as any).flutter_inappwebview.callHandler(
                'MobileCallBack',
                JSON.stringify({
                  action: 'LOGOUT',
                })
              );
            } catch (error) {}
          }
          this.socketService.close();
          this.service.setUser(null);
          this.fleetNNavigationService.clearNavigation();
          if (samlLoginOverideParams) {
            this.router.navigate(['/auth/saml/login'], {
              queryParams: {
                ...samlLoginOverideParams,
                reset: '1',
                r: Math.random(),
              },
            });
          } else {
            this.router.navigate(['/auth/login'], {
              queryParams: { reset: '1', r: Math.random() },
            });
          }

          preserveAndClearKeys();
        })
      ),
    { dispatch: false }
  );

  autologin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.AutoLogin),
      map((action: userAuthActions.AutoLogin) => action.jwt),
      mergeMap((oldJwt) =>
        this.service.checkToken().pipe(
          mergeMap((newJwt: any) => {
            let actions = [];
            if (newJwt == 'error') {
              actions.push(new userAuthActions.AutoLoginFailure());
            } else {
              const decode = this.jwtHelper.decodeToken(newJwt);
              if (newJwt != oldJwt) {
                actions = [
                  new userAuthActions.SetJWT(newJwt),
                  new userAuthActions.GetPermissions({
                    decodedJwt: decode,
                    route: false,
                  }),
                ];
              } else {
                actions = [
                  new userAuthActions.GetPermissions({
                    decodedJwt: decode,
                    route: false,
                  }),
                ];
              }
            }

            //route user

            return actions;
          }),
          catchError((error: any) => {
            return of(new userAuthActions.AutoLoginFailure());
          })
        )
      )
    )
  );

  Lock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.Lock),
        tap(() => {
          this.socketService.close();
          this.renderer.addClass(document.body, 'in_state-locked');
        })
      ),
    { dispatch: false }
  );

  unLock$ = this.actions$.pipe(
    ofType(UserAuthActionTypes.UnLock),
    map((action: userAuthActions.UnLock) => action.payload),
    mergeMap((payload: any) =>
      this.service
        .signIn({
          username: payload.username,
          password: payload.password,
        } as any)
        .pipe(
          mergeMap((resp: any) => {
            const jwtToken = resp.headers.get('Authentication');

            return [
              new userAuthActions.SetJWT(jwtToken),
              new userAuthActions.UnLockSuccess(),
            ];
          }),
          catchError((error) => of(new userAuthActions.UnLockFailure(error)))
        )
    )
  );

  unLockSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.UnLockSuccess),
        tap(() => {
          this.renderer.removeClass(document.body, 'in_state-locked');
        })
      ),
    { dispatch: false }
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.ForgotPassword),
      map((action: userAuthActions.ForgotPassword) => action.payload),
      mergeMap((payload) =>
        this.service.forgotPassword(payload).pipe(
          map(
            (resp: ApiResponse<VerificationModel> | any) =>
              new userAuthActions.ForgotPasswordSuccess(resp.data)
          ),
          catchError((error) =>
            of(new userAuthActions.ForgotPasswordFailure(error))
          )
        )
      )
    )
  );

  forgotPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.ForgotPasswordSuccess),
        tap(() => this.router.navigate(['/auth/verify']))
      ),
    { dispatch: false }
  );

  newPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.NewPassword),
      map((action: userAuthActions.NewPassword) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([payload, verificationModel]) => {
        const loginModel = {
          username: verificationModel.email,
          password: payload.password,
          verificationToken: verificationModel.token,
        };
        return this.service.resetPassword(loginModel).pipe(
          mergeMap((resp: any) => {
            const authHeader = resp.headers.get('Authorization');
            const jwt = authHeader.replace('Bearer ', '');
            return [
              new userAuthActions.SetJWT(jwt),
              new userAuthActions.LoginSuccess(jwt),
            ];
          }),
          catchError((error) =>
            of(new userAuthActions.NewPasswordFailure(error))
          )
        );
      })
    )
  );

  newPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.NewPasswordSuccess),
        tap(() => {
          // this.snackBar.open('Password Successfully Reset', 'Dismiss', {
          //   duration: 3000,
          // });
          this.router.navigate(['/auth/login']);
          try {
            (window as any).MobileCallBack.postMessage(
              JSON.stringify({
                action: 'LOGOUT',
              })
            );
          } catch (error) {}

          try {
            (window as any).flutter_inappwebview.callHandler(
              'MobileCallBack',
              JSON.stringify({
                action: 'LOGOUT',
              })
            );
          } catch (error) {}
        })
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.resetPassword.type),
      map((action: any) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([payload, verificationModel]) => {
        const loginModel = {
          username: verificationModel.email,
          password: payload.password,
          verificationToken: verificationModel.token,
        };
        return this.service.resetPassword(loginModel).pipe(
          mergeMap((resp: any) => {
            const authHeader = resp.headers.get('Authorization');
            if (authHeader) {
              const jwt = authHeader.replace('Bearer ', '');
              return [
                new userAuthActions.SetJWT(jwt),
                new userAuthActions.LoginSuccess(jwt),
              ];
            } else {
              this.onScreenNotificationService.setNotification({
                ...successNotification,
                title: 'Password successfully reset! ',
                subTitle: 'Please login with your new password',
              } as OnScreenNotification);
              return [new userAuthActions.Logout()];
            }
          }),
          catchError((error) => of(userAuthActions.resetPasswordFailure(error)))
        );
      })
    )
  );

  verifyCodeForEmailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.verifyCodeForEmailSuccess.type),
      map((action: any) => action.payload),
      tap((payload) => {
        this.router.navigate(['auth/verify/' + payload.data.activationKey]);
      })
    )
  );

  verify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.Verify),
      map((action: userAuthActions.Verify) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([payload, verification]) => {
        const verificationPayload = {
          verificationId: verification.verificationId,
          code: payload.code,
        } as VerificationModel;

        return this.service.verify(verificationPayload).pipe(
          map(
            (resp: ApiResponse<VerificationModel> | any) =>
              new userAuthActions.VerifySuccess({
                verification: resp.data,
                routedFrom: payload.routedFrom,
              })
          ),
          catchError((error) => of(new userAuthActions.VerifyFailure(error)))
        );
      })
    )
  );

  verifyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.VerifyFailure),
        map((action: userAuthActions.VerifyFailure) => action.payload),
        withLatestFrom(this.store$.select(getUserAuthState)),
        tap(([payload, state]) => {
          console.log('verify failure', payload);
          //check if its a 401 via message
          if (payload.status === 401) {
            const issues = handleApiErrorSync(payload);
            this.onScreenNotificationService.setNotification({
              ...failureNotification,
              title: 'Issue with verification',
              subTitle: issues[0]?.message,
            });
            this.store$.dispatch(
              new userAuthActions.Logout(state.samlQueryParams)
            );
          }
        })
      ),

    { dispatch: false }
  );

  verifySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.VerifySuccess),
        map((action: userAuthActions.VerifySuccess) => action.payload),
        tap((payload) => {
          this.router.navigate(['/auth/verified'], {
            queryParams: {
              verificationToken: payload.verification.token,
              newUser: payload.verification.newUser,
              routedFrom: payload.routedFrom,
              maskedEmail: payload.verification.maskedEmail,
              firstName: payload.verification.firstName,
              email: payload.verification.email,
              username: payload.verification.username,
              resetPassword: payload.verification.resetPassword,
            },
          });
        })
      ),
    { dispatch: false }
  );

  verifyComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.VerifyComplete),
        map((action: userAuthActions.VerifyComplete) => action.payload),
        withLatestFrom(this.store$.select(getUserAuthState)),
        tap(([payload, state]) => {
          if (payload.resetPassword === 'true') {
            this.router.navigate(['auth/reset-password']);
          } else {
            if (payload.routedFrom === 'invitation') {
              this.router.navigate(['auth/signup']);
            } else if (payload.routedFrom === 'forgot-password') {
              this.router.navigate(['auth/reset-password']);
            } else if (payload.routedFrom === 'change-number') {
              this.store$.dispatch(
                new userAuthActions.ChangePhoneNumber(<LoginModel>{
                  verificationToken: payload.verificationToken,
                })
              );
            } else if (payload.routedFrom === 'change-username') {
              this.store$.dispatch(
                new userAuthActions.ChangeUsername(<LoginModel>{
                  verificationToken: payload.verificationToken,
                  username: payload.email,
                })
              );
            } else if (payload.newUser) {
              switch (this.fleetProduct) {
                case 'BUSINESS':
              }
              this.router.navigate(['/auth/plans']);
            } else if (payload.routedFrom === 'mfa-login') {
              this.store$.dispatch(new userAuthActions.Login(state.loginModel));
            } else if (payload.routedFrom === 'saml-login') {
              this.store$.dispatch(
                userAuthActions.samlLogin({
                  payload: {
                    login: {
                      ...state.loginModel,
                      verificationToken: payload.verificationToken,
                    },
                    params: state.samlQueryParams,
                  },
                })
              );
            } else {
              this.router.navigate(['auth/existing-user']);
            }
          }
          //exisitn
        })
      ),
    { dispatch: false }
  );

  changeUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.ChangeUsername),
      mergeMap((action: userAuthActions.ChangeUsername) => {
        return this.service.changeUsername(action.payload).pipe(
          map((resp: HttpResponse<any>) => {
            const jwtHeader = resp.headers.get('Authorization');
            const jwt = jwtHeader.replace('Bearer ', '');

            return new userAuthActions.ChangeUsernameSuccess({
              jwtToken: jwt,
              username: action.payload.username,
            });
          }),
          catchError((error) =>
            of(new userAuthActions.ChangeUsernameFailure(error))
          )
        );
      })
    )
  );

  changeUsernameSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.ChangeUsernameSuccess),
        map((action: userAuthActions.ChangeUsernameSuccess) => action.payload),
        tap((payload: LoginModel) => {
          localStorage.setItem('access_token', payload.jwtToken);

          try {
            (window as any).MobileCallBack.postMessage(
              JSON.stringify({
                action: 'CHANGE_USERNAME',
                data: {
                  loginModel: payload,
                },
              })
            );
          } catch (error) {
            console.log(error);
          }

          try {
            (window as any).flutter_inappwebview.callHandler(
              'MobileCallBack',
              JSON.stringify({
                action: 'CHANGE_USERNAME',
                data: {
                  loginModel: payload,
                },
              })
            );
          } catch (error) {
            console.log(error);
          }
        })
      ),
    { dispatch: false }
  );

  verifyPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.VerifyPhoneNumber),
      map((action: userAuthActions.VerifyPhoneNumber) => action.payload),
      mergeMap((payload) => {
        const includeJwt =
          payload.routedFrom === 'change-number' ? true : false;
        delete payload['routedFrom'];
        return this.service.verifyPhoneNumber(payload, includeJwt).pipe(
          map((resp: ApiResponse<VerificationModel>) => {
            return new userAuthActions.VerifyPhoneNumberSuccess(resp.data);
          }),
          catchError((error) =>
            of(new userAuthActions.VerifyPhoneNumberFailure(error))
          )
        );
      })
    )
  );

  verifyPhoneSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.VerifyPhoneNumberSuccess),
        tap((action: userAuthActions.VerifyPhoneNumberSuccess) => {
          this.router.navigate(['auth/verify']);
        })
      ),
    { dispatch: false }
  );

  userLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.UserLookup),
      map((action: userAuthActions.UserLookup) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([payload, verification]) => {
        const lookupPayload = {
          ...payload,
          verificationToken: verification.token,
        };
        return this.service.findUser(lookupPayload).pipe(
          map(
            (resp: ApiResponse<any>) =>
              new userAuthActions.UserLookupSuccess(resp.data)
          ),
          catchError((error) =>
            of(new userAuthActions.UserLookupFailure(error))
          )
        );
      })
    )
  );

  userLookupSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.UserLookupSuccess),
        map((action: userAuthActions.UserLookupSuccess) => action.payload),
        tap((payload: ValidationModel[]) => {
          if (payload.length === 1) {
            this.store$.dispatch(
              userAuthActions.selectValidationModel({
                validationModel: payload[0],
              })
            );
            //either new or existing
          } else {
            //multiple results, user needs to select
          }
        })
      ),
    { dispatch: false }
  );

  selectValidationModel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthActions.selectValidationModel),
        tap((action) => {
          if (action.validationModel.accountCreationRequired) {
            this.router.navigate(['/auth/signup']);
          } else {
            this.router.navigate(['auth/existing-user']);
          }
        })
      ),
    { dispatch: false }
  );

  validateUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.ValidateUsername),
      mergeMap((action: userAuthActions.ValidateUsername) => {
        return this.service.usernameInUse(action.payload).pipe(
          map((resp: ApiResponse<ValidationModel>) => {
            return new userAuthActions.ValidateUsernameSuccess(resp.data);
          }),
          catchError((error) =>
            of(new userAuthActions.ValidateUsernameFailure(error))
          )
        );
      })
    )
  );

  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.VerifyEmail),
      map((action: userAuthActions.VerifyEmail) => action.payload),
      withLatestFrom(this.store$.select(verificationModel)),
      mergeMap(([verifyPayload, verificationModel]) => {
        const payload = Object.assign({}, verificationModel, verifyPayload);
        return this.service.verifyEmail(payload).pipe(
          map((resp: ApiResponse<VerificationModel>) => {
            return new userAuthActions.VerifyEmailSuccess(resp.data);
          }),
          catchError((error) =>
            of(new userAuthActions.VerifyEmailFailure(error))
          )
        );
      })
    )
  );

  verifyEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.VerifyEmailSuccess),
        tap((payload) => {
          this.router.navigate(['/auth/verify']);
        })
      ),
    { dispatch: false }
  );

  getInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.GetInvitation),
      map((action: GetInvitation) => action.payload),
      tap(() => {
        localStorage.clear();
        this.socketService.close();
      }),
      mergeMap((activationKey: string) => {
        return this.service.getInvitation(activationKey).pipe(
          map(
            (resp: any) => new userAuthActions.GetInvitationSuccess(resp.data)
          ),
          catchError((error) =>
            of(new userAuthActions.GetInvitationFailure(error))
          )
        );
      })
    )
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAuthActionTypes.Signup),
      map((action: userAuthActions.Signup) => action.payload),
      withLatestFrom(this.store$.select(getUserAuthState)),
      mergeMap(([payload, state]) => {
        const signUpPayload = Object.assign({}, state.signupModel, payload);
        if (
          state.userLookupValidation &&
          state.userLookupValidation.driverLeadId
        ) {
          signUpPayload['driverLeadId'] =
            state.userLookupValidation.driverLeadId;
        }

        return this.service.signup(signUpPayload).pipe(
          mergeMap((resp: HttpResponse<ApiResponse<any>>) => {
            if (resp.status === 202) {
              // Handle specific logic for 202 MFA Configuration Requirement
              const verification = resp.body.data as VerificationModel;
              return [
                userAuthActions.configureMFA({
                  payload: { verification: verification, from: 'signup' },
                }),
              ];
            } else {
              const jwtHeader = resp.headers.get('Authorization');
              const jwt = jwtHeader.replace('Bearer ', '');
              this.service.setPermissions(resp.body.data.permissions);

              if (this.fleetProduct === 'DRIVER') {
                //EMIT OUT TO APP SIGNIN DETAILS
                try {
                  (window as any).MobileCallBack.postMessage(
                    JSON.stringify({
                      action: 'SIGNIN',
                      data: {
                        loginModel: {
                          jwtToken: jwt,
                          username: signUpPayload.username,
                          password: signUpPayload.password,
                        } as LoginModel,
                      },
                    })
                  );
                } catch (error) {
                  console.log(error);
                }

                try {
                  (window as any).flutter_inappwebview.callHandler(
                    'MobileCallBack',

                    JSON.stringify({
                      action: 'SIGNIN',
                      data: {
                        loginModel: {
                          jwtToken: jwt,
                          username: signUpPayload.username,
                          password: signUpPayload.password,
                        } as LoginModel,
                      },
                    })
                  );
                } catch (error) {
                  console.log(error);
                }
              }
              const driver = resp.body.data;

              return [
                new userAuthActions.SetJWT(jwt),
                new userAuthActions.SignupSuccess(driver),
              ];
            }
          }),
          catchError((error) => {
            return of(new userAuthActions.SignupFailure(error));
          })
        );
      })
    )
  );

  signUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAuthActionTypes.SignupSuccess),
        tap((action: userAuthActions.SignupSuccess) => {
          this.service.setPermissions(action.payload.permissions);
          this.store$.dispatch(
            new userAuthActions.GetUserSuccess({
              user: action.payload,
              route: true,
            })
          );
        })
      ),
    { dispatch: false }
  );

  verifyMFA$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthActions.verifyMFA),
        tap((action) => {
          if (action.payload) {
            //get user

            this.router.navigate(['/auth/verify']);
          }
        })
      ),
    { dispatch: false }
  );

  sendMFACode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.sendMFACode.type),
      map((action: any) => action.payload),
      withLatestFrom(this.store$.select(loginModel)),
      mergeMap(([payload, login]) => {
        return this.service.sendMFACode(payload, login).pipe(
          map((resp: any) =>
            userAuthActions.sendMFACodeSuccess({ payload: resp.data })
          ),
          catchError((error) =>
            of(userAuthActions.sendMFACodeFailure({ payload: error }))
          )
        );
      })
    )
  );

  configureMFA$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthActions.configureMFA),
        withLatestFrom(this.store$.select(getUserAuthState)),
        tap(([action, state]) => {
          // Open the ChangeMfaDialogComponent with the payload
          const dialogRef = this.matDialog.open(ChangeMfaDialogComponent, {
            disableClose: true,
          });
          dialogRef.componentInstance.verification =
            action.payload.verification;
          dialogRef.componentInstance.login = state.loginModel;
          dialogRef.componentInstance.noLogout = true;
          dialogRef.componentInstance.samlLoginParams = state.samlQueryParams;
          dialogRef.componentInstance.changeMFASuccess.subscribe(
            (verification: VerificationModel) => {
              this.store$.dispatch(
                userAuthActions.configureMFASuccess({
                  payload: {
                    verification: verification,
                    from: action.payload.from,
                  },
                })
              );
            }
          );
          dialogRef.afterClosed().subscribe({
            next: (result) => {
              if (result === 'cancelled') {
                if (
                  action.payload.from === 'login' ||
                  action.payload.from === 'saml-login'
                ) {
                  this.store$.dispatch(
                    new userAuthActions.Logout(state.samlQueryParams)
                  );
                }
              }
            },
          });
        })
      ),
    { dispatch: false }
  );

  configureMFASuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthActions.configureMFASuccess),
      withLatestFrom(this.store$.select(samlQueryParams)),
      map(([action, samlQueryParams]) => {
        return new userAuthActions.Logout(samlQueryParams);
      })
    )
  );
}
