import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocaleService } from '@fleet/locale';
import {
  NetworkGroupModel,
  PaymentMethodModel,
  PaymentMethodSearchResultModel,
} from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { evereeLogo } from '@fleet/shared';
import { internationalizedDate, titleCaseClean } from '@fleet/utilities';
import { DateTime } from 'luxon';
@Component({
  selector: 'fleet-payment-method-list-item',
  templateUrl: './payment-method-list-item.component.html',
  styleUrls: ['./payment-method-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodListItemComponent implements OnInit {
  evereeLogo = evereeLogo;

  @Input() disableActions = false;
  config: {
    paymentType: string;
    cardType: string;
    displayOne: string;
    displayTwo: string;
    detailsOne: string;
    detailsTwo: string;
    detailsThree: string;
    detailsFour: string;
    date: DateTime;
    status: string;
    secondaryStatus: string;
    icons: boolean;
    verificationInfo?: {
      cardVerificationStatus: string;
      cardVerificationType: string;
      cardVerified: string;
      cardThreeDsEnrolled: string;
    };
  };
  @Input() isDefault: boolean;
  _paymentMethod: PaymentMethodSearchResultModel | PaymentMethodModel | any;
  @Input() set paymentMethod(
    value: PaymentMethodSearchResultModel | PaymentMethodModel | any
  ) {
    this._paymentMethod = value;

    let currencySymbol = this.localeService.getSymbol();

    if (value) {
      this.config = {
        paymentType: null,
        cardType: null,
        displayOne: null,
        displayTwo: null,
        detailsOne: null,
        detailsTwo: null,
        detailsThree: null,
        detailsFour: null,
        date: null,
        status: null,
        secondaryStatus: null,
        icons: true,
      };
      this.changeDetectorRef.markForCheck();
      if (value.paymentType) {
        this.config.paymentType = value.paymentType;
      }
      if (value.type) {
        this.config.paymentType = value.type;
      }
      switch (this.config.paymentType) {
        case 'STORED_CARD':
          if (value.card && value.card?.cardType) {
            this.config.cardType = value.card?.cardType;
            this.config.secondaryStatus = value.card?.status;
            this.config.status = value.status;
            if (this.mode === 'condensed' || this.mode === 'condensed_2') {
              this.config.displayOne = value.card.maskedCardNumber;
            } else {
              this.config.displayOne = value.card?.cardholderName;
            }
          } else {
            this.config.cardType = value.cardType;
            this.config.secondaryStatus = value.cardStatus;
            this.config.status = value.status;
            if (this.mode === 'condensed' || this.mode === 'condensed_2') {
              this.config.displayOne = value.maskedCardNumber;
            } else {
              this.config.displayOne = value.cardholderName;
            }
            this.config.verificationInfo = {
              cardVerificationStatus: value.cardVerificationStatus,
              cardVerificationType: value.cardVerificationType,
              cardVerified: value.cardVerified
                ? DateTime.fromISO(value.cardVerified).toFormat('ff')
                : null,
              cardThreeDsEnrolled:
                value.cardThreeDsEnrolled === 'Y' ? 'Yes' : 'No',
            };
          }
          this.config.detailsOne = value.description;
          this.config.icons = true;
          if (value.organisationId) {
            this.config.displayOne = value.displayName;
            if (value.card && value.card?.cardType) {
              this.config.displayTwo = value.card?.cardholderName;
            } else {
              this.config.displayTwo = value.cardholderName;
            }
          }
          break;
        case 'ORGANISATION_ACCOUNT':
          if (value.account && value.account.accountName) {
            this.config.displayOne = value.displayName;
            this.config.displayTwo = value.contractName;
          } else {
            this.config.displayOne = value.displayName;
            this.config.displayTwo = value.contractName;
          }
          this.config.status = value.status;
          this.config.icons = true;
          break;
        case 'BANK_ACCOUNT':
          if (value.account && value.account.accountName) {
            this.config.displayOne = value.displayName;
            this.config.displayTwo = value.account.accountName;
            this.config.detailsOne = value.account.bankCode;
            this.config.detailsTwo = value.account.accountNumber;

            if (value.account.nextDisbursementDate) {
              const nextDisbursementDate = internationalizedDate(
                value.account.nextDisbursementDate,
                'CONDENSED_DATE',
                false,
                null,
                this.localeService.getLocale()
              );

              this.config.detailsThree =
                'Next disbursement is on ' + nextDisbursementDate;
              this.config.detailsFour =
                titleCaseClean(value.account.disbursementFrequency) +
                ' disbursement';
            }
          } else {
            this.config.displayOne = value.displayName;
            this.config.displayTwo = value.accountName;

            if (this.networkGroup.locale === 'en-US') {
              this.config.detailsOne = 'RTN: ' + value.bankCode;
            } else {
              this.config.detailsOne = 'BSB: ' + value.bankCode;
            }

            this.config.detailsTwo = 'Acc: ' + value.accountNumber;

            if (value.nextDisbursementDate) {
              const nextDisbursementDate = internationalizedDate(
                value.nextDisbursementDate,
                'CONDENSED_DATE',
                false,
                null,
                this.localeService.getLocale()
              );

              if (nextDisbursementDate) {
                this.config.detailsThree =
                  'Next disbursement is on ' + nextDisbursementDate;
              }

              if (value.disbursementFrequenc) {
                this.config.detailsFour =
                  titleCaseClean(value.disbursementFrequency) + ' disbursement';
              }
            }
          }
          this.config.icons = true;
          this.config.status = value.status;
          break;
        // case 'BANK_ACCOUNT':
        //   //thgere is a balance and a next seettlement date and disbursement frequency
        //   if (value.account && value.account.accountName) {
        //     const nextDisbursementDate = internationalizedDate(
        //       value.account.nextDisbursementDate,
        //       'CONDENSED_DATE',
        //       false,
        //       null,
        //       this.localeService.getLocale()
        //     );
        //     this.config.displayOne = value.account.accountName;

        //     if (value.account.accountBalance) {
        //       this.config.displayTwo =
        //         'Balance: ' + currencySymbol + value.account.accountBalance;
        //     }

        //     this.config.detailsOne = value.account.bankCode;

        //     this.config.detailsTwo = value.account.accountNumber;

        //     this.config.detailsThree =
        //       'Next disbursement is on ' + nextDisbursementDate;
        //     this.config.detailsFour =
        //       titleCaseClean(value.account.disbursementFrequency) +
        //       ' disbursement';
        //   } else {
        //     const nextDisbursementDate = internationalizedDate(
        //       value.nextDisbursementDate,
        //       'CONDENSED_DATE',
        //       false,
        //       null,
        //       this.localeService.getLocale()
        //     );
        //     this.config.displayOne = value.accountName;

        //     if (value.accountBalance) {
        //       this.config.displayTwo =
        //         'Balance: ' + currencySymbol + value.accountBalance;
        //     }

        //     if (this.networkGroup.locale === 'en-US') {
        //       this.config.detailsOne = 'RTN: ' + value.bankCode;
        //     } else {
        //       this.config.detailsOne = 'BSB: ' + value.bankCode;
        //     }

        //     this.config.detailsTwo = 'Acc: ' + value.accountNumber;

        //     if (nextDisbursementDate) {
        //       this.config.detailsThree =
        //         'Next disbursement is on ' + nextDisbursementDate;
        //     }

        //     if (value.disbursementFrequenc) {
        //       this.config.detailsFour =
        //         titleCaseClean(value.disbursementFrequency) + ' disbursement';
        //     }
        //   }
        //   this.config.icons = false;
        //   this.config.status = value.status;
        //   break;
        case 'CASH':
          this.config.displayOne = 'Cash Payments';
          this.config.detailsOne = value.description;
          break;
        case 'BANK_DEPOSIT':
          this.config.displayOne = 'Bank Deposit';
          this.config.detailsOne = value.description;
          break;
        case 'OTHER':
          this.config.displayOne = 'Pay Driver';
          break;
        case 'EVEREE':
          {
            if (value.account && value.account.accountName) {
              const nextDisbursementDate = internationalizedDate(
                value.nextDisbursementDate,
                'CONDENSED_DATE',
                false,
                null,
                this.localeService.getLocale()
              );
              this.config.displayOne = value.account.accountName;
              this.config.displayTwo =
                'Balance: ' +
                (value.account.accountBalance
                  ? currencySymbol + value.account.accountBalance
                  : '$0.00');

              if (this.networkGroup.locale === 'en-US') {
                this.config.detailsOne = 'RTN: ' + value.account.bankCode;
              } else {
                this.config.detailsOne = 'BSB: ' + value.account.bankCode;
              }
              this.config.detailsTwo = 'Acc: ' + value.account.accountNumber;

              if (nextDisbursementDate) {
                this.config.detailsThree =
                  'Next disbursement is on ' + nextDisbursementDate;
              }

              if (value.disbursementFrequency) {
                this.config.detailsFour =
                  titleCaseClean(value.disbursementFrequency) + ' disbursement';
              }

              this.config.icons = true;
              this.config.status = value.status;
            } else {
              const nextDisbursementDate = internationalizedDate(
                value.nextDisbursementDate,
                'CONDENSED_DATE',
                false,
                null,
                this.localeService.getLocale()
              );
              this.config.displayOne = value.accountName;
              this.config.displayTwo =
                'Balance: ' +
                (value.accountBalance
                  ? currencySymbol + value.accountBalance
                  : '$0.00');

              if (this.networkGroup.locale === 'en-US') {
                this.config.detailsOne = 'RTN: ' + value.bankCode;
              } else {
                this.config.detailsOne = 'BSB: ' + value.bankCode;
              }
              this.config.detailsTwo = 'Acc: ' + value.accountNumber;

              if (nextDisbursementDate) {
                this.config.detailsThree =
                  'Next disbursement is on ' + nextDisbursementDate;
              }

              if (value.disbursementFrequency) {
                this.config.detailsFour =
                  titleCaseClean(value.disbursementFrequency) + ' disbursement';
              }

              this.config.icons = true;
              this.config.status = value.status;
            }
          }
          break;
        default:
          this.config.displayOne = value.displayName;
          this.config.detailsOne = value.description;
          break;
      }
      this.changeDetectorRef.markForCheck();
    }
  }
  get paymentMethod() {
    return this._paymentMethod;
  }
  @Output() actionSelected = new EventEmitter();
  @Output() cardSelected = new EventEmitter();
  @Input() actions: { label: string; value: string }[];
  @Input() mode: 'card' | 'list' | 'condensed' | 'condensed_2' = 'list';
  @Input() hasError = false;
  @Input() shadow = true;
  @Input() padding = true;

  networkGroup: NetworkGroupModel;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private localeService: LocaleService,
    private networkGroupService: NetworkGroupService
  ) {
    this.networkGroupService.networkGroup$
      .pipe(takeUntilDestroyed())
      .subscribe((networkGroup) => {
        if (networkGroup) {
          this.networkGroup = networkGroup;
          this.changeDetectorRef.markForCheck();
        }
      });
  }
  ngOnInit(): void {}
}
