<div class="w-full" [ngClass]="{ 'max-w-3xl': !fullWidth }">
  <div class="flex flex-row justify-between items-center">
    <div class="w-full">
      <div class="flex flex-row items-center">
        <div class="text-xl">{{ title }}</div>
        <ng-content class="ml-3" select="[slot=titlestatus]"></ng-content>

        <ng-content class="ml-3" select="[slot=titleaction]"></ng-content>

        <ng-content select="[slot=results]"></ng-content>
      </div>

      <div class="text-secondary" *ngIf="subtitle">{{ subtitle }}</div>
    </div>
    <ng-content select="[slot=action]"></ng-content>
  </div>
  <div class="flex flex-col gap-8 w-full" [ngClass]="contentPadding">
    <ng-content select="[slot=description]"></ng-content>

    <ng-content select="[slot=error]"></ng-content>

    <ng-content select="[slot=main]"></ng-content>
  </div>
</div>

<div
  *ngIf="hasDivider"
  class="border-t my-8"
  [ngClass]="{ 'max-w-3xl': !fullWidth }"
></div>
