import { FuseNavigationItem } from '@fleet/model';
export const operatorUserNavigation: FuseNavigationItem[] = [
  {
    title: 'finance',
    type: 'group',
    securityFunctions: ['OPERATOR_INVOICE_SEARCH:read'],
    children: [
      {
        title: 'invoices',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: './invoices',
        securityFunctions: ['OPERATOR_INVOICE_SEARCH:read'],
      },
    ],
  },
  {
    title: 'settings',

    type: 'group',

    children: [
      {
        title: 'general',
        link: './settings',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        securityFunctions: ['OPERATOR_SETTINGS:read'],
      },
    ],
  },
];
