import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DriverModel } from '@fleet/model';

@Component({
  selector: 'fleet-driver-list-item',
  templateUrl: './driver-list-item.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverListItemComponent implements OnInit {
  @Input() driver: DriverModel | any;
  @Input() showAddButton: boolean;
  @Input() showDeleteButton: boolean;
  @Output() delete = new EventEmitter();
  @Output() add = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
