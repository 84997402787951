import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobSaleModelItemComponent } from './job-sale-model-item.component';
import {
  CreditCardPipeModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AlertModule } from '@fleet/ui';
import { MatMenuModule } from '@angular/material/menu';
import { JobPaymentTransactionReceiptModule } from '../job-payment-transaction-receipt/job-payment-transaction-receipt.module';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);

@NgModule({
  declarations: [JobSaleModelItemComponent],
  imports: [
    CommonModule,
    TitleCaseAndCleanPipeModule,
    CreditCardPipeModule,
    MatIconModule,
    MatButtonModule,
    AlertModule,
    MatMenuModule,
    HasFunctionPipeModule,
    JobPaymentTransactionReceiptModule,
    TranslocoDirective,
  ],
  exports: [JobSaleModelItemComponent],
  providers: [
    provideTranslocoScope({
      scope: 'jobSaleModelItem',
      loader: lazyTranslationloader,
    }),
  ],
})
export class JobSaleModelItemModule {}
