<div class="absolute inset-0 flex flex-col min-w-0">
  <mat-drawer-container
    class="flex flex-auto w-full h-full dark:bg-transparent"
    (backdropClick)="backdropClicked()"
  >
    <mat-drawer mode="over" position="end" #matDrawer class="w-full max-w-140">
      <ng-content select="[slot=drawer]"></ng-content>
    </mat-drawer>
    <mat-drawer-content
      class="flex flex-col w-full flex-auto px-6 pb-12 pt-9 md:p-8 md:pb-12 lg:p-12"
    >
      <div
        [ngClass]="{ 'w-full': widthFull, 'max-w-6xl': !widthFull }"
        class="flex flex-col gap-8"
      >
        <fleet-entity-header-with-breadcrumbs
          *ngIf="!hideHeader"
        ></fleet-entity-header-with-breadcrumbs>

        <!-- <div
          *ngIf="showTitleAndActions"
          class="flex flex-row justify-between items-center mb-3"
        > -->
        <!-- <div
            class="text-2xl font-bold tracking-tight leading-none ml-4 md:ml-0"
          >
            {{ title }}
          </div> -->

        <ng-content select="[slot=actions]"> </ng-content>
        <!-- </div> -->

        <div>
          <ng-content select="[slot=main]"></ng-content>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
