import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityCallSearchResultsComponent } from './entity-call-search-results.component';
import { MatButtonModule } from '@angular/material/button';
import {
  DurationPipeModule,
  MobileFormatModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ResponsiveElementModule } from '@fleet/layout';

import { MatTooltipModule } from '@angular/material/tooltip';
import { CallerTypePipeModule } from '../../caller-type-pipe/caller-type-pipe.module';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);

@NgModule({
  declarations: [EntityCallSearchResultsComponent],
  imports: [
    CommonModule,
    ResponsiveElementModule,
    MatTableModule,
    MatIconModule,

    TitleCaseAndCleanPipeModule,
    MatButtonModule,
    InternationalizationDatePipeModule,
    DurationPipeModule,
    MobileFormatModule,
    MatTooltipModule,
    CallerTypePipeModule,
    TranslocoDirective,
  ],
  exports: [EntityCallSearchResultsComponent],
  providers: [
    provideTranslocoScope({
      scope: 'entityCallSearchResults',
      loader: lazyTranslationloader,
    }),
  ],
})
export class EntityCallSearchResultsModule {}
