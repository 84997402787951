import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
export interface OnScreenNotification {
  icon: { name: string; color: string };
  title: string;
  subTitle: string;
  autoDismiss: number;
  link?: string;
  linkLabel?: string;
  id: string;
}
@Injectable({
  providedIn: 'root',
})
export class OnscreenNotificationService {
  notificationConfig: BehaviorSubject<any[]> = new BehaviorSubject([]);

  get uuid() {
    return uuidV4();
  }
  constructor() {}

  setNotification(config: any) {
    config.uuid = this.uuid;

    let notifications = [...this.notificationConfig.value, config];
    this.notificationConfig.next(notifications);

    if (config && config.autoDismiss) {
      //clear after duration
      setTimeout(() => {
        let x = this.notificationConfig.value.filter(
          (s) => s.uuid === config.uuid
        );
        let y = this.notificationConfig.value.filter(
          (s) => s.uuid !== config.uuid
        );
        this.notificationConfig.next(
          this.notificationConfig.value.filter((s) => s.uuid !== config.uuid)
        );
      }, config.autoDismiss);
    }
  }

  get notification$() {
    return this.notificationConfig.asObservable();
  }

  get mockNotification() {
    return {
      title: 'bkab',
      subTitle: 'dfsdd',
      autoDismiss: 1000,
      link: '/blah',
      linkLabel: 'Review Import',
    };
  }

  removeNotification(notification: any) {
    const newNotifications = this.notificationConfig.value.filter(
      (s) => s.uuid !== notification.uuid
    );
    this.notificationConfig.next(newNotifications);
  }

  removeNotificationById(notificationId: string) {
    const newNotifications = this.notificationConfig.value.filter(
      (s) => s.id !== notificationId
    );
    this.notificationConfig.next(newNotifications);
  }
}
