import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import {
  CreditCardPipeModule,
  TitleCaseAndCleanPipeModule,
} from '@fleet/pipes';
import { PaymentMethodListItemComponent } from './payment-method-list-item.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusChipModule, ToolTipModule } from '@fleet/ui';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [PaymentMethodListItemComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatMenuModule,

    MatTooltipModule,
    StatusChipModule,
    ToolTipModule,

    CreditCardPipeModule,
    MatTooltipModule,
    TitleCaseAndCleanPipeModule,
    TranslocoDirective,
  ],
  providers: [
    provideTranslocoScope({
      scope: 'paymentMethodListItem',
      loader: lazyTranslationloader,
    }),
  ],
  exports: [PaymentMethodListItemComponent],
})
export class PaymentMethodListItemModule {}
