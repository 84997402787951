import { FormGroup } from '@angular/forms';

export function disableForm(form: FormGroup) {
  Object.keys(form.controls).forEach((controlName) => {
    form.controls[controlName].disable(); // disables/enables each form control based on 'this.formDisabled'
  });
}

export function enableForm(form: FormGroup) {
  Object.keys(form.controls).forEach((controlName) => {
    form.controls[controlName].enable(); // disables/enables each form control based on 'this.formDisabled'
  });
}

import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class TouchedDirtyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return !!(control && control.invalid && control.dirty && control.touched);
  }
}
