import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntitySettingsLayoutComponent } from './entity-settings-layout.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SettingsTabLabelModule } from '@fleet/ui';
import { TranslocoDirective, TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [EntitySettingsLayoutComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    RouterModule,
    SettingsTabLabelModule,
    TranslocoDirective,
    TranslocoModule,
  ],
  exports: [EntitySettingsLayoutComponent],
})
export class EntitySettingsLayoutModule {}
