<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <mat-drawer-container class="flex flex-col flex-auto shadow bg-default">
    <!-- Drawer -->
    <mat-drawer
      class="dark:bg-gray-900 sm:w-80 w-full"
      [autoFocus]="false"
      [mode]="drawerMode"
      [opened]="drawerOpened"
      #matDrawer
    >
      <button
        *ngIf="drawerOpened && drawerMode === 'over'"
        mat-icon-button
        (click)="closeDrawer()"
        style="position: absolute; top: 4px; right: 0; z-index: 999"
      >
        <mat-icon>close</mat-icon>
      </button>
      <ng-container *ngIf="drawerMode === 'over'">
        <ng-content select="[slot=sidebar-header]"></ng-content>
      </ng-container>

      <fuse-vertical-navigation
        [appearance]="'default'"
        [navigation]="navigation"
        [inner]="true"
        [mode]="'side'"
        [name]="'demo-sidebar-navigation'"
        [opened]="true"
      ></fuse-vertical-navigation>
    </mat-drawer>

    <mat-drawer-content
      class="absolute inset-0 flex flex-col"
      [ngClass]="{ 'px-6 pb-12 pt-9 md:p-8 md:pb-12 lg:p-12': withPadding }"
    >
      <ng-content select="[slot=banner]"></ng-content>
      <!-- <div
        class="flex flex-col flex-grow"
        [ngClass]="{ 'px-6 pb-12 pt-9 md:p-8 md:pb-12 lg:p-12': withPadding }"
      > -->
      <ng-content select="[slot=entity-header]"></ng-content>
      <ng-content select="[slot=main]"></ng-content>
      <!-- </div> -->
    </mat-drawer-content>
  </mat-drawer-container>
</div>
