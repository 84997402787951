import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobSaleTransactionResultsComponent } from './job-sale-transaction-results.component';
import { MatIconModule } from '@angular/material/icon';
import { NoResultsLabelModule } from '@fleet/shared';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { TitleCaseAndCleanPipeModule } from '@fleet/pipes';
import { HasFunctionPipeModule } from '@fleet/security-shared';
import { ResponsiveElementModule } from '@fleet/layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { InternationalizationDatePipeModule } from '@fleet/internationalization';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';

const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [JobSaleTransactionResultsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    NoResultsLabelModule,
    MatMenuModule,
    MatButtonModule,
    TitleCaseAndCleanPipeModule,

    HasFunctionPipeModule,
    ResponsiveElementModule,
    MatExpansionModule,
    MatCardModule,
    InternationalizationDatePipeModule,
    TranslocoDirective,
  ],
  exports: [JobSaleTransactionResultsComponent],
  providers: [
    provideTranslocoScope({
      scope: 'jobSaleTransactionResults',
      loader: lazyTranslationloader,
    }),
  ],
})
export class JobSaleTransactionResultsModule {}
