import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsApiService {
  googleApiKey;
  httpClient: HttpClient;
  private isApiLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isApiLoaded$ = this.isApiLoaded.asObservable();
  host: string;

  constructor(
    private httpBackend: HttpBackend,
    private googleJSDKHttpClient: HttpClient,
    private http: HttpClient,
    @Inject('env') env: any
  ) {
    this.host = env.host + paths.googleMaps;

    this.httpClient = new HttpClient(httpBackend);
    this.googleApiKey = env.googleApiKey;
    console.log('🚀 ~ GoogleMapsService ~ loading google maps api...');
    this.googleJSDKHttpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=geometry,visualization,drawing,marker`,
        'callback'
      )
      .subscribe(
        () => {
          console.log('🚀 ~ GoogleMapsService ~ google maps api loaded');
          this.isApiLoaded.next(true);
        },
        (error) => {
          console.log(
            '🚀 ~ GoogleMapsService ~ google maps api cannot be loaded',
            error
          );
        }
      );
  }

  getTimezoneId(location: string): Observable<string> {
    const timeStamp = DateTime.now().toMillis();
    return this.httpClient
      .get(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=${timeStamp}&key=${this.googleApiKey}`
      )
      .pipe(
        map((resp: any) => {
          if (resp.status && resp.status === 'OK') {
            return resp.timeZoneId;
          }
          return null;
        })
      );
  }

  searchGoogleMapKeys(params: any): Observable<any> {
    return this.http
      .get(`${this.host}/key`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  createGoogleMapKey(payload: any): Observable<any> {
    return this.http
      .post(`${this.host}/key`, payload)
      .pipe(catchError(handleApiError));
  }

  deleteGoogleMapKey(intergrationId: string): Observable<any> {
    return this.http
      .delete(`${this.host}/key/${intergrationId}`)
      .pipe(catchError(handleApiError));
  }
}
