import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertModule } from '@fleet/fuse';
import { ServiceLineFromIdPipeModule } from '@fleet/pipes';
import { ServiceClassAndLineSelectorComponent } from './service-class-and-line-selector.component';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ServiceClassesFromServiceClassAndLinesPipe } from './service-classes-from-service-class-and-lines.pipe';
import { ServiceLinesFromServiceClassAndLinesPipe } from './service-lines-from-service-class-and-lines.pipe';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);

    return acc;
  },
  {}
);
@NgModule({
  declarations: [
    ServiceClassAndLineSelectorComponent,
    ServiceClassesFromServiceClassAndLinesPipe,
    ServiceLinesFromServiceClassAndLinesPipe,
  ],
  imports: [
    FuseAlertModule,
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ServiceLineFromIdPipeModule,
    MatIconModule,

    MatInputModule,
    MatListModule,
    MatDividerModule,
    TranslocoDirective,
  ],
  exports: [ServiceClassAndLineSelectorComponent],
  providers: [
    provideTranslocoScope({
      scope: 'serviceClassAndLineSelector',
      loader: lazyTranslationloader,
    }),
  ],
})
export class ServiceClassAndLineSelectorModule {}
