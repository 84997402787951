import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BraintreeApiService {
  host;
  integrationHost;
  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.braintree;
    this.integrationHost = env.host + paths.braintreeIntegration;
  }

  getClientToken(): Observable<any> {
    return this.http
      .get(`${this.integrationHost}/clientToken`)
      .pipe(catchError(handleApiError));
  }

  createBraintreeKey(payload: any): Observable<any> {
    return this.http
      .post(`${this.integrationHost}/key`, payload)
      .pipe(catchError(handleApiError));
  }

  updateBraintreeKey(intergrationId: string, payload: any): Observable<any> {
    return this.http
      .put(`${this.integrationHost}/key/${intergrationId}`, payload)
      .pipe(catchError(handleApiError));
  }

  deleteBraintreeKey(intergrationId: string): Observable<any> {
    return this.http
      .delete(`${this.integrationHost}/key/${intergrationId}`)
      .pipe(catchError(handleApiError));
  }
  searchBraintreeKeys(params: any): Observable<any> {
    return this.http
      .get(`${this.integrationHost}/key`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }
}
