import { DOWN_ARROW } from '@angular/cdk/keycodes';
import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelectionList, MatListModule } from '@angular/material/list';
import { TravellerApiService } from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  IssueModel,
  JobTravellerModel,
  TravellerModel,
  TravellerSearchResultModel,
  TravellerWithSelectedProfile,
} from '@fleet/model';
import { removeEmptyParams } from '@fleet/utilities';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertModule } from '@fleet/fuse';
import { AlertsFromIssuesModule } from '@fleet/issue';
import { SelectionListInDrawerModule } from '@fleet/layout';
import { MobileFormatModule } from '@fleet/pipes';
import { ProgressButtonModule } from '@fleet/shared';
import { provideTranslocoScope, TranslocoDirective } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';

export const lazyTranslationloader = ['en_au', 'es', 'en_us'].reduce(
  (acc: { [key: string]: () => Promise<any> }, lang) => {
    acc[lang] = () => import(`./i18n/${lang}.json`);
    return acc;
  },
  {}
);

@Component({
  selector: 'fleet-traveller-search-list-autocomplete',
  template: `
    <ng-container *transloco="let t">
      <fleet-selection-list-in-drawer
        [title]="t('travellerSearchListAutocomplete.findTraveller')"
        (cancel)="cancel.emit()"
      >
        <ng-container slot="filters">
          <form [formGroup]="searchForm">
            <div class="flex flex-row gap-1">
              <mat-form-field class="w-full fuse-mat-dense">
                <mat-label class="text-sm font-light">{{
                  t('travellerSearchListAutocomplete.firstName')
                }}</mat-label>
                <input
                  autocomplete="off"
                  data-lpignore="true"
                  data-form-type="other"
                  formControlName="firstName"
                  [value]="searchForm.controls['firstName'].value"
                  matInput
                />
                <mat-icon
                  [svgIcon]="'heroicons_outline:identification'"
                  class="icon-size-5"
                  matPrefix
                ></mat-icon>
              </mat-form-field>

              <mat-form-field class="w-full fuse-mat-dense">
                <mat-label class="text-sm font-light">{{
                  t('travellerSearchListAutocomplete.lastName')
                }}</mat-label>
                <input
                  autocomplete="off"
                  data-lpignore="true"
                  data-form-type="other"
                  formControlName="lastName"
                  [value]="searchForm.controls['lastName'].value"
                  matInput
                />
                <mat-icon
                  [svgIcon]="'heroicons_outline:newspaper'"
                  class="icon-size-5"
                  matPrefix
                ></mat-icon>
              </mat-form-field>
            </div>

            <div class="flex flex-row gap-1">
              <mat-form-field class="w-full fuse-mat-dense">
                <mat-label class="text-sm font-light">{{
                  t('travellerSearchListAutocomplete.phoneNumber')
                }}</mat-label>
                <input
                  autocomplete="off"
                  data-lpignore="true"
                  data-form-type="other"
                  formControlName="phoneNumber"
                  [value]="searchForm.controls['phoneNumber'].value"
                  matInput
                />
                <mat-icon
                  [svgIcon]="'heroicons_outline:phone'"
                  class="icon-size-5"
                  matPrefix
                ></mat-icon>
              </mat-form-field>

              <mat-form-field class="w-full fuse-mat-dense">
                <mat-label class="text-sm font-light">{{
                  t('travellerSearchListAutocomplete.email')
                }}</mat-label>
                <input
                  autocomplete="off"
                  data-lpignore="true"
                  data-form-type="other"
                  formControlName="email"
                  [value]="searchForm.controls['email'].value"
                  matInput
                />
                <mat-icon
                  [svgIcon]="'heroicons_outline:inbox'"
                  class="icon-size-5"
                  matPrefix
                ></mat-icon>
              </mat-form-field>
            </div>
            <div class="w-full flex items-center justify-end">
              <button
                mat-button
                [matTooltip]="t('travellerSearchListAutocomplete.clearSearch')"
                type="button"
                (click)="resetForm()"
              >
                {{ t('travellerSearchListAutocomplete.clearSearch') }}
              </button>

              <fleet-progress-button
                class="ml-3"
                [state]="{
                  loading: searching,
                  matTooltip: t(
                    'travellerSearchListAutocomplete.searchTravellers'
                  ),
                  buttonLabel: t('travellerSearchListAutocomplete.search')
                }"
                [disabled]="searching"
                type="submit"
                (onClick)="search()"
              >
              </fleet-progress-button>
            </div>
          </form>
        </ng-container>

        <fuse-alert
          slot="error"
          *ngFor="let alert of issues | alertsFromIssues"
          class=""
          [appearance]="'outline'"
          [showIcon]="true"
          [type]="alert.type"
          [@shake]="alert.type === 'error'"
        >
          {{ alert.message }}
        </fuse-alert>

        <ng-container slot="list">
          <mat-selection-list
            class="selection-list-no-check mt-2"
            #travellerList
          >
            <mat-list-option
              *ngFor="let traveller of travellerSearch"
              class="h-auto"
              (click)="selectTraveller(traveller)"
              (keydown.enter)="selectTraveller(traveller)"
            >
              <ng-container
                *ngTemplateOutlet="
                  travellerTemplate;
                  context: { $implicit: traveller }
                "
              ></ng-container>
            </mat-list-option>

            <mat-list-option
              disabled
              *ngIf="travellerSearch?.length === 0 && searchComplete"
              >{{
                t('travellerSearchListAutocomplete.noResults')
              }}</mat-list-option
            >
          </mat-selection-list>
        </ng-container>
      </fleet-selection-list-in-drawer>

      <ng-template #travellerTemplate let-traveller>
        <div class="flex flex-col sm:flex-row sm:items-center py-3">
          <div class="flex items-center">
            <div
              class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden"
            >
              <img
                *ngIf="traveller?.imageUrl; else noImage"
                class="w-10 h-10 rounded-full object-cover"
                [src]="traveller.imageUrl"
                alt="Traveller photo"
              />
              <ng-template #noImage>
                <span
                  class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500"
                >
                  <span class="text-xl font-medium leading-none text-white">
                    {{
                      traveller.firstName && traveller.lastName
                        ? traveller.firstName.substring(0, 1) +
                          traveller.lastName.substring(0, 1)
                        : ''
                    }}
                  </span>
                </span>
              </ng-template>
            </div>
            <div class="ml-4">
              <div class="font-medium">{{ traveller.displayName }}</div>
              <div class="text-sm text-secondary">
                {{ traveller.displayPhoneNumber | mobileFormat }}
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  `,
  styles: [
    `
      .traveller-list {
        .mat-pseudo-checkbox {
          display: none !important;
        }
      }
    `,
  ],

  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    CdkScrollableModule,
    A11yModule,
    SelectionListInDrawerModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FuseAlertModule,
    AlertsFromIssuesModule,
    MobileFormatModule,
    MatProgressSpinnerModule,
    ProgressButtonModule,
    TranslocoDirective,
    MatTooltipModule,
  ],

  providers: [
    provideTranslocoScope({
      scope: 'travellerSearchListAutocomplete',
      loader: lazyTranslationloader,
    }),
  ],
})
export class TravellerSearchListAutocompleteComponent
  implements OnInit, AfterViewInit
{
  searching: boolean;
  issues: IssueModel[];
  @Input() selectedTraveller: TravellerModel;

  searchControl: UntypedFormControl = new UntypedFormControl();
  searchForm: UntypedFormGroup;

  travellerSearch: TravellerSearchResultModel[];

  traveller: BehaviorSubject<TravellerModel> = new BehaviorSubject(null);
  jobTraveller: BehaviorSubject<JobTravellerModel> = new BehaviorSubject(null);
  selectedTravellerWithProfile: BehaviorSubject<TravellerWithSelectedProfile> =
    new BehaviorSubject(null);
  searchComplete = false;

  // travellers: any[] = [...mock, ...mock, ...mock];
  @Output() travellerWithProfileSelected = new EventEmitter();
  @Output() travellerSelected = new EventEmitter();
  @Output() jobTravellerSelected = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @ViewChild('travellerList') travellerList: MatSelectionList;
  @ViewChild('input') input: ElementRef;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private travellerApiService: TravellerApiService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      phoneNumber: new UntypedFormControl(),
      email: new UntypedFormControl(),
    });

    this.selectedTravellerWithProfile.asObservable().subscribe({
      next: (travellerWithProfile: TravellerWithSelectedProfile) => {
        if (travellerWithProfile) {
          this.travellerWithProfileSelected.emit(travellerWithProfile);
        }
      },
    });

    this.jobTraveller.asObservable().subscribe({
      next: (jobTraveller: JobTravellerModel) => {
        if (jobTraveller) {
          this.jobTravellerSelected.emit(jobTraveller);
        }
      },
    });

    this.traveller.asObservable().subscribe((traveller) => {
      if (traveller) {
        this.travellerSelected.emit(traveller);
      }
    });
  }

  search() {
    const params = this.assembleParams(this.searchForm.value);

    this.searching = true;
    this.issues = [];
    this.searchComplete = false;

    this.changeDetectorRef.markForCheck();

    this.travellerApiService.searchTravellers(params).subscribe({
      next: (resp: HttpResponse<ApiResponse<TravellerSearchResultModel[]>>) => {
        this.searching = false;
        this.searchComplete = true;
        this.travellerSearch = resp.body.data;
        this.changeDetectorRef.markForCheck();
      },
      error: (issues: IssueModel[]) => {
        this.searching = false;
        this.issues = issues;
        this.searchComplete = false;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus();
      }
    }, 100);
  }

  selectTraveller(traveller: TravellerSearchResultModel) {
    this.getTraveller(traveller.travellerId);
  }

  getTraveller(travellerId: string) {
    this.travellerApiService.getTraveller(travellerId).subscribe({
      next: (resp: ApiResponse<TravellerModel>) => {
        this.traveller.next(resp.data);
      },
    });
  }

  inputKeydown(event: { keyCode: number }) {
    if (event.keyCode === DOWN_ARROW) {
      //If key down, lets check if we can navigate on the service
      this.travellerList.options.first.focus();
    }
  }

  assembleParams(searchParams: any) {
    const params: any = Object.assign(
      {},
      { limit: 25, offset: 0 },
      removeEmptyParams(searchParams)
    );

    return params;
  }

  resetForm() {
    this.searchComplete = false;
    this.searchForm.reset();
    this.changeDetectorRef.markForCheck();
  }
}
